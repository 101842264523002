import {
  API_ADMIN_LOGIN_AFF_ROUTE,
  API_ADMIN_PAYOUT_ROUTE,
  API_ADMIN_PAYOUTS_ROUTE,
  API_ADMIN_TRANSACTION_ROUTE,
  API_GLOBAL_STATS_ROUTE,
  API_MEMBER_ROUTE,
  API_MEMBERS_ROUTE,
  API_USER_STATS_ROUTE,
} from "../../utils/consts";
import {
  ADMIN_PAYOUT,
  ADMIN_PAYOUT_PATCH,
  ADMIN_PAYOUT_TRANSACTION_PATCH,
  ADMIN_PAYOUTS,
  FETCH_MEMBER,
  GLOBAL_STATS,
  MEMBER,
  MEMBERS,
  USER_STATS,
} from "../types";
import { removeLoading, setLoading, setMessage } from "../rootReducer";
import axios from "../../utils/axios";

const allMembers = (data) => {
  return {
    type: MEMBERS,
    payload: { data },
  };
};
const Member = (data) => {
  return {
    type: MEMBER,
    payload: { data },
  };
};
const Fetch_Member = (data) => {
  return {
    type: FETCH_MEMBER,
    payload: { data },
  };
};
const GlobalStatistics = (data) => {
  return {
    type: GLOBAL_STATS,
    payload: { data },
  };
};
const UserStatistics = (data) => {
  return {
    type: USER_STATS,
    payload: { data },
  };
};
const Admin_payouts = (data) => {
  return {
    type: ADMIN_PAYOUTS,
    payload: { data },
  };
};
const Admin_payout = (data) => {
  return {
    type: ADMIN_PAYOUT,
    payload: { data },
  };
};
const patch_Admin_payout = (data) => {
  return {
    type: ADMIN_PAYOUT_PATCH,
    payload: { data },
  };
};
const patch_Admin_payout_transaction = (data) => {
  return {
    type: ADMIN_PAYOUT_TRANSACTION_PATCH,
    payload: { data },
  };
};

export const GetMembers = (page, limit, search) => async (dispatch) => {
  try {
    dispatch(setLoading("stats"));
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_URL + API_MEMBERS_ROUTE,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
        params: {
          page: page,
          limit: limit,
          search: search,
        },
      },
    );
    //credentials: 'include',

    if (response.status >= 200 && response.status <= 299) {
      console.log("here");
      console.log(response.data.data);
      dispatch(allMembers(response.data.data));
      dispatch(removeLoading("stats"));
    }
  } catch (error) {
    console.log(error);
    dispatch(removeLoading("stats"));
  }
};
export const GetMember =
  (id, page = null, limit = null, search, start = null, end = null) =>
  async (dispatch) => {
    dispatch(setLoading("stats"));
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + API_MEMBER_ROUTE + id + "/stats",
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ` + localStorage.getItem("access_token"),
          },

          params:
            start !== null && end !== null
              ? {
                  page: page,
                  limit: limit,
                  search: search,
                  start_date: start,
                  end_date: end,
                }
              : {
                  page: page,
                  limit: limit,
                  search: search,
                },
        },
      );

      if (response.status >= 200 && response.status <= 299) {
        console.log("here");
        console.log(response.data.data);
        dispatch(Member(response.data.data));
        dispatch(removeLoading("stats"));
      }
    } catch (error) {
      console.log(error);
      dispatch(removeLoading("stats"));
    }
  };

export const GetGlodalStats =
  (
    start = null,
    end = null,
    view_type = null,
    filter_by_group = null,
    filter_by_campaign = null,
    page,
    limit,
    sort_by = null,
    sort_mode = null,
  ) =>
  async (dispatch) => {
    try {
      var group = "";
      console.log(filter_by_group);
      if (filter_by_group == "All") {
        group = "all";
      }
      if (filter_by_group == "Affiliate") {
        group = "default";
      }
      if (filter_by_group == "AdNetwork") {
        group = "ad_network";
      }
      if (filter_by_group == "Model Referral") {
        group = "model_promocode";
      }
      if (filter_by_group == "Organic") {
        group = "organic";
      }
      dispatch(setLoading("stats"));
      let response;
      if (start !== null && end !== null) {
        response = await axios.get(
          process.env.REACT_APP_BACKEND_URL + API_GLOBAL_STATS_ROUTE,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ` + localStorage.getItem("access_token"),
            },
            params: {
              start_date: start,
              end_date: end,
              view_type: view_type,
              filter_by_group: group.length > 0 ? group : filter_by_group,
              filter_by_campaign: filter_by_campaign,
              page: page,
              limit: limit,
              sort_by: sort_by,
              sort_mode: sort_mode,
            },
          },
        );
      }
      if (start === null && end === null) {
        response = await axios.get(
          process.env.REACT_APP_BACKEND_URL + API_GLOBAL_STATS_ROUTE,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ` + localStorage.getItem("access_token"),
            },
            params: {
              page: page,
              limit: limit,
            },
          },
        );
      }

      //credentials: 'include',

      if (response.status >= 200 && response.status <= 299) {
        console.log(response.data);
        dispatch(GlobalStatistics(response.data.data));
        dispatch(removeLoading("stats"));
      }
    } catch (error) {
      dispatch(removeLoading("stats"));
      console.log(error);
    }
  };

export const GetUserStats =
  (selected_user = null) =>
  async (dispatch) => {
    try {
      dispatch(setLoading("stats"));
      let response;
      if (selected_user !== null) {
        response = await axios.get(
          process.env.REACT_APP_BACKEND_URL + API_USER_STATS_ROUTE,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ` + localStorage.getItem("access_token"),
            },
            params: {
              selected_user: selected_user,
            },
          },
        );
      } else {
        response = await axios.get(
          process.env.REACT_APP_BACKEND_URL + API_USER_STATS_ROUTE,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ` + localStorage.getItem("access_token"),
            },
          },
        );
      }

      if (response.status >= 200 && response.status <= 299) {
        console.log("here user");
        console.log(response.data);
        dispatch(UserStatistics(response.data.data));
        dispatch(removeLoading("stats"));
      }
    } catch (error) {
      dispatch(removeLoading("stats"));
      console.log(error);
    }
  };

export const AdminPayouts = () => async (dispatch) => {
  console.log("request admin payouts");
  try {
    dispatch(setLoading("stats"));
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_URL + API_ADMIN_PAYOUTS_ROUTE,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      },
    );

    if (response.status >= 200 && response.status <= 299) {
      console.log(response.data.data);
      dispatch(Admin_payouts(response.data.data));
      dispatch(removeLoading("stats"));
    }
  } catch (error) {
    console.log(error);
    dispatch(removeLoading("stats"));
  }
};
export const AdminPayoutsApprove = (id, method) => async (dispatch) => {
  const status = { status: method === 1 ? "paid_out" : "declined" };

  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL +
        API_ADMIN_PAYOUT_ROUTE +
        "/" +
        id +
        "/status",
      JSON.stringify(status),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      },
    );
    console.log(response);
    if (response.status >= 200 && response.status <= 299) {
      console.log("here");
      dispatch(AdminPayouts());
      dispatch(setMessage(response.data.message, response.data.type));
    }
  } catch (error) {
    console.log(error);
  }
};
export const AdminPayoutsComment = (id, note) => async (dispatch) => {
  const status = { note: note };

  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL +
        API_ADMIN_PAYOUT_ROUTE +
        "/" +
        id +
        "/notes",
      JSON.stringify(status),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      },
    );

    if (response.status >= 200 && response.status <= 299) {
      console.log("request admin payouts");
      dispatch(AdminPayouts());
    }
  } catch (error) {
    console.log(error);
  }
};
export const getAdminPayout = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("stats"));
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_URL + API_ADMIN_PAYOUT_ROUTE + "/" + id,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      },
    );

    if (response.status >= 200 && response.status <= 299) {
      dispatch(Admin_payout(response.data.data));
      dispatch(removeLoading("stats"));
    }
  } catch (error) {
    console.log(error);
    dispatch(removeLoading("stats"));
  }
};
export const changeAdminPayoutStatus = (id, method) => async (dispatch) => {
  const status = { status: method };
  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL +
        API_ADMIN_PAYOUT_ROUTE +
        "/" +
        id +
        "/status",
      JSON.stringify(status),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      },
    );
    console.log(response);
    if (response.status >= 200 && response.status <= 299) {
      dispatch(patch_Admin_payout(response.data.data));
      dispatch(setMessage(response.data.message, response.data.type));
    }
  } catch (error) {
    console.log(error);
  }
};
export const cancelAdminPayoutTransaction =
  (id, method) => async (dispatch) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL +
          API_ADMIN_TRANSACTION_ROUTE +
          "/" +
          id +
          "/cancel",
        JSON.stringify(method),
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ` + localStorage.getItem("access_token"),
          },
        },
      );
      console.log(response);
      if (response.status >= 200 && response.status <= 299) {
        dispatch(patch_Admin_payout_transaction(response.data.data));
        dispatch(setMessage(response.data.message, response.data.type));
      }
    } catch (error) {
      console.log(error);
    }
  };

export const changeAdminAffiliateStatus = (id, method) => async (dispatch) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + API_MEMBER_ROUTE + id + "/status",
      JSON.stringify(method),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      },
    );
    console.log(response);
    if (response.status >= 200 && response.status <= 299) {
      dispatch(Fetch_Member(response.data.data));
      dispatch(setMessage(response.data.message, response.data.type));
    }
  } catch (error) {
    console.log(error);
  }
};
export const loginAsAffiliate = (id) => async (dispatch) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + API_ADMIN_LOGIN_AFF_ROUTE + id,
      JSON.stringify(),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      },
    );
    console.log(response);
    if (response.status >= 200 && response.status <= 299) {
      console.log(response.data.data);
      window.open(
        `https://money.warehouse-x.io/?access_token=${response.data.data.access_token}`,
        "_blank",
        "incognito=yes",
      );
    }
  } catch (error) {
    console.log(error);
  }
};
//   export const CreateCampaign = (item) => async (dispatch) => {
//     console.log(item);
//     try {
//       const options = {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ` + localStorage.getItem("access_token"),
//         },
//         body: JSON.stringify(item),
//         //credentials: 'include',
//       };
//       const response = await fetch(
//         process.env.REACT_APP_BACKEND_URL + API_FETCH_CAMPAIGN_ROUTE,
//         options
//       );
//       //response.data
//       const data = await response.json();

//       if (data.type == "success") {
//         dispatch(Campaign(data.data));
//         dispatch(GetCampaigns());
//         dispatch(setMessage(data.message, data.type));
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   export const DeleteCampaign = (id) => async (dispatch) => {
//     try {
//       const options = {
//         method: "DELETE",
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ` + localStorage.getItem("access_token"),
//         },
//         //credentials: 'include',
//       };
//       const response = await fetch(
//         process.env.REACT_APP_BACKEND_URL + API_FETCH_CAMPAIGN_ROUTE + "/" + id,
//         options
//       );
//       //response.data
//       const data = await response.json();

//       if (data.type == "success") {
//         dispatch(Campaign({}));
//         dispatch(GetCampaigns());
//         dispatch(setMessage(data.message, data.type));
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   export const GetCampaign =
//     (id, start = null, end = null, period = null) =>
//     async (dispatch) => {
//       try {
//         let response;

//         if (id == null) {
//           response = await axios.get(
//             process.env.REACT_APP_BACKEND_URL + API_FETCH_CAMPAIGN_ROUTE,
//             {
//               withCredentials: "true",
//               headers: {
//                 "Content-Type": "application/json",
//                 Accept: "application/json",
//                 Authorization: `Bearer ` + localStorage.getItem("access_token"),
//               },
//             }
//           );
//         }
//       //   if (id) {
//       //     response = await axios.get(
//       //       process.env.REACT_APP_BACKEND_URL + API_FETCH_CAMPAIGN_ROUTE + "/" + id,
//       //       {
//       //         withCredentials: "true",
//       //         headers: {
//       //           "Content-Type": "application/json",
//       //           Accept: "application/json",
//       //           Authorization: `Bearer ` + localStorage.getItem("access_token"),
//       //         },
//       //       }
//       //     );
//       //   }
//       //   if (period && id) {
//       //     response = await axios.get(
//       //       process.env.REACT_APP_BACKEND_URL +
//       //         API_FETCH_CAMPAIGN_ROUTE +
//       //         "/" +
//       //         id +
//       //         `?period_type=${period.toLowerCase()}`,
//       //       {
//       //         withCredentials: "true",
//       //         headers: {
//       //           "Content-Type": "application/json",
//       //           Accept: "application/json",
//       //           Authorization: `Bearer ` + localStorage.getItem("access_token"),
//       //         },
//       //       }
//       //     );
//       //   }
//         if (start != null && end != null && period != null && id) {
//           response = await axios.get(
//             process.env.REACT_APP_BACKEND_URL +
//               API_FETCH_CAMPAIGN_ROUTE +
//               "/" +
//               id +
//               `?start_date=${start}&end_date=${end}&period_type=${period.toLowerCase()}`,
//             {
//               withCredentials: "true",
//               headers: {
//                 "Content-Type": "application/json",
//                 Accept: "application/json",
//                 Authorization: `Bearer ` + localStorage.getItem("access_token"),
//               },
//             }
//           );
//         }

//          //response.data
//         console.log(response)
//         if (response.data) {
//           console.log("here");
//           dispatch(CampaignDetails(response.data.data));
//           /*         dispatch(setMessage(data.message,data.type)); */
//         }
//       } catch (error) {
//         console.log(error);
//       }
//     };

export const GetMemberProfile = (id) => async (dispatch) => {
  dispatch(setLoading("stats"));
  try {
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_URL + API_MEMBER_ROUTE + id,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      },
    );

    if (response.status >= 200 && response.status <= 299) {
      dispatch(Member(response.data.data));
      dispatch(removeLoading("stats"));
    }
  } catch (error) {
    console.log(error);
    dispatch(removeLoading("stats"));
  }
};
export const UpdateMemberProfile = (id, data) => async (dispatch) => {
  dispatch(setLoading("stats"));
  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + API_MEMBER_ROUTE + id,
      JSON.stringify(data),

      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      },
    );

    if (response.status >= 200 && response.status <= 299) {
      // dispatch(Member(response.data.data));
      // console.log(response.data)
      dispatch(setMessage(response.data.message, response.data.status));
      dispatch(removeLoading("stats"));
      window.history.back();
    }
  } catch (error) {
    console.log(error);
    dispatch(setMessage(error.message, error.status));
    dispatch(removeLoading("stats"));
  }
};
