import { Card, CardActionArea, Container, Grid, Grow } from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { connect, useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import fileDownload from "js-file-download";
import { saveAs } from "file-saver";
const mapStateToProps = (state) => {
  return {
    stats_type: state.statsType,
    payout: state.payout,
  };
};
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};
function importAll(r) {
  let images = [];
  r.keys().forEach((item, index) => {
    images.push({
      images: item.replace("./", "").split(".")[0],
      value: r(item),
    });
    // images[item.replace("./", "")] = r(item);
  });
  return images.sort((a, b) => a.images - b.images);
}
const images = importAll(
  require.context("../../assets/GIF", false, /\.(|gif|png)$/)
);

const WhxTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} placement="top" arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function Banners({ stats_type, payout }) {
  const dispatch = useDispatch();

  //   var result = images.sort((a, b) => a.images - b.images);

  console.log(images);
  return (
    <>
      <Container maxWidth="xl">
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            className="container"
            container
            spacing={4}
            style={{ marginTop: "40px" }}
          >
            <Grid
              className="stats_header"
              item
              xs={11}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "20px",
              }}
            >
              {images.map((image, index) => {
                return (
                  //   <img
                  //     style={{
                  //       width: "fit-content",
                  //       borderRadius: "10px",
                  //       height: "fit-content",
                  //       // filter: "blur(10px)"
                  //     }}
                  //     src={image.value}
                  //     type="image"
                  //     alt={index}
                  //   />
                
                    <WhxTooltip title="Click to Download">
                        <Grow in timeout={200} delay={400}>
                      <Card style={{ margin: "5px" }}>
                        <CardActionArea
                          download={true}
                          onClick={() => {
                            saveAs(image.value, index);
                          }}
                          style={{ display: "flex" }}
                        >
                          {
                            // <ReactHlsPlayer
                            //   id="main_video"
                            //   src="rtsp://admin:WhxXXx971!@84.50.88.9:2001/cam/realmonitor?channel=1&subtype=1"
                            //   controls={false}
                            //   style={{ maxWidth: "140px", minWidth: "140px", width: "100%", height: '80px' }}
                            //   muted={true}
                            //   autoPlay
                            // />
                            // :
                            <img
                              src={image.value}
                              alt="preload"
                              className="Mansory_img"
                              style={{ borderRadius: "5px" }}
                            />
                          }
                        </CardActionArea>
                      </Card>
                      </Grow>
                    </WhxTooltip>
                  
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default connect(mapStateToProps)(Banners);
