import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Header from "../components/Header/Header";
import MuiAlert from "@mui/material/Alert";
import { connect, useDispatch } from "react-redux";
import { Snackbar } from "@mui/material";
import { removeMessage, setMessage, showModal } from "../redux/rootReducer";
import { pages } from "./pages";
import Login from "../components/Login/Login";
import Payout from "../pages/Payout/Payout";
import Logout from "../components/Logout/Logout";
import Support from "../pages/Support/Support";
import AdminLogin from "../components/Login/AdminLogin";
import { Adminpages } from "./adminPages";
import Landing from "../pages/Landing/Landing";
import Faq from "../pages/FAQ/Faq";
import {
  getEnvironment,
  setBinom,
  UpdateBinom,
} from "../redux/actions/userAction";
import { API_RESTORE_ROUTE } from "../utils/consts";
import Modals from "../components/Modals/Modals";

const mapStateToProps = (state) => {
  return {
    session: state.session,
    message: state.message,
  };
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function MainContainer({ session, message }) {
  const authenticated = localStorage.getItem("access_token");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(removeMessage());
  };
  const handleExited = () => {
    dispatch(removeMessage());
  };
  const location = useLocation();

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams) {
      const clickid = queryParams.get("clickid");
      if (clickid) {
        let binomObject = {};
        for (const [key, value] of new URLSearchParams(
          window.location.search,
        ).entries()) {
          binomObject[key] = value;
        }
        dispatch(setBinom(binomObject));
        if (queryParams.get("uclick") && queryParams.get("uclickhash")) {
          if (session?.user) {
            dispatch(UpdateBinom({ binom: binomObject }));
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    session?.user?.latestPaymentMethod === null &&
      session?.user?.role !== "affiliate_admin" &&
      session?.user?.role !== "model" &&
      dispatch(setMessage("Fill in Payout information to continue", "error"));

    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams) {
      const access_token = queryParams.get("access_token");
      if (access_token) {
        dispatch(getEnvironment(access_token));
        return;
      }
      if (
        queryParams.get("user") &&
        queryParams.get("token") &&
        location.pathname.includes("reset-password")
      ) {
        let token = queryParams.get("token");
        let user = queryParams.get("user");
        fetch(
          process.env.REACT_APP_BACKEND_URL +
            API_RESTORE_ROUTE +
            "?" +
            new URLSearchParams({
              user: user,
              token: token,
            }),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          },
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              dispatch(
                showModal("reset", {
                  token: token,
                  user: user,
                }),
              );
            } else {
              dispatch(
                setMessage(
                  "The password restoration link has expired. Please request a new one.",
                  "error",
                ),
              );
            }
          });
      }
    }
    if (location.pathname.includes("/admin/login")) {
      localStorage.removeItem("access_token");
      window.localStorage.clear();
      localStorage.clear();
      window.sessionStorage.clear();
    }
    if (location.pathname.includes("/register")) {
      localStorage.removeItem("access_token");
      window.localStorage.clear();
      localStorage.clear();
      window.sessionStorage.clear();
    }
  }, [location]);
  React.useEffect(() => {
    if (
      !localStorage.getItem("access_token") &&
      location.pathname.includes("/admin/login")
    ) {
      navigate("admin/login");
    }
  }, [localStorage.getItem("access_token")]);
  console.log(session?.user);
  return (
    <>
      {authenticated && <Header session={session} />}
      <Routes>
        {!authenticated ? (
          <>
            {location.pathname.includes("admin/login") ? (
              <>
                <Route path="/admin/login" element={<AdminLogin />} />
                <Route
                  path="*"
                  element={
                    <Navigate
                      to="/admin/login"
                      state={{ from: location }}
                      replace
                    />
                  }
                ></Route>
              </>
            ) : (
              <>
                <Route path="/" element={<Landing />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/signup" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Login />} />
                <Route path="/reset-password" element={<Login />} />
                <Route path="/signin" element={<Login />} />
                <Route
                  path="*"
                  element={
                    <Navigate to="/" state={{ from: location }} replace />
                  }
                />
              </>
            )}
          </>
        ) : session?.user?.latestPaymentMethod === null &&
          session?.user?.role !== "affiliate_admin" &&
          session?.user?.role !== "model" ? (
          <>
            <Route path={"/payout"} element={<Payout />} session={session} />
            <Route path={"/support"} element={<Support />} session={session} />
            <Route
              path="*"
              element={
                <Navigate to="/payout" state={{ from: location }} replace />
              }
            />
            <Route path={"/logout"} element={<Logout />} session={session} />
          </>
        ) : session.user.role === "affiliate_admin" ? (
          Adminpages.map((page) => {
            return (
              <Route
                path={page.path}
                element={page.component}
                session={session}
              />
            );
          })
        ) : (
          pages.map((page) => {
            return (
              <Route
                path={page.path}
                element={page.component}
                session={session}
              />
            );
          })
        )}
      </Routes>
      <Modals />
      {message && Object.keys(message).length > 0 && (
        <Snackbar
          open={Object.keys(message).length > 0}
          autoHideDuration={3500}
          TransitionProps={{ onExited: handleExited }}
          onClose={handleClose}
          anchorOrigin={
            message.type === "warning"
              ? { vertical: "center", horizontal: "center" }
              : message.position
                ? { vertical: "bottom", horizontal: message.position }
                : { vertical: "bottom", horizontal: "left" }
          }
        >
          <Alert
            onClose={handleClose}
            className="alert-whx"
            severity={message.type}
            sx={{ width: "100%" }}
          >
            {message.text}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default connect(mapStateToProps)(MainContainer);
