import React from "react";
import RestoreModal from "./RestoreModal";
import { useSelector } from "react-redux";
import PasswordModal from "./PasswordModal";
export default function Modals() {
  const modal = useSelector((state) => state.modal);
  if (modal?.name === "reset")
    return <RestoreModal reset={true} content={modal.content} />;
  else if (modal?.name === "restore") return <RestoreModal />;
  else if (modal?.name === "password") return <PasswordModal />;
}
