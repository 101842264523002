import * as React from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import LogoutIcon from "@mui/icons-material/Logout";
import Logo from "../../assets/money_logo.svg";
import { CardActionArea, Grid } from "@mui/material";
import styles from "./header.module.css";
import { useDispatch } from "react-redux";
import SettingsIcon from "@mui/icons-material/Settings";
import { showModal } from "../../redux/rootReducer";

const settings = [
  {
    // icon: 'DeleteIcon',
    name: "Logout",
    link: "/logout",
  },
];
const AdminSettings = [
  {
    // icon: 'DeleteIcon',
    name: "Logout",
    link: "/admin/logout",
  },
];

const pages = [
  {
    name: "CAMPAIGNS",
    link: "/campaigns",
  },
  /*   {
        name: "BLOG & EVENTS",
        link: "/blog",
      }, */
  {
    name: "STATS",
    link: "/stats",
  },
  {
    name: "SETTINGS",
    link: "/settings",
  },
  {
    name: "SUPPORT",
    link: "/support",
  },
  {
    name: "PAYOUT",
    link: "/payout",
  },
  {
    name: "PROMO TOOLS",
    link: "/promo",
  },
  // {
  //   name:'LIVE SHOWS',
  //   link:'/liveshows'
  // }
];
const Adminpages = [
  {
    name: "MEMBERS",
    link: "/admin/members",
  },
  {
    name: "GLOBAL STATS",
    link: "/admin/global_stats",
  },
  {
    name: "PAYOUTS",
    link: "/admin/payout",
  },
  /*   {
        name: "BLOG & EVENTS",
        link: "/blog",
      }, */
  // {
  //   name: "STATS",
  //   link: "/stats",
  // },
  // {
  //   name: "SETTINGS",
  //   link: "/setings",
  // },
  // {
  //   name: "SUPPORT",
  //   link: "/support",
  // },
  // {
  //   name: "PAYOUT",
  //   link: "/payout",
  // },
  // {
  //   name:'LIVE SHOWS',
  //   link:'/liveshows'
  // }
];

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Header = ({ session }) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const dispatch = useDispatch();

  const [loginUser, setLoginUser] = React.useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleChangePassword = () => {
    handleCloseUserMenu();
    dispatch(showModal("password"));
  };

  // const session = useSelector(state=>state.session)
  return (
    <AppBar
      position="static"
      style={{
        background: "#191919",
        color: "none",
        boxShadow: "unset",
        marginBottom: "30px",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              // mr: 1,
              display: { xs: "none", md: "flex" },
              textDecoration: "none",
              height: "auto",
            }}
            style={{ alignItems: "center", marginRight: "10px" }}
          >
            <Link to="/">
              <Avatar
                sx={{
                  display: { xs: "none", md: "flex" },
                  width: "190px",
                  height: "auto",
                }}
                variant="square"
                src={Logo}
              />
            </Link>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar-mobile"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {session &&
              session.user &&
              session.user.role === "affiliate_admin"
                ? Adminpages.map((page) => (
                    <MenuItem
                      key={page.name}
                      onClick={handleCloseNavMenu}
                      style={{ margin: "0px", padding: "0px" }}
                    >
                      <Link to={page.link}>
                        <Button
                          key={page.name}
                          onClick={handleCloseNavMenu}
                          sx={{
                            my: 0,
                            mx: 1,
                            fontSize: "13px",
                            color: "white",
                            display: "block",
                            fontWeight: "bold",
                            width: "220px",
                            textAlign: "left",
                          }}
                          style={{
                            backgroundColor: window.location.pathname.includes(
                              page.link,
                            )
                              ? "white"
                              : "black",
                            color: window.location.pathname.includes(page.link)
                              ? "black"
                              : "white",
                          }}
                        >
                          {page.name}
                        </Button>
                      </Link>
                    </MenuItem>
                  ))
                : pages.map((page) => (
                    <MenuItem
                      key={page.name}
                      onClick={handleCloseNavMenu}
                      style={{ margin: "0px", padding: "0px" }}
                    >
                      {console.log(
                        window.location.pathname.includes(page.link),
                      )}
                      <Link to={page.link}>
                        <Button
                          key={page.name}
                          onClick={handleCloseNavMenu}
                          sx={{
                            my: 0,
                            mx: 1,
                            fontSize: "13px",
                            color: "white",
                            display: "block",
                            fontWeight: "bold",
                            width: "220px",
                            textAlign: "left",
                          }}
                          style={{
                            backgroundColor:
                              window.location.pathname.includes(page.link) &&
                              "white",
                            color:
                              window.location.pathname.includes(page.link) &&
                              "black",
                          }}
                        >
                          {page.name}
                        </Button>
                      </Link>
                    </MenuItem>
                  ))}
            </Menu>
          </Box>

          <Box
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Link to="/">
              <Avatar
                sx={{
                  display: { xs: "flex", md: "none" },
                  mr: 0.5,
                  width: "fit-content",
                  height: "fit-content",
                }}
                variant="square"
                src={Logo}
              />
            </Link>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-start",
            }}
          >
            {session && session.user && session.user.role === "affiliate_admin"
              ? Adminpages.map((page) => (
                  <Link key={"header-page-" + page.name} to={page.link}>
                    <Button
                      key={page.name}
                      onClick={handleCloseNavMenu}
                      sx={{
                        my: 2,
                        mx: 1,
                        fontSize: "1rem",
                        color: "white",
                        display: "block",
                        fontWeight: "bold",
                        borderRadius: "5px",
                        background: "#FFFFFF 0% 0 % no - repeat padding- box",
                        padding: "0px 8px",
                      }}
                      style={{
                        backgroundColor:
                          window.location.pathname.includes(page.link) &&
                          "white",
                        color:
                          window.location.pathname.includes(page.link) &&
                          "black",
                      }}
                    >
                      {page.name}
                    </Button>
                  </Link>
                ))
              : pages.map((page) => (
                  <Link key={"header-page-" + page.name} to={page.link}>
                    <Button
                      key={page.name}
                      onClick={handleCloseNavMenu}
                      sx={{
                        my: 2,
                        mx: 1,
                        fontSize: "1rem",
                        color: "white",
                        display: "block",
                        fontWeight: "bold",
                        borderRadius: "5px",
                        background: "#FFFFFF 0% 0 % no - repeat padding- box",
                        padding: "0px 8px",
                      }}
                      style={{
                        backgroundColor:
                          window.location.pathname.includes(page.link) &&
                          "white",
                        color:
                          window.location.pathname.includes(page.link) &&
                          "black",
                      }}
                    >
                      {page.name}
                    </Button>
                  </Link>
                ))}
          </Box>

          <Box sx={{ flexGrow: 1 }} />
          <Grid className={styles.avatar_grid}>
            {localStorage.getItem("access_token") &&
              session.user &&
              session.user.role !== "affiliate_admin" && (
                <Box
                  sx={{
                    display: { xs: "none", md: "flex", marginRight: "25px" },
                  }}
                >
                  <Typography style={{ color: "#77F73B", fontSize: "2rem" }}>
                    ${session?.user?.affiliatePartner?.balance}
                  </Typography>
                </Box>
              )}
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip disableHoverListener>
                <Stack direction="row" spacing={1}>
                  {localStorage.getItem("access_token") && (
                    <CardActionArea style={{ borderRadius: "5px" }}>
                      <Grid
                        onClick={handleOpenUserMenu}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Grid className="hide-mobile">
                          <Typography
                            sx={{
                              overflowWrap: "break-word",
                              color: "#77F73B",
                              font: "normal normal bold 1.3rem Effra",
                              textAlign: "left",
                            }}
                          >
                            {console.log(session)}
                            {
                              session?.user?.username?.length <= 10
                                ? session?.user?.username
                                : session?.user
                                    ?.username /* .substring(0, 10) */
                            }
                          </Typography>
                          <Typography
                            style={{
                              font: "normal normal bold 0.8rem Effra",
                              textAlign: "left",
                              height: "13px",
                              marginTop: "2px",
                            }}
                          >
                            {session &&
                            session.user &&
                            session.user.role === "affiliate_admin"
                              ? "Admin"
                              : "Affiliate"}
                          </Typography>
                        </Grid>
                        <Grid>
                          <Avatar
                            className={
                              "headerAvatar onlineCard " + styles.avatar
                            }
                            alt={session?.user?.username.toUpperCase()}
                            src={"/broken-image.jpg"}
                          />
                        </Grid>
                      </Grid>
                    </CardActionArea>
                  )}
                </Stack>
              </Tooltip>
              <Menu
                sx={{
                  mt: "100px",
                  ul: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  },
                }}
                id="menu-appbar"
                style={{ top: "0px" }}
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  onClick={() => {
                    handleChangePassword();
                  }}
                  sx={{
                    fontSize: "13px",
                    color: "#fff",
                    fontWeight: "bold",
                    maxWidth: "220px",
                    minWidth: "120px",
                    display: "flex",
                    justifyContent: "flex-end",
                    textTransform: "uppercase",
                  }}
                >
                  <SettingsIcon />
                  &emsp;Change Password
                </MenuItem>
                {session &&
                session.user &&
                session.user.role === "affiliate_admin"
                  ? AdminSettings.map((setting) => (
                      <Link
                        to={setting.link}
                        key={setting.name}
                        style={{ width: "100%" }}
                      >
                        <MenuItem
                          onClick={handleCloseUserMenu}
                          sx={{
                            fontSize: "13px",
                            color: "#fff",
                            fontWeight: "bold",
                            maxWidth: "220px",
                            display: "flex",
                            textTransform: "uppercase",
                          }}
                        >
                          {setting.name === "Logout" ? <LogoutIcon /> : ""}
                          &emsp;{setting.name}
                        </MenuItem>
                      </Link>
                    ))
                  : settings.map((setting) => (
                      <Link
                        to={setting.link}
                        key={setting.name}
                        style={{ width: "100%" }}
                      >
                        <MenuItem
                          onClick={handleCloseUserMenu}
                          sx={{
                            fontSize: "13px",
                            color: "#fff",
                            fontWeight: "bold",
                            width: "100%",
                            minWidth: "120px",
                            display: "flex",

                            textTransform: "uppercase",
                          }}
                        >
                          {setting.name === "Logout" ? <LogoutIcon /> : ""}
                          &emsp;{setting.name}
                        </MenuItem>
                      </Link>
                    ))}
              </Menu>
            </Box>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
