import React, { useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik, useField } from "formik";
import { Button, Grid } from "@mui/material";
import * as Yup from "yup";
import Logo from "../../assets/Logo.svg";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { addNewUser, loginAdmin, loginUser } from "../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { useSnackbar } from "notistack";
import { removeErrors } from "../../redux/rootReducer";
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const AdminLoginForm = () => {

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
      username: "",
      password: "",
      password_confirmation: "",
    },
    onSubmit: () => {
      console.log("submitting...");
      dispatch(loginAdmin(formik.values));
      console.log("submited!!");
    },
  });

  const { errors, touched, values, isSubmitting, getFieldProps } = formik;

  const { enqueueSnackbar } = useSnackbar();
  const errorsSubmit = useSelector((state) => state.errors);
  React.useEffect(() => {
    if (errorsSubmit && errorsSubmit.email) {
      enqueueSnackbar(errorsSubmit.email[0], { variant: "error" });
    }
    if (errorsSubmit && errorsSubmit.username) {
      enqueueSnackbar(errorsSubmit.username[0], { variant: "error" });
    }
    if (errorsSubmit && errorsSubmit.password) {
      enqueueSnackbar(errorsSubmit.password[0], { variant: "error" });
    }
    if (errorsSubmit && errorsSubmit.password_confirmation) {
      enqueueSnackbar(errorsSubmit.password_confirmation[0], {
        variant: "error",
      });
    }

    setTimeout(() => {
      dispatch(removeErrors());
    }, 5000);
  }, [errorsSubmit]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Box
            animate={{
              transition: {
                staggerChildren: 0.55,
              },
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                gap: "16px",
                width: "80%",
              }}
              initial={{ opacity: 0, y: 40 }}
              animate={animate}
            >
              <AccountCircle sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                fullWidth
                label="Email or Username"
                variant="standard"
                style={{ backgroundColor: "#191919", paddingTop: "5px" }}
                type="username"
                {...getFieldProps("username")}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                gap: "16px",
                width: "80%",
              }}
              initial={{ opacity: 0, y: 40 }}
              animate={animate}
            >
              <LockIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                fullWidth
                style={{ backgroundColor: "#191919", paddingTop: "5px" }}
                label="Password"
                variant="standard"
                autoComplete="current-password"
                type={"password"}
                {...getFieldProps("password")}
              />
            </Box>

            <Box
              initial={{ opacity: 0, y: 20 }}
              animate={animate}
              style={{ width: "60%" }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2, color: "black" }}
              ></Stack>

              <Button
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                /*   loading={isSubmitting} */
                style={{
                  backgroundColor: "#77F73B",
                  font: "normal normal 800 18px/22px Effra",
                }}
              >
                {"Login"}
              </Button>
            </Box>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
};

export default AdminLoginForm;
