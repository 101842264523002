import React from "react";
import { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import format from "date-fns/format";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useDispatch } from "react-redux";
import { dateRange } from "../../redux/rootReducer";
import { Button, SvgIcon } from "@mui/material";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { GetCampaign } from "../../redux/actions/CampaignsActions";
import { useParams } from "react-router-dom";
import { GetGlodalStats, GetMember } from "../../redux/actions/AdminMembersActions";
const DateRangePickerCompAffMembers = ({id,page,limit,search,start, end}) => {
  // date state
  const dispatch = useDispatch();
  const param = useParams();
  const [range, setRange] = useState([
    {
      startDate: start && new Date(start),
      endDate: end && new Date(end),
      key: "selection",
    },
  ]);
  dispatch(dateRange(range));
  function pad(num) {
    return ("0" + num).slice(-2);
  }
  let startDate = start && new Date(start);
  let endDate = end && new Date(end);
  useEffect(() => {
    if (start !== undefined) {
      range[0].startDate = new Date(start);
    }
    if (end !== undefined) {
      range[0].endDate = new Date(end);
    }
  }, [start, end]);
  console.log(range[0]);
  React.useEffect(() => {
    if (range[0].startDate !== undefined) {
      startDate = range[0].startDate;
    }
    if (range[0].endDate !== undefined) {
      endDate = range[0].endDate;
    }
    const first =
      startDate &&
      startDate.getFullYear() +
        "-" +
        pad(startDate.getMonth() + 1) +
        "-" +
        pad(startDate.getDate());
    const last =
      endDate &&
      endDate.getFullYear() +
        "-" +
        pad(endDate.getMonth() + 1) +
        "-" +
        pad(endDate.getDate());
    dispatch(GetMember(id,page,limit,search,first, last));
  }, [range]);
  // open close
  const [open, setOpen] = useState(false);

  // get the target element to toggle
  const refOne = useRef(null);

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  // Hide dropdown on outside click
  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  return (
    <div className="calendarWrap">
      <div className="wrapper">
        <SvgIcon className="icon" color="black">
          <CalendarTodayOutlinedIcon />
        </SvgIcon>
        <input
          value={
            range[0]&&range[0].startDate && `${format(range[0].startDate, "dd.MM.yyy")}`
          }
          readOnly
          className="inputBox"
          style={{ textAlign: "center" }}
        />
      </div>
      <span
        style={{ color: "white", paddingRight: "10px", paddingLeft: "10px" }}
      >
        -
      </span>
      <div className="wrapper">
        <SvgIcon className="icon" color="black">
          <CalendarTodayOutlinedIcon />
        </SvgIcon>
        <input
          value={range[0]&&range[0].endDate && `${format(range[0].endDate, "dd.MM.yyy")}`}
          readOnly
          className="inputBox"
          style={{ textAlign: "center" }}
        />
      </div>
      <div style={{ backgroundColor: "black" }} ref={refOne}>
        {open && (
          <DateRangePicker
            onChange={(item) => setRange([item.selection])}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            closeOnSelect={true}
            ranges={range}
            months={1}
            direction="vertical"
            className="calendarElement"
          />
        )}
      </div>
      <Button
        className="date_time_button"
        onClick={() => setOpen((open) => !open)}
        style={{
          background: "#77F73B 0% 0% no-repeat padding-box",
          color: "black",
          boxShadow: "0px 3px 6px #00000029",
          font: "normal normal bold 1rem Montserrat",
          borderRadius: "15px",
          padding: "7px 15px",
          marginLeft: "15px",
        }}
      >
        SHOW
      </Button>
    </div>
  );
};

export default DateRangePickerCompAffMembers;
