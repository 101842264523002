import {
  Card,
  CardActionArea,
  CardMedia,
  Container,
  Grid,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { connect, useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import fileDownload from "js-file-download";
import { saveAs } from "file-saver";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import axios from "axios"
const mapStateToProps = (state) => {
  return {
    stats_type: state.statsType,
    payout: state.payout,
  };
};
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const WhxTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} placement="top" arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function PreRolls({ stats_type, payout }) {
  const dispatch = useDispatch();

  //   var result = images.sort((a, b) => a.images - b.images);

  const videos = [
    "whx_aff_preroll_1.mp4",
    "whx_aff_preroll_2.mp4",
    "whx_aff_preroll_3.mp4",
    "whx_aff_preroll_4.mp4",
    "whx_aff_preroll_5.mp4",
  ];
///https://media.warehouse-x.io/videos/fetch_preroll.php?context=
  return (
    <>
      <Container maxWidth="xl">
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            className="container"
            container
            spacing={4}
            style={{ marginTop: "40px" }}
          >
            <Grid
              className="stats_header"
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "35px",
                paddingBottom:'40px'
              }}
            >
              {videos.map((video, index) => {
                return (
                  //   <img
                  //     style={{
                  //       width: "fit-content",
                  //       borderRadius: "10px",
                  //       height: "fit-content",
                  //       // filter: "blur(10px)"
                  //     }}
                  //     src={image.value}
                  //     type="image"
                  //     alt={index}
                  //   />
                  
                  <div className="preroll" style={{ position: "relative" }}>
                    <WhxTooltip title="Click to Download">
                      <IconButton
                        edge="start"
                        color="inherit"
                        download
                        onClick={()=>{
                            saveAs("https://media.warehouse-x.io/videos/fetch_preroll.php?context="+video,index)
                        }}
                        aria-label="close"
                        style={{
                          width: "fit-content",
                          marginLeft: "15px",
                          marginTop: "5px",
                          position: "absolute",
                          right: "10px",
                          top: "2px",
                          zIndex: 5,
                          color: "white",
                        }}
                      >
                        <FileDownloadIcon />
                      </IconButton>
                    </WhxTooltip>

                    <video
                      style={{
                        minWidth: "300px",
                        minHeight: "200px",
                        maxWidth: window.innerWidth<900? "100%": "920px",
                        borderRadius: "10px",
                      }}
                      
                      // ref={highlightRef}
                      src={"https://media.warehouse-x.io/videos/"+video}
                      controls={true}
                    />
                  </div>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default connect(mapStateToProps)(PreRolls);
