import { CAMPAIGNS, GLOBAL_STATS, MEMBERS, USER_STATS } from "../types";

export const AdminUserStatisticsReducer = (state = [], action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_STATS: {
      const { data } = payload;
      state = [];
      return data;
    }
    default: {
      return state;
    }
  }
};
