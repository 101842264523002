import React from "react";
import { Container, Box } from "@mui/material";
import styled from "@emotion/styled";
import LoginForm from "../LoginForm/LoginForm";
import Logo from "../../assets/lgbg.svg";
import Logo2 from "../../assets/lgbgsmall.svg";
import Warehouse from "../../assets/lglogo.svg";
import { Avatar } from "@mui/material";
import AdminLoginForm from "../LoginForm/AdminLoginForm";

const RootStyle = styled("div")({
  background: "rgb(249, 250, 251)",
  height:'100%',
  display: "grid",
  placeItems: "center",
});


let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

const AdminLogin = () => {
  return (
    <Box className="RegistrationBoxWrap" style={{ backgroundImage: `url(${Logo})` }}>
      <Box
      className="RegistrationBox"
      >
        <Avatar
          sx={{
            display: {  md: "flex" },
            mr: 0.5,
            maxWidth: "300px",
            width:'fit-content',
            justifyContent: "center",
            maxHeight: '80px',
            height:'fit-content'
          }}
          variant="square"
          src={Warehouse}
        />
        <AdminLoginForm />
      </Box>
    </Box>
  );
};

export default AdminLogin;
