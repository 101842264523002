import {
    Button,
    Container,
    Grid,
    IconButton,
    TableCell,
    tableCellClasses,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import React, {useState} from "react";
import Box from "@mui/material/Box";
import {connect, useDispatch, useSelector} from "react-redux";

import {styled} from "@mui/material/styles";
import {GetMembers} from "../../redux/actions/AdminMembersActions";
import {useNavigate} from "react-router-dom";
import {DataGrid} from "@mui/x-data-grid";
import PropTypes from "prop-types";
import ClearIcon from "@mui/icons-material/Clear";
import AccountBoxIcon from '@mui/icons-material/AccountBox';

const mapStateToProps = (state) => {
    return {
        admin_members: state.admin_members,
    };
};
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
    opacity: 1,
    y: 0,
    transition: {
        duration: 0.6,
        ease: easing,
        delay: 0.16,
    },
};

function QuickSearchToolbar(props) {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
            }}
        >
            <TextField
                fullWidth
                value={props.value}
                onChange={props.onChange}
                placeholder="Search User"
                InputProps={{
                    //   startAdornment: <SearchIcon fontSize="small" />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{visibility: props.value ? "visible" : "hidden"}}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small"/>
                        </IconButton>
                    ),
                }}
            />
        </Box>
    );
}

QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

function Members({admin_members}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedType, setSelectedType] = React.useState("pending");
    React.useEffect(() => {
        dispatch(GetMembers());
    }, []);

    console.log(admin_members);
    const StyledTableRow = styled(TableRow)(({theme}) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: "#292929",
            height: "40px",
            padding: "0px 16px",
        },
        "&:nth-of-type(even)": {
            backgroundColor: "#1F1F1F",
            height: "40px",
            padding: "0px 16px",
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
        root: {
            height: 30,
        },
        "&.MuiTableRow-root:hover": {
            backgroundColor: "#555555",
        },
    }));

    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#292929",
            color: theme.palette.common.white,
            padding: "0px 16px",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const yearsMonths = [
        {id: 0, name: "January"},
        {id: 1, name: "February"},
        {id: 2, name: "March"},
        {id: 3, name: "April"},
        {id: 4, name: "May"},
        {id: 5, name: "June"},
        {id: 6, name: "July"},
        {id: 7, name: "August"},
        {id: 8, name: "September"},
        {id: 9, name: "October"},
        {id: 10, name: "November"},
        {id: 11, name: "December"},
    ];

    function getTime(time) {
        var date = new Date(time * 1000);
        const month_number = date.getMonth();
        const month = yearsMonths[month_number].name;
        // 👉️ 0
        // 👇️ returns day of the month (1-31)
        const day_month = date.getDate();
        const hours = date.getHours(); // get hours with getHours method
        const minutes = date.getMinutes(); // get minutes with getMinutes method // get seconds with getSeconds method
        const timeString = `${day_month} ${month} ${pad(hours)}:${pad(minutes)}`;
        return timeString; // finally, join to a time string
    }

    function pad(num) {
        return ("0" + num).slice(-2);
    }

    const columns = [
        {field: "username", headerName: "Username", width: "250"},
        {field: "email", headerName: "Email", width: "250"},
        {
            field: "balance",
            headerName: "balance",
            valueGetter: (params) => params.row.affiliate_partner.balance,
            type: "number",
            sortComparator: (v1, v2) => parseInt(v1) - parseInt(v2),
            width: "250",
            renderCell: (params) => {
                return (
                    <Typography>$ {params.row.affiliate_partner.balance}</Typography>
                );
            },
        },
        {field: "createdAt", headerName: "Date Created", width: "250"},
        {field: "lastIP", headerName: "last IP", width: "250"},
        {
            headerName: "User Settings",
            width: "100",
            disableClickEventBubbling: true,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return (
                    <Button
                        style={{
                            color: "white",
                            width: "10px",
                            padding: "0px",
                            border: "unset",
                            borderRadius: "5px",
                        }}
                        type="button"
                        onClick={(event) => {
                            event.stopPropagation();
                            handleNavigateToUser(params.row.id);
                        }}
                    >
                        <AccountBoxIcon style={{color: "#77f73b"}}/>
                    </Button>
                );
            },
        },
    ];
    const handleNavigateToUser = (id) => {
        navigate(`/admin/member-settings/${id}`)
    };


    const [pageSize, setPageSize] = React.useState(
        admin_members?.pagination?.limit
            ? Number(admin_members?.pagination?.limit)
            : 20
    );
    const [page, setPage] = React.useState(
        admin_members?.pagination?.page ? admin_members?.pagination?.page : 0
    );

    console.log(pageSize, page);
    React.useEffect(() => {
        setPage(admin_members?.pagination?.page);
        setPageSize(Number(admin_members?.pagination?.limit));

        // dispatch(GetMembers(page, Number(pageSize)));
    }, [admin_members?.pagination?.page, admin_members?.pagination?.limit]);
    const [data_rows, setdata_rows] = useState([]);
    React.useEffect(() => {
        setdata_rows(admin_members && admin_members.users && admin_members.users);
    }, [admin_members, admin_members.users]);

    const loading = useSelector((state) => state.loading);
    const handleRowClick = (params) => {
        navigate("/admin/members/" + params.row.id);
        // console.log(params.row.id);
    };
    const handleRow = (params) => {
        console.log(params);
        // navigate("/admin/members/" + params.row.id);
        // console.log(params.row.id);
    };
    const handlSearch = (e) => {
        console.log(e.target.value);
        // navigate("/admin/members/" + params.row.id);
        // console.log(params.row.id);
    };

    const [rowCountState, setRowCountState] = React.useState(
        admin_members?.pagination?.total_count || 0
    );
    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            admin_members?.pagination?.total_count !== undefined
                ? admin_members?.pagination?.total_count
                : prevRowCountState
        );
    }, [admin_members?.pagination?.total_count, setRowCountState]);

    const [selectionModel, setSelectionModel] = React.useState();
    const [searchText, setSearchText] = useState("");

    React.useEffect(() => {
        const timeoutID = setTimeout(() => {
            dispatch(GetMembers(page, Number(pageSize), searchText));
        }, 500);
        return () => clearTimeout(timeoutID);
    }, [searchText]);

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        console.log(searchValue);
    };
    return (
        <>
            <Container maxWidth="xl">
                <Box sx={{flexGrow: 1}}>
                    <Grid
                        className="container"
                        container
                        spacing={4}
                        style={{marginTop: "40px"}}
                    >
                        <Grid
                            item
                            xs={12}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                paddingTop: "46px",
                            }}
                        >
                            <Typography
                                style={{
                                    color: "white",
                                    font: "normal normal bold 1.5rem Effra",
                                }}
                            >
                                Affiliate Members
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop: "0px"}}>
                            <div style={{height: "600px", width: "100%"}}>
                                {data_rows && (
                                    <DataGrid
                                        rows={data_rows && data_rows}
                                        columns={columns}
                                        pageSize={pageSize}
                                        onPageChange={(newPage) => {
                                            setPage(newPage);
                                            dispatch(GetMembers(newPage, Number(pageSize), searchText));
                                        }}
                                        rowCount={rowCountState}
                                        onPageSizeChange={(newPageSize) => {
                                            setPageSize(newPageSize);
                                            dispatch(GetMembers(page, Number(newPageSize), searchText));
                                        }}
                                        rowsPerPageOptions={[20, 50, 100]}
                                        //   checkboxSelection
                                        loading={loading}
                                        pagination
                                        paginationMode="server"
                                        page={page}
                                        onRowClick={handleRowClick}
                                        components={{Toolbar: QuickSearchToolbar}}
                                        componentsProps={{
                                            toolbar: {
                                                value: searchText,
                                                showQuickFilter: true,
                                                quickFilterProps: {debounceMs: 500},
                                                onChange: (event) => requestSearch(event.target.value),
                                                clearSearch: () => requestSearch(""),
                                            },
                                        }}
                                        onSelectionModelChange={(newSelectionModel) => {
                                            setSelectionModel(newSelectionModel);
                                        }}
                                        selectionModel={selectionModel && selectionModel}
                                    />
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    );
}

export default connect(mapStateToProps)(Members);
