import Logout from "../components/Logout/Logout"
import {Navigate} from 'react-router-dom';
import Members from '../AdminPages/Members/Members';
import Member from "../AdminPages/Member/Member";
import GlobalStats from "../AdminPages/GlodalStats/GlobalStats";
import Payouts from "../AdminPages/Payouts/Payouts";
import AdminPayout from "../AdminPages/Payouts/AdminPayout";
import MemberSettings from "../AdminPages/MemberSettings/MemberSettings";

export const Adminpages = [
    {
        path: '/',
        component: <Members/>
    },
    {
        path: '/admin/logout',
        component: <Logout/>
    },
    {
        path: '*',
        component: <Navigate to="/"/>
    },
    {
        path: '/admin/members',
        component: <Members/>
    },
    {
        path: '/admin/member-settings/:id',
        component: <MemberSettings/>
    },
    {
        path: '/admin/members/:id',
        component: <Member/>
    },
    {
        path: '/admin/global_stats',
        component: <GlobalStats/>
    },
    {
        path: '/admin/payout',
        component: <Payouts/>
    },
    {
        path: '/admin/payout/:id',
        component: <AdminPayout/>
    },
    // {
    //     path:'/campaigns',
    //     component: <Campaigns/>
    // },
    // {
    //     path:'/support',
    //     component: <Support/>
    // },
    // {
    //     path:'/stats',
    //     component: <Stats/>
    // },
    // {
    //     path:'/stats/:id',
    //     component: <Stats/>
    // },
    // {
    //     path:'/payout',
    //     component: <Payout/>
    // }
];