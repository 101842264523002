import { CAMPAIGN, CAMPAIGN_DETAILS,  } from '../types'

export const CampaignDetailsReducer = (state = {}, action) =>{
  const {type, payload} = action

  switch (type) {
    case CAMPAIGN_DETAILS: {
        const { data } = payload
        return data
    }
    default: {
      return state
    }
  }
}
