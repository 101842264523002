import React from "react";
import { Container, Box, IconButton } from "@mui/material";
import styled from "@emotion/styled";
import LoginForm from "../LoginForm/LoginForm";
import Logo from "../../assets/money_girl.jpg";
import Logo2 from "../../assets/lgbgsmall.svg";
import Money from "../../assets/Money.svg";
import { Avatar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const ContentStyle = styled("div")({
  width: "450px",
  height: "fit-content",
  minHeight: "480px",
  padding: 25,
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
  backgroundColor: "#292929",
});
let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

const Login = () => {
  const [height, setHeight] = React.useState(0);
  const navigate = useNavigate();
  console.log(height);
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://money.warehouse-x.io/register" />
        <title>Sign Up for X Money Affiliate Program by Warehouse X</title>
        <meta
          name="description"
          content={`Warehouse X affiliate marketing program X Money lets you earn 20% off your referrals for lifetime. Sign up and start earning now!`}
        />
        <meta
          property="og:title"
          content="Sign Up for X Money Affiliate Program by Warehouse X"
        />
        <meta
          property="og:description"
          content="Warehouse X affiliate marketing program X Money lets you earn 20% off your referrals for lifetime. Sign up and start earning now!"
        />
        <meta property="og:url" content="https://money.warehouse-x.io/register" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://warehouse-x.io/static/media/warehouse-x-logo.05ae9f6cb7027f71bb24.png"
        />
      </Helmet>
      <Box
        className="RegistrationBoxWrap"
        style={{
          backgroundImage: `url(${Logo})`,
        }}
      >
        <Container style={{ display: "flex" }} className="logincontainer">
          <Box className="logoGrid">
            <Avatar
              sx={{
                mr: 0.5,
                width: "300px",
                marginBottom: "20px",
                justifyContent: "center",
                height: "fit-content",
              }}
              variant="square"
              src={Money}
            />
          </Box>
          <div className="LoginForm">
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                navigate("/");
              }}
              aria-label="close"
              style={{
                width: "fit-content",
                marginLeft: "15px",

                position: "absolute",
                right: "5px",
                top: "5px",
                zIndex: 5,
                color: "white",
              }}
            >
              <CloseIcon />
            </IconButton>
            <LoginForm />
          </div>
        </Container>
      </Box>
    </>
  );
};

export default Login;
