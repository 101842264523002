import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { CheckBox, Handshake } from "@mui/icons-material";
import { removeErrors, setPurchaseType } from "../../redux/rootReducer";
import { connect, useDispatch, useSelector } from "react-redux";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Divider } from "@mui/material";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { RequiredPayout } from "../../redux/actions/userAction";
import { useSnackbar } from "notistack";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Banners from "./Banners";
import PreRolls from "./PreRolls";
import Interstitial from "./Interstitial";

const mapStateToProps = (state) => {
  return {
    purchase_type: state.purchaseType,
    session: state.session,
  };
};
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};
function Promo_tools({ purchase_type, session }) {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = React.useState(
    purchase_type && purchase_type
  );
  React.useEffect(() => {
    if (purchase_type == "company") {
      setSelectedType("company");
    } else {
      setSelectedType("personal");
    }
  }, [purchase_type]);
  React.useEffect(() => {
    dispatch(setPurchaseType(selectedType));
  }, [selectedType]);

  const { enqueueSnackbar } = useSnackbar();
  const errorsSubmit = useSelector((state) => state.errors);
  React.useEffect(() => {
    if (errorsSubmit && errorsSubmit.bank_address) {
      enqueueSnackbar(errorsSubmit.bank_address[0], { variant: "error" });
    }
    if (errorsSubmit && errorsSubmit.bank_name) {
      enqueueSnackbar(errorsSubmit.bank_name[0], { variant: "error" });
    }
    if (errorsSubmit && errorsSubmit.bic) {
      enqueueSnackbar(errorsSubmit.bic[0], { variant: "error" });
    }
    if (errorsSubmit && errorsSubmit.iban) {
      enqueueSnackbar(errorsSubmit.iban[0], { variant: "error" });
    }
    if (errorsSubmit && errorsSubmit.recipient) {
      enqueueSnackbar(errorsSubmit.recipient[0], { variant: "error" });
    }
    if (errorsSubmit && errorsSubmit.crypto_currency) {
      enqueueSnackbar(errorsSubmit.crypto_currency[0], { variant: "error" });
    }
    if (errorsSubmit && errorsSubmit.crypto_wallet) {
      enqueueSnackbar(errorsSubmit.crypto_wallet[0], { variant: "error" });
    }

    setTimeout(() => {
      dispatch(removeErrors());
    }, 5000);
  }, [errorsSubmit]);

  const [tab, settab] = useState("banners");

  const handleChangeTab = (event, newValue) => {
    settab(newValue);
  };
  return (
    <>
      <TabContext value={tab}>
        <Box
          maxWidth="xl"
          sx={{ borderBottom: 1, borderColor: "divider", margin: "20px auto" }}
        >
          <TabList onChange={handleChangeTab} aria-label="Pages">
            <Tab
              label={"Banners"}
              className="ProfileTab"
              value="banners"
            />
            <Tab
              label={"PreRolls"}
              className="ProfileTab"
              value="prerolls"
            />
             <Tab
              label={"Interstitials"}
              className="ProfileTab"
              value="interstitial"
            />
          </TabList>
        </Box>
        <TabPanel style={{ padding: 0 }} value="banners">
          {/* <MyAccount session={session} /> */}
          {/* <PayoutHistory /> */}
          <Banners/>
        </TabPanel>
        <TabPanel style={{ padding: 0 }} value="prerolls">
       <PreRolls/>
        </TabPanel>
        <TabPanel style={{ padding: 0 }} value="interstitial">
         <Interstitial/>
        </TabPanel>
      </TabContext>
    </>
  );
}

export default connect(mapStateToProps)(Promo_tools);
