import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutAdmin, logoutUser } from '../../redux/actions/userAction';

const mapStateToProps = (state) => {
  return {
      session:state.session
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logoutUser: () => dispatch(logoutUser()),
        logoutAdmin: ()=> dispatch(logoutAdmin())
    }
}

function Logout({logoutUser,logoutAdmin,session}) {
  const navigate = useNavigate();
  useEffect(() => {
    if(session.user.role==='affiliate_admin'){
      logoutAdmin()
    localStorage.removeItem('access_token')
    localStorage.removeItem('persist:root')
    navigate('/admin/login');
    }
    else{
      logoutUser()
      localStorage.removeItem('access_token')
      localStorage.removeItem('persist:root')
      navigate('/register');
    }

  }, []);
  return (
    <>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
