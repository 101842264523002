import { PAYOUT } from "../types";

export const payoutReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case PAYOUT: {
      console.log('here')
      console.log(payload)
        const { data } = payload
        
        state = {};
        return data
    }
    default: {
        return state;
      }
    }
  }