import {
  Button,
  Container,
  Divider,
  Grid,
  Grow,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { removeErrors, setPurchaseType } from "../../redux/rootReducer";
import { connect, useDispatch, useSelector } from "react-redux";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { SupportRequest } from "../../redux/actions/userAction";
import { useSnackbar } from "notistack";
import Faq from "react-faq-component";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";

const mapStateToProps = (state) => {
  return {
    purchase_type: state.purchaseType,
  };
};
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

function Support({ purchase_type }) {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = React.useState(
    purchase_type && purchase_type,
  );
  React.useEffect(() => {
    if (purchase_type == "company") {
      setSelectedType("company");
    } else {
      setSelectedType("personal");
    }
  }, [purchase_type]);
  React.useEffect(() => {
    dispatch(setPurchaseType(selectedType));
  }, [selectedType]);

  const [inputs, setInputs] = React.useState({
    subject: "",
    description: "",
  });

  function handleChange(evt) {
    const value = evt.target.value;
    setInputs({
      ...inputs,
      [evt.target.name]: value,
    });
  }

  function handleRequest() {
    dispatch(SupportRequest(inputs));
    setInputs({ subject: "", description: "" });
  }

  const currencies = [
    {
      value: "Live Show page",
      label: "$",
    },
    {
      value: "Landing page",
      label: "€",
    },
    {
      value: "Housemate page",
      label: "฿",
    },
  ];

  const [rows, setRowsOption] = useState(null);
  const data = {
    title: "FAQ (How it works)",
    rows: [
      {
        title: "-What is Money X?",
        content: (
          <p>
            Money X is an Official Affiliate Program of World Famouse{" "}
            <a
              href="https://warehouse-x.io/"
              className="faq_link"
              target={"_blank"}
              rel="noreferrer"
            >
              Warehouse-x.io.
            </a>
          </p>
        ),
      },
      {
        title: "- How much do I earn with you?",
        content: (
          <p>
            Money X offers 25% Revenue Share for every subscription or purchase
            on our Website -{" "}
            <a
              href="https://warehouse-x.io/"
              className="faq_link"
              target={"_blank"}
              rel="noreferrer"
            >
              Warehouse-x.io.
            </a>
            . Join us and start making money online!{" "}
          </p>
        ),
      },
      {
        title: "- Which payment methods do you offer?",
        content: `Right now we offer cryptocurrencies, but you can always contact your Manager and discuss other payment methods. `,
      },
      {
        title: "- Where can I see clicks, registration amount and earnings?",
        content: (
          <p>
            Everything is available here in Stats Section -{" "}
            <a
              href="https://money.warehouse-x.io/stats"
              className="faq_link"
              target={"_blank"}
              rel="noreferrer"
            >
              money.warehouse-x.io/stats
            </a>
          </p>
        ),
      },
      {
        title:
          "- What should I do if I have any questions or improvement offers?",
        content: (
          <p>
            Contact your Manager via the Support Section and He/She will answer
            you ASAP. We are always open for new upgrades!
          </p>
        ),
      },
      {
        title: "- How much will I earn for a signup of a user?",
        content: (
          <Grid
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <Grid>
              <p>{t("Tier 1 - $2")}</p>
              <Divider />
              <p>
                {t(
                  "Australia, Austria, Belgium, Canada, Denmark, Finland, France, Germany, Ireland, Italy, Luxembourg, Netherlands, New Zealand, Norway, Spain, Sweden, Switzerland, United Kingdom, United States of America",
                )}
              </p>
            </Grid>
            <Grid>
              <p>{t("Tier 2 - $1")}</p>
              <Divider />
              <p>
                {t(
                  "Andorra, Argentina, Bahamas, Belarus, Bolivia, Bosnia and Herzegovina, Brazil, Brunei, Bulgaria, Chile, China, Colombia, Costa Rica, Croatia, Cyprus, Czech Republic, Dominican Republic, Ecuador, Egypt, Estonia, Fiji, Greece, Guyana, Hong Kong, Hungary, Iceland, Indonesia, Israel, Japan, Kazakhstan, Latvia, Lithuania, Macao, Malaysia, Malta, Mexico, Montenegro, Morocco, Nepal, Oman, Panama, Paraguay, Peru, Philippines, Poland, Portugal, Puerto Rico, Qatar, Republic of Korea (South), Romania, Russian Federation, Saudi Arabia, Serbia, Singapore, Slovakia, Slovenia, South Africa, Thailand, Turkey, Ukraine, United Arab Emirates, Uruguay, Vanuatu",
                )}
              </p>
            </Grid>
            <Grid>
              <p>{t("Tier 3 - $0.5")}</p>
              <Divider />
              <p>
                {t(
                  "Albania, Algeria, Angola, Armenia, Azerbaijan, Bahrain, Bangladesh, Barbados, Belize, Benin, Botswana, Burkina Faso, Burundi, Cambodia, Cameroon, Cape Verde, Chad, Comoros, Congo, El Salvador, Ethiopia, Gabon, Georgia, Guatemala, Guinea, Haiti, Honduras, India, Iraq, Jamaica, Jordan, Kenya, Kuwait, Kyrgyzstan, Laos, Lebanon, Lesotho, Macedonia, Madagascar, Mali, Mauritania, Mauritius, Moldova, Mongolia, Mozambique, Namibia, Nicaragua, Niger, Nigeria, Pakistan, Senegal, Sri Lanka, Suriname, Swaziland, Tajikistan, Tanzania, Togo, Trinidad and Tobago, Tunisia, Turkmenistan, Uganda, Uzbekistan, Vietnam, Zambia",
                )}
              </p>
            </Grid>
            <Grid>
              <p>{t("Tier 4 - $0.1")}</p>
              <Divider />
              <p>
                {t(
                  "Afghanistan, Aland Islands, American Samoa, Anguilla, Antigua and Barbuda, Aruba, Bermuda, Bhutan, British Indian Ocean Territory, Brunei Darussalam, Cayman Islands, Central African Republic, Christmas Island, Cocos (Keeling) Islands, Congo, The Democratic Republic of the, Cook Islands, Cote d'Ivoire, Cuba, Curacao, Djibouti, Dominica, Equatorial Guinea, Eritrea, Falkland Islands (Malvinas), Faroe Islands, French Polynesia, French Southern Territories, Gambia, Ghana, Gibraltar, Greenland, Grenada, Guam, Guernsey, Guinea-Bissau, Holy See (Vatican City State), Iran, Islamic Republic of, Isle of Man, Jersey, Kiribati, Korea, Democratic People's Republic of, Lao People's Democratic Republic, Liberia, Libyan Arab Jamahiriya, Liechtenstein, Macedonia, The former Yugoslav Republic of, Malawi, Maldives, Marshall Islands, Martinique, Micronesia, Federated States of, Zimbabwe, Monaco, Montserrat, Myanmar, Nauru, New Caledonia, Niue, Norfolk Island, Northern Mariana Islands, Palau, Palestinian Territory, Occupied, Papua New Guinea, Pitcairn, Reunion, Rwanda, Saint Kitts and Nevis, Saint Lucia, Saint Vincent and The Grenadines, Samoa, San Marino, Sao Tome and Principe, Seychelles, Sierra Leone, Sint Maarten (Dutch Part), Solomon Islands, Somalia, South Georgia and The South Sandwich Islands, South Sudan, Sudan, Syrian Arab Republic, Taiwan, Province of China, Tanzania, United Republic of, Timor-Leste, Tokelau, Tonga, Turks and Caicos Islands, Tuvalu, Venezuela, Bolivarian Republic of, VietNam, Virgin Islands, British, Virgin Islands, U.S., Western Sahara, Yemen",
                )}
              </p>
            </Grid>
          </Grid>
        ),
      },
      {
        title: "- What are conditions for registration to be counted?",
        content: (
          <p>We pay for SOI registrations. Credit card is not required!</p>
        ),
      },
    ],
  };

  const { enqueueSnackbar } = useSnackbar();
  const errorsSubmit = useSelector((state) => state.errors);
  React.useEffect(() => {
    if (errorsSubmit && errorsSubmit.subject) {
      enqueueSnackbar(errorsSubmit.subject[0], { variant: "error" });
    }
    if (errorsSubmit && errorsSubmit.description) {
      enqueueSnackbar(errorsSubmit.description[0], { variant: "error" });
    }

    setTimeout(() => {
      dispatch(removeErrors());
    }, 5000);
  }, [errorsSubmit]);
  return (
    <Container maxWidth="xl">
      <Box sx={{ flexGrow: 1 }}>
        <Grid className="container" container spacing={4}>
          <Grow in timeout={200} delay={400}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid className="data_headers_support">
                <Typography
                  style={{
                    color: "white",
                    marginTop: "15px",
                    font: "normal normal bold 1.5rem Effra",
                    marginRight: "10px",
                    width: "fit-content",
                  }}
                >
                  Questions?
                </Typography>
                <Typography
                  className="green_text"
                  style={{ color: "#77F73B", fontSize: "0.8rem" }}
                >
                  All questions on service. Expect response in 24 hours.
                </Typography>
              </Grid>
            </Grid>
          </Grow>

          <Grow in timeout={200} delay={400}>
            <Grid
              item
              xs={12}
              className="SupportGrid"
              style={{ paddingTop: "10px" }}
            >
              <Grid className="DataBoxSupport">
                <Grid item xs={12} className="support">
                  <Grid className="support_subject">
                    <Box
                      className="subject"
                      initial={{ opacity: 0, y: 40 }}
                      animate={animate}
                    >
                      <TextField
                        fullWidth
                        placeholder="Subject"
                        name="subject"
                        value={inputs.subject}
                        onChange={handleChange}
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleOutlinedIcon
                                style={{ color: "black" }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <Link
                      href={"https://join.skype.com/invite/BwxJsGgfKJJa"}
                      target="_blank"
                      style={{
                        color: "#77f73b",
                        textDecorationColor: "#77f73b",
                      }}
                      // underline="none"
                    >
                      <Typography style={{ color: "#77f73b" }}>
                        Use Skype to contact your Manager
                      </Typography>
                    </Link>
                    {/* <Grid className="support_respond_type" item>
                  <Typography className="Support_selector">
                    How should we respond?
                  </Typography>
                  <Select
                    className="LandingSelect"
                    defaultValue=""
                    displayEmpty
                    renderValue={(value) => {
                      console.log(value);
                      return (
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <SvgIcon color="black">
                            <FilterListOutlinedIcon />
                          </SvgIcon>
                          <div
                            style={{
                              display: "flex",
                              width: "-webkit-fill-available",
                              justifyContent: "center",
                            }}
                          >
                            {value}
                          </div>
                        </Box>
                      );
                    }}
                  >
                    {currencies.map((option) => (
                      <MenuItem
                        style={{ textAlign: "center" }}
                        key={option.value}
                        value={option.value}
                      >
                        {option.value}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid> */}
                  </Grid>
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    className="subject"
                    initial={{ opacity: 0, y: 40 }}
                    animate={animate}
                    style={{ marginTop: "16px", width: "100%" }}
                  >
                    <TextField
                      fullWidth
                      multiline
                      minRows={4}
                      name="description"
                      value={inputs.description}
                      onChange={handleChange}
                      placeholder="Message"
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "5px",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grow>
          {/* <Grid
            item
            xs={12}
            style={{ display: "flex", flexDirection: "row", marginTop: "15px" }}
          >
            <Divider
              style={{
                opacity: 1,
                width: "100%",
                border: "2px solid white",
                borderRadius: "10px",
              }}
              component="div"
            />
          </Grid> */}
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "15px",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
            <Button
              size="large"
              type="submit"
              variant="contained"
              className="MainSaveButton"
              onClick={handleRequest}
              /*                             style={{ backgroundColor: "#77F73B", font: "normal normal 800 1.2rem Effra", width: '20% ' }} */
            >
              {"send"}
            </Button>
          </Grid>
          <Grow in timeout={200} delay={400}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "15px",
                justifyContent: "center",
                marginBottom: "30px",
              }}
            >
              <Box className="FaqBox">
                <Faq
                  style={{ width: "100%" }}
                  data={data}
                  getRowOptions={setRowsOption}
                />
              </Box>
            </Grid>
          </Grow>
        </Grid>
      </Box>
    </Container>
  );
}

export default connect(mapStateToProps)(Support);
