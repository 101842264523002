import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SvgIcon,
  TableCell,
  tableCellClasses,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { connect, useDispatch, useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {
  AdminPayoutsApprove,
  cancelAdminPayoutTransaction,
  changeAdminPayoutStatus,
  getAdminPayout,
  GetUserStats,
} from "../../redux/actions/AdminMembersActions";
import { useNavigate, useParams } from "react-router-dom";
import {
  DataGrid,
  useGridApiContext,
  useGridApiEventHandler,
} from "@mui/x-data-grid";
import DateRangePickerComp from "../../components/DateRangePickerComp/DateRangePickerComp";
import DateRangePickerCompGlobal from "../../components/DateRangePickerComp/DateRangePickerCompGlobal";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CloseIcon from "@mui/icons-material/Close";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CancelIcon from "@mui/icons-material/Cancel";
const mapStateToProps = (state) => {
  return {
    global_statistics: state.global_statistics,
    user_statistics: state.user_statistics,
    admin_payout: state.admin_payout,
  };
};
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};
function AdminPayout({ global_statistics, user_statistics, admin_payout }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();
  React.useEffect(() => {
    dispatch(getAdminPayout(param.id));
  }, []);

  console.log(admin_payout);
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#292929",
      height: "40px",
      padding: "0px 16px",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#1F1F1F",
      height: "40px",
      padding: "0px 16px",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    root: {
      height: 30,
    },
    "&.MuiTableRow-root:hover": {
      backgroundColor: "#555555",
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#292929",
      color: theme.palette.common.white,
      padding: "0px 16px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const yearsMonths = [
    { id: 0, name: "January" },
    { id: 1, name: "February" },
    { id: 2, name: "March" },
    { id: 3, name: "April" },
    { id: 4, name: "May" },
    { id: 5, name: "June" },
    { id: 6, name: "July" },
    { id: 7, name: "August" },
    { id: 8, name: "September" },
    { id: 9, name: "October" },
    { id: 10, name: "November" },
    { id: 11, name: "December" },
  ];
  function getTime(time) {
    var date = new Date(time * 1000);
    const month_number = date.getMonth();
    const month = yearsMonths[month_number].name;
    // 👉️ 0
    // 👇️ returns day of the month (1-31)
    const day_month = date.getDate();
    const hours = date.getHours(); // get hours with getHours method
    const minutes = date.getMinutes(); // get minutes with getMinutes method // get seconds with getSeconds method
    const timeString = `${day_month} ${month} ${pad(hours)}:${pad(minutes)}`;
    return timeString; // finally, join to a time string
  }

  function pad(num) {
    return ("0" + num).slice(-2);
  }
  const [view_type, setview_type] = useState(null);

  const time = useSelector((state) => state.time_picker);

  function pad(num) {
    return ("0" + num).slice(-2);
  }
  let first = time && time[0] && new Date(time[0].startDate);
  let last = time && time[0] && new Date(time[0].endDate);
  first =
    time[0] &&
    first.getFullYear() +
      "-" +
      pad(first.getMonth() + 1) +
      "-" +
      pad(first.getDate());
  last =
    time[0] &&
    last.getFullYear() +
      "-" +
      pad(last.getMonth() + 1) +
      "-" +
      pad(last.getDate());

  React.useEffect(() => {
    setview_type(admin_payout?.payout?.status);
  }, [admin_payout?.payout?.status]);
  const columns = [
    { field: "createdAt", headerName: "Created", width: "270" },
    { field: "payment_reference_id", headerName: "Reference Id", width: "270" },
    {
      field: "profit",
      headerName: "Profit",
      width: "270",
      renderCell: (params) => {
        return <Typography>{params.row.profit}$</Typography>;
      },
    },
    {
      field: "turnover",
      headerName: "Turnover",
      width: "270",
      renderCell: (params) => {
        return <Typography>{params.row.turnover}$</Typography>;
      },
    },
    {
      field: "is_cancelled",
      headerName: "Status",
      width: "270",
      renderCell: (params) => {
        return (
          <Typography>
            {params.row.is_cancelled == false ? "" : "Canceled"}
          </Typography>
        );
      },
    },
    {
      headerName: "Decline",
      width: "100",
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            style={{
              color: "white",
              width: "10px",
              padding: "0px",
              border: "unset",
              borderRadius: "5px",
            }}
            type="button"
            onClick={() => {
              navigateTo(params.row);
            }}
          >
            <CancelIcon style={{ color: "#d11313" }} />
          </Button>
        );
      },
    },
  ];

  const [data_rows, setdata_rows] = useState([]);
  React.useEffect(() => {
    setdata_rows(admin_payout?.transactions);
  }, [admin_payout?.transactions]);

  const loading = useSelector((state) => state.loading);
  const [selectionModel, setSelectionModel] = React.useState();
  const [pageSize, setPageSize] = React.useState(10);

  const selector = [
    {
      value: "requested",
      label: "$",
    },
    {
      value: "in_progress",
      label: "€",
    },
    {
      value: "paid_out",
      label: "1",
    },
    {
      value: "declined",
      label: "2",
    },
  ];

  const [open, setOpen] = React.useState(false);
  const [transactionId, setId] = React.useState(null);
  function navigateTo(item) {
    setId(item.id)
    setOpen(true);
  }
    const handleClose = () => {
      setOpen(false);
    };
    const [user_view, setuser_view] = React.useState(null);
    const handleChangeUser = (event) => {
      console.log(event.target.value);
      setuser_view(event.target.value);
      dispatch(GetUserStats(event.target.value.id));
    };
    const payable_type =
      admin_payout?.payout?.user?.latest_payment_method?.payable_type;

    const handleChangeStatus = (event) => {
      console.log(event.target.value);
      setview_type(event.target.value);
      dispatch(changeAdminPayoutStatus(param.id, event.target.value));
    };

    function ApplyCancel() {
      console.log(transactionId)
      if(message.length !==0){
        setOpen(false);
        dispatch(cancelAdminPayoutTransaction(transactionId,{cancel_reason:message}))
        setMessage('')
      }
    }
    const [message, setMessage] = useState("");

  const handleMessageChange = (event) => {
    // 👇️ update textarea value
    setMessage(event.target.value);
  };
    return (
      <>
        <Container maxWidth="xl">
          <Box sx={{ flexGrow: 1 }}>
            <Grid className="container" container spacing={4}>
              <Grid item xs={12} className="SupportGrid">
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: window.innerWidth<900? "100%":'30%',
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={{ color: "white" }}>
                      {admin_payout?.payout?.user?.username.toUpperCase()} DATA
                    </Typography>
                  </Grid>
                  <Grid className="Payout_type" item></Grid>
                  <Box
                    className="payoutField"
                    initial={{ opacity: 0, y: 40 }}
                    animate={animate}
                  >
                    <MailOutlineIcon
                      sx={{ color: "action.active", mr: 1, my: 0.5 }}
                      style={{marginBottom:'0px'}}
                    />
                    <TextField
                      fullWidth
                      multiline
                      name="recipient"
                      disabled
                      value={admin_payout?.payout?.user?.email}
                      // onChange={handleChange}
                      placeholder="Recipient name"
                      variant="standard"
                      style={{ backgroundColor: "#191919", paddingTop: "25px" }}
                      type="email"
                    />
                  </Box>
                  {payable_type === "wire" && (
                    <>
                      <Box
                        className="payoutField"
                        initial={{ opacity: 0, y: 40 }}
                        animate={animate}
                      >
                        <MailOutlineIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          fullWidth
                          multiline
                          name="recipient"
                          disabled
                          value={
                            admin_payout?.payout?.user?.latest_payment_method
                              ?.payable?.recipient
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                Name
                              </InputAdornment>
                            ),
                          }}
                          // onChange={handleChange}
                          placeholder="Recipient name"
                          variant="standard"
                          style={{
                            backgroundColor: "#191919",
                            paddingTop: "25px",
                          }}
                          type="email"
                        />
                      </Box>
                      <Box
                        className="payoutField"
                        initial={{ opacity: 0, y: 40 }}
                        animate={animate}
                      >
                        <MailOutlineIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          fullWidth
                          multiline
                          name="iban"
                          disabled
                          value={
                            admin_payout?.payout?.user?.latest_payment_method
                              ?.payable?.iban
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                Iban
                              </InputAdornment>
                            ),
                          }}
                          // value={inputs.iban}
                          // onChange={handleChange}
                          placeholder="IBAN"
                          variant="standard"
                          style={{
                            backgroundColor: "#191919",
                            paddingTop: "25px",
                          }}
                          type="email"
                        />
                      </Box>
                      <Box
                        className="payoutField"
                        initial={{ opacity: 0, y: 40 }}
                        animate={animate}
                      >
                        <MailOutlineIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          fullWidth
                          multiline
                          name="bic"
                          disabled
                          value={
                            admin_payout?.payout?.user?.latest_payment_method
                              ?.payable?.bic
                          }
                          // value={inputs.bic}
                          // onChange={handleChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                BIC / SWIFT
                              </InputAdornment>
                            ),
                          }}
                          placeholder="BIC / SWIFT"
                          variant="standard"
                          style={{
                            backgroundColor: "#191919",
                            paddingTop: "25px",
                          }}
                          type="email"
                        />
                      </Box>
                      <Box
                        className="payoutField"
                        initial={{ opacity: 0, y: 40 }}
                        animate={animate}
                      >
                        <MailOutlineIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          fullWidth
                          multiline
                          disabled
                          name="bank_name"
                          value={
                            admin_payout?.payout?.user?.latest_payment_method
                              ?.payable?.bank_name
                          }
                          // value={inputs.bank_name}
                          // onChange={handleChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                Bank name
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Bank name"
                          variant="standard"
                          style={{
                            backgroundColor: "#191919",
                            paddingTop: "25px",
                          }}
                          type="email"
                        />
                      </Box>
                      <Box
                        className="payoutField"
                        initial={{ opacity: 0, y: 40 }}
                        animate={animate}
                      >
                        <MailOutlineIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          fullWidth
                          multiline
                          name="bank_address"
                          disabled
                          value={
                            admin_payout?.payout?.user?.latest_payment_method
                              ?.payable?.bank_address
                          }
                          // value={inputs.bank_address}
                          // onChange={handleChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                Bank address
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Bank address"
                          variant="standard"
                          style={{
                            backgroundColor: "#191919",
                            paddingTop: "25px",
                          }}
                          type="email"
                        />
                      </Box>
                    </>
                  )}
                  {payable_type === "crypto" && (
                    <>
                      <Box
                        className="payoutField"
                        initial={{ opacity: 0, y: 40 }}
                        animate={animate}
                      >
                        <MailOutlineIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          fullWidth
                          multiline
                          disabled
                          name="crypto_wallet"
                          // value={inputs.crypto_wallet}
                          // onChange={handleChange}
                          placeholder="Crypto Cur."
                          value={
                            admin_payout?.payout?.user?.latest_payment_method
                              ?.payable?.crypto_currency
                          }
                          variant="standard"
                          style={{
                            backgroundColor: "#191919",
                            paddingTop: "25px",
                          }}
                          type="email"
                        />
                      </Box>
                      <Box
                        className="payoutField"
                        initial={{ opacity: 0, y: 40 }}
                        animate={animate}
                      >
                        <MailOutlineIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          fullWidth
                          multiline
                          disabled
                          name="crypto_wallet"
                          // value={inputs.crypto_wallet}
                          // onChange={handleChange}
                          placeholder="Crypto wallet"
                          value={
                            admin_payout?.payout?.user?.latest_payment_method
                              ?.payable?.crypto_wallet
                          }
                          variant="standard"
                          style={{
                            backgroundColor: "#191919",
                            paddingTop: "25px",
                          }}
                          type="email"
                        />
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                className="stats_body"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: "46px",
                }}
              >
                <Grid className="stats_type" item>
                  <Typography className="Support_selector">Status</Typography>
                  <Select
                    className="LandingSelect"
                    value={view_type && view_type}
                    onChange={handleChangeStatus}
                    displayEmpty
                    renderValue={(value) => {
                      return (
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <SvgIcon color="black">
                            <FilterListOutlinedIcon />
                          </SvgIcon>
                          <div
                            style={{
                              display: "flex",
                              width: "-webkit-fill-available",
                              justifyContent: "center",
                            }}
                          >
                            {value && value}
                          </div>
                        </Box>
                      );
                    }}
                  >
                    {selector.map((option) => (
                      <MenuItem
                        style={{ textAlign: "center" }}
                        key={option.value}
                        value={option.value}
                      >
                        {option.value}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: "46px",
              }}
            >
              <Typography
                style={{
                  color: "white",
                  font: "normal normal bold 1.5rem Effra",
                  paddingBottom: "10px",
                }}
              >
                Transactions
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "0px" }}>
              <div style={{ height: "600px", width: "100%" }}>
                {data_rows && (
                  <DataGrid
                    rows={data_rows && data_rows}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 25, 50]}
                    //   checkboxSelection
                    loading={loading}
                  />
                )}
              </div>
            </Grid>
          </Box>
        </Container>
        <Dialog
          className="pop-up_verify Dialog_transaction"
          open={open}
          onClose={handleClose}
       
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{
              width: "fit-content",
              marginLeft: "15px",
              marginTop: "5px",
              position: "absolute",
              right: "5px",
              top: "2px",
              zIndex: 5,
              color: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle>
            Please write reason of Cancel
          </DialogTitle>
          <DialogActions
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "20px 24px 5px",
            }}
          >
          <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-end",
                        gap: "16px",
                        width: "80%",
                      }}
                      initial={{ opacity: 0, y: 40 }}
                      animate={animate}
                    >
                      <MailOutlineIcon
                        sx={{ color: "action.active", mr: 1, my: 0.5 }}
                      />
                      <TextField
                        fullWidth
                        name="company_register_code"
                        // value={inputs.company_register_code}
                        // onChange={handleChange}
                        placeholder="Cancel reason"
                        variant="standard"
                        style={{
                          backgroundColor: "#191919",
                          paddingTop: "25px",
                          maxHeight:'150px',
                          overflow:'auto'
                        }}
                        type="text"
                        multiline
                        required
                        onChange={handleMessageChange}
                        error={message.length === 0 ? true : false}
                        helperText={message.length === 0 && 'Reason is required'}
                      />
                    </Box>
          </DialogActions>
          <DialogContent
            style={{ overflow: "unset", width: "1500px", paddingTop: "30px" }}
          >
            <Button
              className="new_campaign"
              style={{
                background: "#77F73B 0% 0% no-repeat padding-box",
                color: "black",
                boxShadow: "0px 3px 6px #00000029",
                font: "normal normal bold 1rem Montserrat",
                borderRadius: "15px",
                padding: "7px 15px",
                marginTop: "15px",
              }}
              onClick={() => {
                ApplyCancel();
              }}
            >
              Send
            </Button>
          </DialogContent>
        </Dialog>
      </>
    );
  }

export default connect(mapStateToProps)(AdminPayout);
