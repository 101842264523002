export const USER = 'USER';
export const USER_UPDATE = 'USER_UPDATE';

export const ERROR = 'ERROR';
export const ERRORS = 'ERRORS';

export const ERROR_REMOVE = 'ERROR_REMOVE';
export const ERRORS_REMOVE = 'ERRORS_REMOVE';

export const MODAL_ENABLE = 'MODAL_ENABLE';
export const MODAL_DISABLE = 'MODAL_DISABLE';

export const TAB = 'TAB';
export const TAB_REMOVE = 'TAB_REMOVE';

export const MESSAGES = 'MESSAGES';
export const MESSAGE = 'MESSAGE';
export const MESSAGE_REMOVE = 'MESSAGE_REMOVE';
export const MESSAGES_REMOVE = 'MESSAGES_REMOVE';

export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const SET_LOADING = "SET_LOADING";
export const REMOVE_LOADING = "REMOVE_LOADING";

export const SET_LOADINGS = "SET_LOADINGS";
export const REMOVE_LOADINGS = "REMOVE_LOADINGS";

export const BINOM = 'BINOM'
export const BINOM_REMOVE = 'BINOM_REMOVE'
/////////////// NEW CONSTS

export const CAMPAIGNS = 'CAMPAIGNS'
export const CAMPAIGN = 'CAMPAIGN'
export const CAMPAIGN_DETAILS = 'CAMPAIGN_DETAILS'
export const CAMPAIGN_UPDATE = 'CAMPAIGN_UPDATE'

export const LANGUAGE = "LANGUAGE";

export const COINS = "COINS";
export const COINS_ADD_ONE = "COINS_ADD_ONE";
export const COINS_REMOVE = "COIN_REMOVE";
export const COINS_REMOVE_ONE = "COINS_REMOVE_ONE";



export const PURCHASE_TYPE = 'PURCHASE_TYPE'
export const PURCHASE_TYPE_REMOVE = 'PURCHASE_TYPE_REMOVE'

export const STATS_TYPE = 'STATS_TYPE'
export const STATS_TYPE_REMOVE = 'STATS_TYPE_REMOVE'


export const TIME_PICKER = 'TIME_PICKER'

export const PAYOUT = 'PAYOUT'


//// NEW CONSTS FOR ADMIN
export const MEMBERS = 'MEMBERS'
export const MEMBER = 'MEMBER'
export const FETCH_MEMBER = 'FETCH_MEMBER'


export const GLOBAL_STATS = 'GLOBAL_STATS'
export const USER_STATS = 'USER_STATS'
export const ADMIN_PAYOUTS = 'ADMIN_PAYOUTS'
export const ADMIN_PAYOUT = 'ADMIN_PAYOUT'
export const ADMIN_PAYOUT_PATCH = 'ADMIN_PAYOUT_PATCH'
export const ADMIN_PAYOUT_TRANSACTION_PATCH = 'ADMIN_PAYOUT_TRANSACTION_PATCH'