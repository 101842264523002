import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { setNewPasswordAction } from "../../redux/actions/userAction";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { hideModal, removeErrors, showModal } from "../../redux/rootReducer";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

export default function PasswordModal() {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modal);
  const errors = useSelector((state) => state.errors);
  const { t } = useTranslation();
  const handleOpenPass = () => {
    // eslint-disable-next-line no-lone-blocks
    {
      modal && modal.name === "password"
        ? dispatch(hideModal("password"))
        : dispatch(showModal("password"));
    }
    setPasswordInfo({
      oldPassword: "",
      password: "",
      password_confirmation: "",
      showPassword: {
        oldPassword: false,
        password: false,
        password_confirmation: false,
      },
    });
    dispatch(removeErrors());
  };

  const [passwordInfo, setPasswordInfo] = React.useState({
    oldPassword: "",
    password: "",
    password_confirmation: "",
    showPassword: {
      oldPassword: false,
      password: false,
      password_confirmation: false,
    },
  });

  const handleChange = (prop) => (event) => {
    setPasswordInfo({ ...passwordInfo, [prop]: event.target.value });
  };

  const handleClickShowPassword = (type) => {
    setPasswordInfo({
      ...passwordInfo,
      showPassword: {
        ...passwordInfo.showPassword,
        [type]: !passwordInfo.showPassword[type],
      },
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function handlePassSubmit(e) {
    e.preventDefault();
    dispatch(setNewPasswordAction(passwordInfo));
  }

  const { enqueueSnackbar } = useSnackbar();
  React.useEffect(() => {
    if (errors && errors.oldPassword) {
      enqueueSnackbar(errors.oldPassword, { variant: "error" });
    }
    if (errors && errors.password) {
      enqueueSnackbar(errors.password[0], { variant: "error" });
    }
    if (errors && errors.password_confirmation) {
      enqueueSnackbar(errors.password_confirmation[0], {
        variant: "error",
      });
    }

    setTimeout(() => {
      dispatch(removeErrors());
    }, 5000);
  }, [errors]);

  const stylePass = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 400,
    minWidth: 300,
    width: "100%",
    height: "fit-content",
    p: 4,
    color: "#FFFFFF",
  };
  return (
    <Modal
      open={modal && modal.name === "password"}
      onClose={handleOpenPass}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={stylePass} className="modal">
        <Grid container spacing={0}>
          <Grid item xs={11} style={{ fontSize: "20px" }}>
            <Typography id="modal-modal-description">
              {t("Change Password")}
            </Typography>
          </Grid>
          {/* <Grid item xs={1}>
            <Button
              size="small"
              color="white"
              onClick={handleOpenPass}
              sx={{
                color: "white",
                width: "fit-content",
                minWidth: "unset",
                position: "relative",
                top: "-15px",
                right: "-15px",
              }}
            >
              <CloseIcon sx={{ height: "22px", width: "22px" }} />
            </Button>
          </Grid>*/}
        </Grid>
        <form onSubmit={handlePassSubmit}>
          <FormControl
            variant="outlined"
            style={{
              margin: "2px",
              marginTop: "10px",
              maxWidth: "350px",
              width: "100%",
            }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              {t("Current Password")}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={passwordInfo.showPassword.oldPassword ? "text" : "password"}
              value={passwordInfo.oldPassword}
              onChange={handleChange("oldPassword")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("oldPassword")}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {passwordInfo.showPassword.oldPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label={t("Current Password")}
            />
          </FormControl>
          {/* {errors && errors.oldPassword && (
            <Grid item xs={11} sx={{ mt: 1 }}>
              <Alert variant="outlined" severity="error">
                {errors.oldPassword}
              </Alert>
            </Grid>
          )} */}

          <FormControl
            variant="outlined"
            style={{
              margin: "2px",
              marginTop: "35px",
              maxWidth: "350px",
              width: "100%",
            }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              {t("New password")}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={passwordInfo.showPassword.password ? "text" : "password"}
              value={passwordInfo.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("password")}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {passwordInfo.showPassword.password ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label={t("New password")}
            />
          </FormControl>
          {/* {errors && errors.password && (
            <Grid item xs={11} sx={{ mt: 1 }}>
              <Alert variant="outlined" severity="error">
                {errors.password}
              </Alert>
            </Grid>
          )} */}
          <FormControl
            variant="outlined"
            style={{
              margin: "2px",
              marginTop: "10px",
              maxWidth: "350px",
              width: "100%",
            }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              {t("Repeat new password")}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={
                passwordInfo.showPassword.password_confirmation
                  ? "text"
                  : "password"
              }
              value={passwordInfo.password_confirmation}
              onChange={handleChange("password_confirmation")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      handleClickShowPassword("password_confirmation")
                    }
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {passwordInfo.showPassword.password_confirmation ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label={t("Repeat new password")}
            />
          </FormControl>
          {/* {errors && errors.password_confirmation && (
            <Grid item xs={11} sx={{ mt: 1 }}>
              <Alert variant="outlined" severity="error">
                {errors.password_confirmation}
              </Alert>
            </Grid>
          )} */}
          <Stack
            direction="row"
            mt={2}
            spacing={2}
            style={{ paddingBottom: "10px" }}
          >
            <Button
              type="submit"
              color="warning"
              sx={{ bgcolor: "#FFD800" }}
              variant="contained"
              style={{
                padding: "10px 100px",
                color: "#000000",
                margin: "10px auto",
                fontWeight: "bold",
              }}
            >
              {t("SAVE")}
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
}
