import { ADMIN_PAYOUTS, CAMPAIGNS, GLOBAL_STATS, MEMBERS } from '../types'

export const AdminPayoutsReducer = (state = [], action) =>{
  const {type, payload} = action

  switch (type) {
    case ADMIN_PAYOUTS: {
        const { data } = payload
        state = []
        return data
    }
    default: {
      return state
    }
  }
}
