export const initialState = {
  session: [],
  loadings: [],
  error: {},
  errors: [],
  message: {},
  modal: {},
  tab: '',
  purchaseType:'personal',
  statsType:'clients',
  campaigns:[],
  campaign:{},
  campaignDetails:{},
  payout:{},
  admin_members:[],
  admin_member:{},
  global_statistics:[],
  user_statistics:{},
  admin_payouts:[],
  admin_payout:{},
  binom: {},
}