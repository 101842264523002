import { TIME_PICKER } from "../types";

export const timePickerReducer = (state = [], action) => {
  const { type, payload } = action;

  switch (type) {
    case TIME_PICKER: {
        const { data } = payload
        state = [];
        return data
    }
    default: {
        return state;
      }
    }
  }