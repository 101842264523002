import { CAMPAIGNS, GLOBAL_STATS, MEMBERS } from '../types'

export const AdminGlobalStatisticsReducer = (state = [], action) =>{
  const {type, payload} = action

  switch (type) {
    case GLOBAL_STATS: {
        const { data } = payload
        state = []
        return data
    }
    default: {
      return state
    }
  }
}
