import React from "react";
import Header from "./Header";
import { Container, Typography, Button, Grow, Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import bg1 from "../../assets/images/background/bg1.jpg";
import bg2 from "../../assets/images/background/bg2.png";
import bg3 from "../../assets/images/background/bg3.png";

import cube from "../../assets/images/icons/cube.png";

import x from "../../assets/images/logo/large.png";

import logo from "../../assets/images/logo/full.png";
import { Link } from "react-router-dom";

export default function Landing() {
  const { t } = useTranslation();

  const ref1 = React.useRef(null);
  const ref2 = React.useRef(null);
  const ref3 = React.useRef(null);

  const isInViewport1 = useIsInViewport(ref1);
  const isInViewport2 = useIsInViewport(ref2);
  const isInViewport3 = useIsInViewport(ref3);

  const [appeared1, setAppeared1] = React.useState(false);
  const [appeared2, setAppeared2] = React.useState(false);
  const [appeared3, setAppeared3] = React.useState(false);

  React.useEffect(() => {
    if (isInViewport1) {
      setAppeared1(true);
    }
    if (isInViewport2) {
      setAppeared2(true);
    }
    if (isInViewport3) {
      setAppeared3(true);
    }
  }, [isInViewport1, isInViewport2, isInViewport3]);

  const handleScrollTo = React.useCallback((pos) => {
    // if (pos === 'top') {
    //     window.scrollTo({ behavior: 'smooth', top: 0 });
    //     return;
    // }
    // const elem = document.getElementById(pos);
    // if (elem) {
    //     elem.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
    // }
    // return;
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://money.warehouse-x.io" />
        <title>
          The Best Adult Webcam Affiliate Program – Earn with X Money
        </title>
        <meta
          name="description"
          content={`X Money is one of the best adult webcam affiliate programs and lets you make money through Warehouse X affiliate marketing.`}
        />
        <meta
          property="og:title"
          content="The Best Adult Webcam Affiliate Program – Earn with X Money"
        />
        <meta
          property="og:description"
          content="X Money is one of the best adult webcam affiliate programs and lets you make money through Warehouse X affiliate marketing."
        />
        <meta property="og:url" content="https://money.warehouse-x.io" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://warehouse-x.io/static/media/warehouse-x-logo.05ae9f6cb7027f71bb24.png"
        />
      </Helmet>
      <div style={{ maxWidth: "100%" }}>
        <Box
          id="head"
          className="background"
          sx={{
            backgroundPosition: {
              xs: "60% 70%",
              sm: "50% 70%",
              md: "30% 70%",
              lg: "0 70%",
            },
            backgroundSize: "cover",
            backgroundImage: "url(" + bg1 + ")",
            boxShadow: {
              xs: "inset 0 150px 350px rgb(0 0 0 / 100%)",
              sm: "inset 0 180px 190px rgb(0 0 0 / 100%)",
              md: "inset 0 130px 150px rgb(0 0 0 / 80%)",
              lg: "inset 0 130px 150px rgb(0 0 0 / 80%)",
            },
          }}
        >
          <Container maxWidth="xl" className="Containter_mobile">
            <Header />
            <Box
              ref={ref3}
              className="container flex column content-center"
              sx={{
                minHeight: "90vh",
                position: "relative",
                pt: { xs: "0", sm: "0", md: "180px", lg: "180px" },
              }}
            >
              <Grow in={isInViewport3 || appeared1} timeout={800} delay={500}>
                <Box
                  className="fx-col-box"
                  sx={{
                    maxWidth: "795px",
                    marginBottom: {
                      xs: "none",
                      sm: "none",
                      md: "220px",
                      lg: "220px",
                    },
                    padding: {
                      xs: "100px 0 60px",
                      sm: "40px 0",
                      md: "unset",
                      lg: "unset",
                    },
                  }}
                >
                  <Typography
                    variant="h1"
                    className="typography flex primary font BrushKing h xxxlg"
                    sx={{
                      position: "relative",
                      flexDirection: {
                        xs: "column",
                        sm: "column",
                        md: "unset",
                        lg: "unset",
                      },
                      gap: { xs: "50px", sm: "60px", md: "unset", lg: "unset" },
                      width: "fit-content",
                    }}
                  >
                    <span className="f1" style={{ marginRight: "30px" }}>
                      {t("EARN")}{" "}
                    </span>
                    <span className="f2" style={{ marginRight: "20px" }}>
                      {t("WITH")}
                    </span>
                    <Box
                      sx={{
                        position: {
                          xs: "absolute",
                          sm: "absolute",
                          md: "absolute",
                          lg: "absolute",
                        },
                        right: {
                          xs: "-185px",
                          sm: "-250px",
                          md: "-280px",
                          lg: "-280px",
                        },
                        bottom: {
                          xs: "0px",
                          sm: "-50px",
                          md: "-90px",
                          lg: "-90px",
                        },
                        width: {
                          xs: "180px",
                          sm: "229px",
                          md: "259px",
                          lg: "259px",
                        },
                      }}
                    >
                      <Avatar
                        sx={{
                          width: {
                            xs: "100px",
                            sm: "100%",
                            md: "100%",
                            lg: "100%",
                          },
                          height: "100%",
                        }}
                        src={x}
                        alt={t("logo")}
                      />
                    </Box>
                  </Typography>
                  <Typography
                    variant="h2"
                    className="typography color third"
                    sx={{
                      maxWidth: "650px",
                      mt: 8,
                      marginBottom: {
                        xs: "100px",
                        sm: "60px",
                        md: "unset",
                        lg: "unset",
                      },
                      fontSize: {
                        xs: "30px !important",
                        sm: "36px !important",
                        md: "38px !important",
                        lg: "40px !important",
                      },
                      padding: {
                        xs: "25px",
                        sm: "20px",
                        md: "0",
                        lg: "0",
                      },
                      borderRadius: "15px",
                      boxShadow: {
                        xs: "inset 80px 80px 80px 80px rgb(0 0 0 / 100%)",
                        sm: "inset 70px 70px 70px 70px rgb(0 0 0 / 80%)",
                        md: "0",
                        lg: "0",
                      },
                    }}
                  >
                    {t(
                      "Welcome to X Money, the new affiliate program of the world famous adult reality webcam show, Warehouse X!"
                    )}
                  </Typography>
                  <Box
                    className="flex"
                    sx={{
                      gap: "30px",
                      position: {
                        xs: "unset",
                        sm: "unset",
                        md: "relative",
                        lg: "relative",
                      },
                      top: "50px",
                      alignItems: "center",
                      flexDirection: {
                        xs: "column",
                        sm: "unset",
                        md: "unset",
                        lg: "unset",
                      },
                    }}
                  >
                    <Link to="/register">
                      <Button
                        className="btn btn-primary typography"
                        sx={{
                          fontSize: {
                            xs: "18px !important",
                            sm: "18px !important",
                            md: "20px !important",
                            lg: "22px !important",
                          },
                          width: "fit-content",
                          padding: {
                            xs: "10px 20px !important",
                            sm: "10px 22px !important",
                            md: "10px 24px !important",
                            lg: "12px 25px !important",
                          },
                        }}
                      >
                        {t("GET YOUR ACCOUNT!")}
                      </Button>
                    </Link>
                    <Button
                      onClick={() =>
                        ref1.current.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                          inline: "center",
                        })
                      }
                      className="btn btn-secondary yellow typography"
                      sx={{
                        fontSize: {
                          xs: "18px !important",
                          sm: "18px !important",
                          md: "20px !important",
                          lg: "22px !important",
                        },
                        width: "fit-content",
                        padding: {
                          xs: "10px 20px !important",
                          sm: "10px 22px !important",
                          md: "10px 24px !important",
                          lg: "12px 25px !important",
                        },
                      }}
                    >
                      {t("MORE INFO")}
                    </Button>
                  </Box>
                </Box>
              </Grow>
            </Box>
          </Container>
        </Box>
        <Box
          id="about"
          className="background third"
          sx={{
            backgroundImage: "url(" + bg2 + ")",
            backgroundPosition: "40%",
            position: "relative",
            overflow: "hidden",
            maxHeight: "656px",
          }}
        >
          <Container maxWidth="xl">
            <Box
              ref={ref1}
              className="container flex column content-center"
              sx={{
                minHeight: window.innerWidth < 900 ? "600px" : "656px",
                maxHeight: window.innerWidth < 900 ? "600px" : "656px",
              }}
            >
              <Box
                className={appeared1 ? "appear" : ""}
                sx={{ maxWidth: "1054px", position: "relative" }}
              >
                <Box
                  className="flex column content-center"
                  sx={{
                    position: "relative",
                    zIndex: "2",
                    maxWidth: "750px",
                    gap: "15px",
                    alignItems: {
                      xs: "center",
                      sm: "unset",
                      md: "unset",
                      lg: "unset",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      marginBottom: {
                        xs: "35px",
                        sm: "25px",
                        md: "10px",
                        lg: "10px",
                      },
                    }}
                    variant="h2"
                    className="typography heavy color secondary xxlg"
                  >
                    {t("The deal is simple...")}
                  </Typography>
                  <Typography
                    variant="body1"
                    className="typography medium "
                    sx={{
                      fontSize: {
                        xs: "34px !important",
                        sm: "38px !important",
                        md: "42px !important",
                        lg: "45px !important",
                      },
                      marginBottom: {
                        xs: "55px",
                        sm: "35px",
                        md: "20px",
                        lg: "20px",
                      },
                      marginTop: "5px",
                      color: "black",
                    }}
                  >
                    {t(
                      "You promote our project, we pay you 20% of everything your referred customer spends, for life!"
                    )}
                  </Typography>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://my.matterport.com/show/?m=iRFPZQVt93K&play=1&hl=1&tourcta=1&ts=0"
                  >
                    <Button
                      className="btn btn-secondary black"
                      sx={{ width: "fit-content" }}
                    >
                      <img
                        src={cube}
                        alt={t("walkthrough")}
                        style={{ marginRight: "10px", width: "24px" }}
                      />{" "}
                      {t("3D WALKTHROUGH")}
                    </Button>
                  </a>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box
          id="price"
          className="background"
          sx={{
            backgroundImage: "url(" + bg3 + ")",
            backgroundPosition: { xs: "35%", sm: "45%", md: "45%", lg: "30%" },
            position: "relative",
            overflow: "hidden",
            maxHeight: "656px",
          }}
        >
          <Container maxWidth="xl">
            <Box
              ref={ref2}
              className="container flex column content-center"
              sx={{ minHeight: "656px" }}
            >
              <Box
                className={appeared2 ? "appear" : ""}
                sx={{ maxWidth: "1054px", position: "relative" }}
              >
                <Box
                  className="flex column"
                  sx={{
                    position: "relative",
                    zIndex: "2",
                    maxWidth: "750px",
                    gap: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      marginBottom: {
                        xs: "25px",
                        sm: "15px",
                        md: "10px",
                        lg: "10px",
                      },
                    }}
                    variant="h2"
                    className="typography heavy color secondary xxlg"
                  >
                    {t("Yes, X does care")}
                  </Typography>

                  <Typography
                    variant="body1"
                    className="typography medium color third"
                    sx={{
                      fontSize: {
                        xs: "35px",
                        sm: "38px",
                        md: "42px",
                        lg: "45px",
                      },
                      marginBottom: "20px",
                      marginTop: "5px",
                      maxWidth: "485px",
                    }}
                  >
                    {t("You own 20% of all revenue you send our way!")}
                  </Typography>

                  <Box
                    className="flex column"
                    sx={{ gap: "15px", mt: "10px", mb: "25px" }}
                  >
                    <Typography
                      variant="body1"
                      className="typography medium color primary xlg"
                    >
                      {t("Weekly payouts:")}
                    </Typography>
                    <Typography
                      variant="body1"
                      className="typography medium color third xlg"
                    >
                      {t("Wire, Paypal, BTC, USDT")}
                    </Typography>
                  </Box>

                  <Box
                    className="flex"
                    sx={{
                      gap: "25px",
                      justifyContent: {
                        xs: "center",
                        sm: "unset",
                        md: "unset",
                        lg: "unset",
                      },
                    }}
                  >
                    <Link to="/register">
                      <Button
                        className="btn btn-primary typography"
                        sx={{
                          fontSize: {
                            xs: "18px !important",
                            sm: "18px !important",
                            md: "20px !important",
                            lg: "22px !important",
                          },
                          width: "fit-content",
                          padding: {
                            xs: "10px 20px !important",
                            sm: "10px 22px !important",
                            md: "10px 24px !important",
                            lg: "10px 28px !important",
                          },
                        }}
                      >
                        {t("SIGN UP NOW")}
                      </Button>
                    </Link>
                    <Link to="/faq">
                      <Button
                        className="btn btn-secondary yellow typography"
                        sx={{
                          fontSize: {
                            xs: "18px !important",
                            sm: "18px !important",
                            md: "20px !important",
                            lg: "22px !important",
                          },
                          width: "fit-content",
                          padding: {
                            xs: "10px 20px !important",
                            sm: "10px 22px !important",
                            md: "10px 24px !important",
                            lg: "10px 28px !important",
                          },
                        }}
                      >
                        {t("FAQ")}
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box
          id="footer"
          className="background"
          sx={{ borderTop: "1px solid #282828" }}
        >
          <Container maxWidth="lg">
            <Box
              className="container flex items-center"
              sx={{
                minHeight: "350px",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "unset",
                  lg: "unset",
                },
                justifyContent: {
                  xs: "space-evenly",
                  sm: "space-evenly",
                  md: "unset",
                  lg: "unset",
                },
              }}
            >
              <Box
                className="flex items-center text-center"
                sx={{
                  gap: "70px",
                  padding: { xs: "15px", sm: "10px", md: "0", lg: "0" },
                }}
              >
                <Link to="/faq">
                  <Typography
                    variant="body1"
                    className="typography color white bold"
                  >
                    {t("FAQ")}
                  </Typography>
                </Link>
                <Link to="/" style={{ display: "none" }}>
                  <Typography
                    variant="body1"
                    className="typography color white bold"
                  >
                    {t("TERMS OF SERVICE")}
                  </Typography>
                </Link>
                <Link to="/" style={{ display: "none" }}>
                  <Typography
                    variant="body1"
                    className="typography color white bold"
                  >
                    {t("SUPPORT")}
                  </Typography>
                </Link>
              </Box>

              <Box sx={{ flexGrow: { xs: 0, sm: 0, md: 1, lg: 1 } }} />

              <Box sx={{ flexGrow: 0 }}>
                <Link to="/" onClick={handleScrollTo("top")}>
                  <img src={logo} alt={t("Logo")} style={{ width: "178px" }} />
                </Link>
              </Box>
            </Box>
          </Container>
        </Box>
      </div>
    </>
  );
}

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = React.useState(false);

  const observer = React.useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  );

  React.useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
