//frontend
const API = "/api/v1/";
export const API_UPDATE_BINOM = API + "binom/update";
export const HOME_ROUTE = "/";
export const WHX_BACKEND_API_URL = "https://whxlbo-fr.warehouse-x.io";
export const WHX_BACKEND_API_TEST_URL = "https://whxlbo-fr.warehouse-x.io";
/* export const FRONTEND_URL = process.env.REACT_APP_APP_URL;
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL; */
export const LOGIN_ROUTE = "/login";
export const RESET_ROUTE = "/password/reset";
export const REGISTRATION_ROUTE = "/register";
export const PROFILE_ROUTE = "/account";
//https://whxlb-fr.warehouse-x.io
// core api
export const CORE_API_LOGIN_ROUTE = "/user/auth/login";
export const CORE_API_ENVIRONMENT_ROUTE = API + "affiliate/environment";

//api
export const API_RESTORE_LINK_ROUTE = API + "forgot-password-link";
export const API_RESTORE_ROUTE = API + "forgot-password";
export const API_REGISTRATION_ROUTE = API + "affiliate/auth/register";
export const API_LOGIN_ROUTE = API + "affiliate/auth/login";
export const API_LOGOUT_ROUTE = API + "affiliate/auth/logout";
export const API_USER_CHANGE_PASSWORD_ROUTE = API + "users/change-password";
export const API_USER_FINDME_ROUTE = API + "findMe";

export const API_FETCH_ENVIRONMENT_ROUTE = API + "environment";

export const API_FETCH_USER = API + "user/";
export const API_USER_UPDATE = API + "affiliate/edit-profile";

export const API_FETCH_POSTS = API + "posts";
export const API_POST = API + "post";
export const API_FETCH_POST = API + "post/";

export const API_FETCH_COMMENT = API + "comment/";
export const API_COMMENT_UPDATE = API + "comment/";
export const API_COMMENT_DELETE = API + "comment/";

export const API_FETCH_TAGS = API + "tags";
export const API_TAG_DELETE = API + "tag/";
export const API_FETCH_TAG_POSTS = API + "tag/";

export const API_FETCH_CAMPAIGNS_ROUTE = API + "affiliate/campaigns";
export const API_FETCH_CAMPAIGN_ROUTE = API + "affiliate/campaign";

export const API_SUPPORT = API + "affiliate/support";
export const API_PAYOUT = API + "affiliate/edit-payout-data";
export const API_PAYOUT_HISTORY = API + "affiliate/payouts";

export const timeoutTime = 700;

// admin api routes

export const API_LOGIN_ADMIN_ROUTE = API + "affiliate/admin/auth/login";
export const API_LOGOUT_ADMIN_ROUTE = API + "affiliate/admin/auth/logout";

export const API_MEMBERS_ROUTE = API + "affiliate/admin/members";
export const API_MEMBER_ROUTE = API + "affiliate/admin/member-profile/";

export const API_GLOBAL_STATS_ROUTE = API + "affiliate/admin/global-statistics";
export const API_USER_STATS_ROUTE = API + "affiliate/admin/user-campaigns";

export const API_ADMIN_PAYOUTS_ROUTE = API + "affiliate/admin/payouts";
export const API_ADMIN_PAYOUT_ROUTE = API + "affiliate/admin/payout";

export const API_ADMIN_TRANSACTION_ROUTE = API + "affiliate/admin/transaction";

export const API_ADMIN_LOGIN_AFF_ROUTE = API + "affiliate/admin/login-as/";
