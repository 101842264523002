import { PURCHASE_TYPE, PURCHASE_TYPE_REMOVE, STATS_TYPE, STATS_TYPE_REMOVE } from '../types'

export const statsTypeReducer = (state = 'clients', action) => {
  const { type, payload } = action

  switch (type) {
    case STATS_TYPE: {
      let { data } = payload
      return data
    }
    case STATS_TYPE_REMOVE: {
      return {}
    }
    default: {
      return state
    }
  }
}
