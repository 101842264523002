import Logout from "../components/Logout/Logout"
import Main from "../pages/Main/Main"
import { Navigate } from 'react-router-dom';
import Campaigns from "../pages/Campaigns/Campaigns";
import Support from "../pages/Support/Support";
import Stats from "../pages/Stats/Stats";
import Payout from "../pages/Payout/Payout";
import Promo_tools from "../pages/Promo_tools/Promo_tools";

export const pages = [
    {
        path: '/settings',
        component: <Main />
    },
    {
        path: '/logout',
        component: <Logout />
    },
    {
        path: '*',
        component: <Navigate to="/settings"/>
    },
    {
        path:'/campaigns',
        component: <Campaigns/>
    },
    {
        path:'/support',
        component: <Support/>
    },
    {
        path:'/stats',
        component: <Stats/>
    },
    {
        path:'/stats/:id',
        component: <Stats/>
    },
    {
        path:'/payout',
        component: <Payout/>
    },
    {
        path:'/promo',
        component: <Promo_tools/>
    }
];