import React, { useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik, useField } from "formik";
import { Button, Grid } from "@mui/material";
import * as Yup from "yup";
import Logo from "../../assets/Logo.svg";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  addNewUser,
  loginUser,
  removeBinom,
} from "../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { useSnackbar } from "notistack";
import { removeErrors, showModal } from "../../redux/rootReducer";
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [captcha, setCaptcha] = React.useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const binom = useSelector((state) => state.binom);
  const dispatch = useDispatch();

  const LoginSchemaRegister = Yup.object().shape({
    email: Yup.string()
      .email("Provide a valid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password should be  minimum 8 characters ")
      .required("Password is required"),
    password_confirmation: Yup.string()
      .min(8, "password_confirmation should be  minimum 8 characters ")
      .required("password_confirmation is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Provide a valid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password should be  minimum 8 characters ")
      .required("Password is required"),
  });
  const [logReg, setLogRes] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
      username: "",
      password: "",
      password_confirmation: "",
    },

    validationSchema: logReg && LoginSchemaRegister,

    onSubmit: () => {
      console.log("submitting...");
      console.log(formik.values);
      if (!logReg) {
        dispatch(loginUser(formik.values));
      } else {
        let item;
        if (
          binom &&
          binom !== {} &&
          Object.getOwnPropertyNames(binom).length !== 0
        ) {
          console.log(item, binom);
          item = binom;
          dispatch(removeBinom());
        }
        dispatch(addNewUser(formik.values, item));
      }

      console.log("submited!!");
    },
  });

  const { errors, touched, values, isSubmitting, getFieldProps } = formik;
  function loginFormChange(event) {
    event.preventDefault();
    setLogRes(!logReg);
  }

  function onChange(value) {
    console.log("Captcha successfuly completed!");
    /*   dispatch(removeErrors()); */
    setCaptcha(value);
  }
  const { enqueueSnackbar } = useSnackbar();
  const errorsSubmit = useSelector((state) => state.errors);
  React.useEffect(() => {
    if (errorsSubmit && errorsSubmit.email) {
      enqueueSnackbar(errorsSubmit.email[0], { variant: "error" });
    }
    if (errorsSubmit && errorsSubmit.username) {
      enqueueSnackbar(errorsSubmit.username[0], { variant: "error" });
    }
    if (errorsSubmit && errorsSubmit.password) {
      enqueueSnackbar(errorsSubmit.password[0], { variant: "error" });
    }
    if (errorsSubmit && errorsSubmit.password_confirmation) {
      enqueueSnackbar(errorsSubmit.password_confirmation[0], {
        variant: "error",
      });
    }

    setTimeout(() => {
      dispatch(removeErrors());
    }, 5000);
  }, [errorsSubmit]);

  const handleForgotPassword = () => {
    dispatch(showModal("restore"));
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Box
            animate={{
              transition: {
                staggerChildren: 0.55,
              },
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {!logReg ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "16px",
                    width: "80%",
                  }}
                  initial={{ opacity: 0, y: 40 }}
                  animate={animate}
                  className="LoginBox"
                >
                  <Typography
                    style={{
                      color: "white",
                      font: "normal normal 800 20px/24px Montserrat",
                    }}
                  >
                    Log in
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Username"
                    style={{
                      borderRadius: "5px",
                    }}
                    type="username"
                    {...getFieldProps("username")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleOutlinedIcon
                            style={{ color: "black" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    fullWidth
                    style={{
                      borderRadius: "5px",
                    }}
                    autoComplete="current-password"
                    type={"password"}
                    placeholder="Password"
                    {...getFieldProps("password")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockOutlinedIcon style={{ color: "black" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Box
                  initial={{ opacity: 0, y: 20 }}
                  animate={animate}
                  sx={{
                    width: "80%",
                    pt: 3,
                    display: "flex",
                    align: "center",
                  }}
                >
                  <Link
                    variant="body2"
                    onClick={handleForgotPassword}
                    sx={{
                      textDecoration: "underline !important",
                      fontSize: "0.9rem",
                      lineHeight: "inherit",
                      color: "#fff",
                    }}
                  >
                    Forgot password?
                  </Link>
                </Box>

                <Box
                  initial={{ opacity: 0, y: 20 }}
                  animate={animate}
                  style={{ width: "80%" }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ my: 2, color: "black" }}
                  ></Stack>

                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    /*   loading={isSubmitting} */
                    style={{
                      backgroundColor: "#77F73B",
                      font: "normal normal 800 18px/22px Effra",
                      height: "50px",
                      borderRadius: "5px",
                    }}
                  >
                    {"Login"}
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "16px",
                    width: "80%",
                  }}
                  className="LoginBox"
                  initial={{ opacity: 0, y: 40 }}
                  animate={animate}
                >
                  <Typography
                    style={{
                      color: "white",
                      font: "normal normal 800 1.5rem Effra",
                      marginTop: "20px",
                      display: !logReg && "none",
                    }}
                  >
                    Become an affiliate
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Email"
                    style={{
                      borderRadius: "5px",
                    }}
                    type="email"
                    {...getFieldProps("email")}
                    error={
                      touched.email && Boolean(touched.email && errors.email)
                    }
                    helperText={touched.email && touched.email && errors.email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleOutlinedIcon
                            style={{ color: "black" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    fullWidth
                    placeholder="Username"
                    style={{
                      borderRadius: "5px",
                    }}
                    type="username"
                    {...getFieldProps("username")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleOutlinedIcon
                            style={{ color: "black" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    style={{
                      borderRadius: "5px",
                    }}
                    autoComplete="current-password"
                    type={"password"}
                    placeholder="Password"
                    {...getFieldProps("password")}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockOutlinedIcon style={{ color: "black" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    style={{
                      borderRadius: "5px",
                    }}
                    autoComplete="current-password"
                    type={"password"}
                    placeholder="Repeat password"
                    {...getFieldProps("password_confirmation")}
                    error={Boolean(
                      touched.password_confirmation &&
                        errors.password_confirmation
                    )}
                    helperText={
                      touched.password_confirmation &&
                      errors.password_confirmation
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockOutlinedIcon style={{ color: "black" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                {/* <Grid item xs={12} style={{ marginTop: "30px" }}>
                  <ReCAPTCHA
                    sitekey="6LfZHl8hAAAAABjQC3jPhxOYQDhzYawDjLJUDsOC"
                    onChange={onChange}
                    theme="dark"
                    label="I'm not a robot"
                  />
                </Grid> */}
                <Box
                  initial={{ opacity: 0, y: 20 }}
                  animate={animate}
                  style={{ width: "80%" }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ my: 2, color: "black" }}
                  ></Stack>

                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: "#77F73B",
                      font: "normal normal 800 18px/22px Effra",
                      height: "50px",
                      borderRadius: "5px",
                    }}
                  >
                    {"Sign up"}
                  </Button>
                </Box>
              </>
            )}

            {!logReg ? (
              <>
                <Box
                  initial={{ opacity: 0, y: 20 }}
                  animate={animate}
                  style={{ width: "80%", marginTop: "10px" }}
                >
                  <Button
                    onClick={loginFormChange}
                    fullWidth
                    size="large"
                    variant="outlined"
                    /*   loading={isSubmitting} */
                    style={{
                      font: "normal normal 800 18px/22px Effra",
                      height: "50px",
                      border: "2px solid white",
                      color: "white",
                      borderRadius: "5px",
                    }}
                  >
                    {"Sign up"}
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Box
                  initial={{ opacity: 0, y: 20 }}
                  animate={animate}
                  style={{
                    width: "80%",
                    margin: "10px 0px",
                    paddingBottom: "20px",
                  }}
                >
                  <Button
                    onClick={loginFormChange}
                    fullWidth
                    size="large"
                    variant="outlined"
                    /*   loading={isSubmitting} */
                    style={{
                      font: "normal normal 800 18px/22px Effra",
                      height: "50px",
                      border: "2px solid white",
                      color: "white",
                      borderRadius: "5px",
                    }}
                  >
                    {"LOG IN"}
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
};

export default LoginForm;
