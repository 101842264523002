import {
  Button,
  ButtonGroup,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Pagination,
  Select,
  Snackbar,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { CheckBox } from "@mui/icons-material";
import { setPurchaseType, setStatsType } from "../../redux/rootReducer";
import { connect, useDispatch, useSelector } from "react-redux";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DateRangePickerComp from "../../components/DateRangePickerComp/DateRangePickerComp";
import {
  CreateCampaign,
  DeleteCampaign,
  GetCampaign,
} from "../../redux/actions/CampaignsActions";
import ContentPasteGoOutlinedIcon from "@mui/icons-material/ContentPasteGoOutlined";
import { Payouts } from "../../redux/actions/userAction";
const mapStateToProps = (state) => {
  return {
    stats_type: state.statsType,
    payout: state.payout,
  };
};
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};
function PayoutHistory({ stats_type, payout }) {
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = React.useState("pending");
  React.useEffect(() => {
    dispatch(Payouts());
  }, []);
  React.useEffect(() => {
    if (stats_type == "pending") {
      setSelectedType("pending");
    } else {
      setSelectedType("completed");
    }
  }, [stats_type]);
  React.useEffect(() => {
    dispatch(setStatsType(selectedType));
  }, [selectedType]);

  console.log(payout);
  const [completedRows, setcompletedRows] = useState(payout && payout.paid);
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#292929",
      height: "40px",
      padding: "0px 16px",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#1F1F1F",
      height: "40px",
      padding: "0px 16px",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    root: {
      height: 30,
    },
    /*         "&.MuiTableRow-root:hover": {
                    backgroundColor: "#555555",
                }, */
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#292929",
      color: theme.palette.common.white,
      padding: "0px 16px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const yearsMonths = [
    { id: 0, name: "January" },
    { id: 1, name: "February" },
    { id: 2, name: "March" },
    { id: 3, name: "April" },
    { id: 4, name: "May" },
    { id: 5, name: "June" },
    { id: 6, name: "July" },
    { id: 7, name: "August" },
    { id: 8, name: "September" },
    { id: 9, name: "October" },
    { id: 10, name: "November" },
    { id: 11, name: "December" },
  ];
  function getTime(time) {
    var date = new Date(time * 1000);
    const month_number = date.getMonth();
    const month = yearsMonths[month_number].name;
    // 👉️ 0
    // 👇️ returns day of the month (1-31)
    const day_month = date.getDate();
    const hours = date.getHours(); // get hours with getHours method
    const minutes = date.getMinutes(); // get minutes with getMinutes method // get seconds with getSeconds method
    const timeString = `${day_month} ${month} ${pad(hours)}:${pad(minutes)}`;
    return timeString; // finally, join to a time string
  }

  function pad(num) {
    return ("0" + num).slice(-2);
  }
  return (
    <>
      <Container maxWidth="xl">
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            className="container"
            container
            spacing={4}
            style={{ marginTop: "40px" }}
          >
            <Grid
              className="stats_header"
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid className="stats_choise">
                <Button
                  onClick={() => {
                    setSelectedType("pending");
                  }}
                  style={{
                    color: selectedType == "pending" ? "black" : "white",
                    marginRight: "20px",
                    border: "2px solid #ffffff",
                    height: "28px",
                    borderRadius: "5px",
                    alignItems: "stretch",
                    padding: "0px 8px",
                    backgroundColor: selectedType == "pending" && "white",
                  }}
                >
                  {" "}
                  Pending Payouts
                </Button>
                <Button
                  onClick={() => {
                    setSelectedType("completed");
                  }}
                  style={{
                    color: selectedType == "completed" ? "black" : "white",
                    border: "2px solid #ffffff",
                    height: "28px",
                    borderRadius: "5px",
                    alignItems: "stretch",
                    padding: "0px 8px",
                    backgroundColor: selectedType == "completed" && "white",
                  }}
                >
                  {" "}
                  Completed Payouts
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: "46px",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  color: "white",
                  font: "normal normal bold 1.5rem Effra",
                }}
              >
                {selectedType == "pending"
                  ? "Pending Payouts"
                  : "Completed Payouts"}
              </Typography>
            </Grid>
            <Grid item style={{ paddingTop: "0px",width:'100%',justifyContent:'center',display:'flex' }}>
              <TableContainer
                className="SchrollBar_Geo"
                component={Paper}
                style={{
                  overflow: "auto",
                  marginTop: "10px",
                  borderRadius: "5px",
                  boxShadow: "unset",
                  width:window.innerWidth<900? "100%": "40%",
                }}
                sx={{ maxHeight: "500px" }}
              >
                <Table aria-label="simple table" stickyHeader>
                  <TableHead style={{ height: "40px" }}>
                    <StyledTableRow>
                      <StyledTableCell
                        style={{
                          borderBottom: "2px solid #5C5C5C",
                          color: "#77F73B",
                        }}
                        align="left"
                      >
                        AMOUNT
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          borderBottom: "2px solid #5C5C5C",
                          color: "#77F73B",
                        }}
                        align="right"
                      >
                        DATE REQUESTED
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {selectedType == "pending"
                      ? payout &&
                        payout.requested &&
                        payout.requested.map((row) => (
                          <StyledTableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            style={{ height: "40px" }}
                          >
                            <TableCell
                              align="left"
                              style={{
                                borderBottom: "none",
                                color: "white",
                                padding: "0px 16px",
                                fontSize: "1.1rem",
                              }}
                            >
                              {row.amount}$
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                borderBottom: "none",
                                color: "white",
                                padding: "0px 16px",
                                fontSize: "1.1rem",
                              }}
                            >
                              {row.createdAt && getTime(row.createdAt)}
                            </TableCell>
                          </StyledTableRow>
                        ))
                      : payout &&
                        payout.completed &&
                        payout.completed.map((row) => (
                          <StyledTableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            style={{ height: "40px" }}
                          >
                            <TableCell
                              align="left"
                              style={{
                                borderBottom: "none",
                                color: "white",
                                padding: "0px 16px",
                                fontSize: "1.1rem",
                              }}
                            >
                              {row.amount}$
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                borderBottom: "none",
                                color: "white",
                                padding: "0px 16px",
                                fontSize: "1.1rem",
                              }}
                            >
                              {row.createdAt && getTime(row.createdAt)}
                            </TableCell>
                          </StyledTableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* <Pagination
                            style={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}
                            component="div"
                            count={count}
                            page={page}
                            siblingCount={1}
                            boundaryCount={0}
                            shape="rounded"
                            onChange={handleChangePage} /> */}

              {/*       <TablePagination
                            style={{ background: '#292929', color: 'white' }}
                            rowsPerPageOptions={[6, 12, 18]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage} /> */}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default connect(mapStateToProps)(PayoutHistory);
