// api-client.js

import axios from 'axios';
import { setErrors, setMessage } from '../redux/rootReducer';
import { store } from '../redux/store';
import { useDispatch } from 'react-redux';
import Logout from '../components/Logout/Logout';
import { useNavigate } from 'react-router-dom';

// Request middleware
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    if(sessionStorage.getItem("access_token") === null){
        let root = localStorage.getItem('persist:root');
        root = JSON.parse(root);
        let session = JSON.parse(root.session);
        if(!session)
          return config
        const access_token = session.access_token;
        if(!access_token)
          return config
        sessionStorage.setItem("access_token", access_token);
    }

    return config;
    
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });


// Response middleware
axios.interceptors.response.use(function (response) {
    // Do something with response data

    if(response.message && response.message !== null && response.message !== '')
      store.dispatch(setMessage(response.message))

    return response;

  }, function (error) {
    // Do something with response error
    if(error.response.status === 401){
      store.dispatch(setMessage('You are not authenticated.', 'error'))
      console.log(window.location.pathname)
      setTimeout(()=>{
        localStorage.removeItem('persist:root')
        localStorage.removeItem("access_token");
        localStorage.removeItem("campaign");
        window.localStorage.clear();
        window.localStorage.clear()
        localStorage.clear();
        localStorage.clear();
        window.sessionStorage.clear();
        window.localStorage.clear();
        window.location.href='/register'
      },2000)
    } else if (error.response.status == 404){
      
    } else {

      (error.response.data.message && error.response.data.message !== null && error.response.data.message !== '' ?
        store.dispatch(setMessage(error.response.data.message, 'error')) :
        (error.response.data.errors ?
          (error.response.data.errors[0] ?
            store.dispatch(setErrors(error.response.data.errors[0]))
            :
            store.dispatch(setErrors(error.response.data.errors))
          )
          :
          store.dispatch(setMessage(error.response.data.error, 'error'))
        )
      )

    }
    return Promise.reject(error);
  });

export default axios;