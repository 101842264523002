import { CAMPAIGN,  } from '../types'

export const CampaignReducer = (state = {}, action) =>{
  const {type, payload} = action

  switch (type) {
    case CAMPAIGN: {
        const { data } = payload
        return data
    }
    default: {
      return state
    }
  }
}
