import { useNavigate } from "react-router-dom";
import {
  API_LOGIN_ADMIN_ROUTE,
  API_LOGIN_ROUTE,
  API_LOGOUT_ADMIN_ROUTE,
  API_LOGOUT_ROUTE,
  API_PAYOUT,
  API_PAYOUT_HISTORY,
  API_REGISTRATION_ROUTE,
  API_SUPPORT,
  API_UPDATE_BINOM,
  API_USER_CHANGE_PASSWORD_ROUTE,
  API_USER_FINDME_ROUTE,
  API_USER_UPDATE,
  CORE_API_ENVIRONMENT_ROUTE,
} from "../../utils/consts";
import {
  hideModal,
  removeErrors,
  setError,
  setErrors,
  setMessage,
} from "../rootReducer";
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  BINOM,
  BINOM_REMOVE,
  PAYOUT,
  USER,
} from "../types";
import { headers } from "../config";
import axios from "../../utils/axios";

const userLogin = (data) => {
  return {
    type: AUTH_LOGIN,
    payload: { data },
  };
};

const userLogout = (data) => {
  return {
    type: AUTH_LOGOUT,
    payload: { data },
  };
};

const oneUser = (data) => {
  return {
    type: USER,
    payload: { data },
  };
};
const Payout = (data) => {
  return {
    type: PAYOUT,
    payload: { data },
  };
};

export const setBinom = (data) => {
  return {
    type: BINOM,
    payload: { data },
  };
};
export const removeBinom = (data) => {
  return {
    type: BINOM_REMOVE,
    payload: { data },
  };
};

export const setNewPasswordAction = (data) => async (dispatch) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_USER_CHANGE_PASSWORD_ROUTE,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
        body: JSON.stringify(data),
        credentials: "include",
      },
    );

    if (response.status === 200) {
      const data = await response.json();
      dispatch(setMessage(data.message, data.type));
      dispatch(hideModal("password"));
    } else {
      const data = await response.json();
      dispatch(setErrors(data.errors));
    }
  } catch (error) {
    dispatch(setErrors(error));
    return false;
  }
};

export const getUser = () => async (dispatch) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_USER_FINDME_ROUTE,
      {
        method: "GET",
        headers: headers,
      },
    );

    const data = await response.json();
    dispatch(oneUser(data));
  } catch (error) {
    dispatch(setError(error));
  }
};

export const loginUser = (user) => async (dispatch) => {
  try {
    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        username: user.username,
        password: user.password,
      }),
    };

    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_LOGIN_ROUTE,
      options,
    );

    const data = await response.json();

    if (data.access_token) {
      dispatch(userLogin(data));
      window.localStorage.setItem("access_token", data.access_token);
      useNavigate("/");
      window.location.href = "/";
    } else {
      console.log("here");
      console.log(data);
      dispatch(setMessage(data.message, "error"));
    }
  } catch (error) {
    setMessage(error, "error");
  }
};
export const loginAdmin = (user) => async (dispatch) => {
  try {
    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        email: user.username,
        password: user.password,
      }),
    };

    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_LOGIN_ADMIN_ROUTE,
      options,
    );
    const data = await response.json();

    if (data.access_token) {
      dispatch(userLogin(data));
      window.localStorage.setItem("access_token", data.access_token);
      useNavigate("/");
      window.location.href = "/";
    } else {
      console.log("here");
      console.log(data);
      dispatch(setMessage(data.message, "error"));
    }
  } catch (error) {
    setMessage(error, "error");
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ` + localStorage.getItem("access_token"),
      },
    };

    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_LOGOUT_ROUTE,
      options,
    );
    console.log("vlear");
    const data = await response.json();
    dispatch(userLogout());
    setTimeout(() => {
      localStorage.removeItem("persist:root");
      localStorage.removeItem("access_token");
      localStorage.removeItem("campaign");
      window.localStorage.clear();
      window.localStorage.clear();
      localStorage.clear();
      localStorage.clear();
      window.sessionStorage.clear();
      window.localStorage.clear();
    }, 15);
  } catch (error) {
    setMessage(error, "error");
  }
};

export const logoutAdmin = () => async (dispatch) => {
  try {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ` + localStorage.getItem("access_token"),
      },
    };

    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_LOGOUT_ADMIN_ROUTE,
      options,
    );
    console.log("vlear");
    const data = await response.json();
    dispatch(userLogout());
    setTimeout(() => {
      localStorage.removeItem("persist:root");
      localStorage.removeItem("access_token");
      localStorage.removeItem("campaign");
      window.localStorage.clear();
      window.localStorage.clear();
      localStorage.clear();
      localStorage.clear();
      window.sessionStorage.clear();
      window.localStorage.clear();
    }, 15);
  } catch (error) {
    setMessage(error, "error");
  }
};

export const addNewUser = (item, binom) => async (dispatch) => {
  try {
    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        email: item.email,
        username: item.username,
        password: item.password,
        password_confirmation: item.password_confirmation,
        binom: binom,
      }),
    };

    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_REGISTRATION_ROUTE,
      options,
    );
    const data = await response.json();
    console.log(data);
    if (data.access_token) {
      dispatch(removeErrors());
      dispatch(userLogin(data));
      dispatch(setMessage(data.message, "success"));
      window.localStorage.setItem("access_token", data.access_token);
      useNavigate("/");
      window.location.href = "/";
    } else {
      const errors = {
        email: data.errors.email,
        username: data.errors.username,
        password: data.errors.password,
        password_confirmation: data.errors.password_confirmation,
      };
      dispatch(setErrors(errors));
    }
  } catch (error) {
    setMessage(error, "error");
  }
};

export const UpdateUser = (item) => async (dispatch) => {
  try {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ` + localStorage.getItem("access_token"),
      },
      body: JSON.stringify(item),
    };

    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + API_USER_UPDATE,
      JSON.stringify(item),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      },
    );

    if (response.status === 200) {
      dispatch(removeErrors());
      dispatch(userLogin(response.data.data));
      dispatch(setMessage(response.data.message, response.data.type));
    }
  } catch (error) {
    setMessage(error, "error");
  }
};

export const SupportRequest = (item) => async (dispatch) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + API_SUPPORT,
      JSON.stringify(item),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      },
    );

    if (response.data) {
      dispatch(removeErrors());
      dispatch(setMessage(response.data.message, response.data.type));
    }
  } catch (error) {
    setMessage(error, "error");
  }
};

export const RequiredPayout = (item) => async (dispatch) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + API_PAYOUT,
      JSON.stringify(item),
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      },
    );

    if (response.data) {
      dispatch(removeErrors());
      dispatch(userLogin(response.data.data));
      dispatch(setMessage(response.data.message, response.data.type));
    }
  } catch (error) {
    setMessage(error, "error");
  }
};

export const Payouts = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_URL + API_PAYOUT_HISTORY,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ` + localStorage.getItem("access_token"),
        },
      },
    );

    if (response.data) {
      console.log(response.data);
      dispatch(removeErrors());
      dispatch(Payout(response.data.data.payouts));
    }
  } catch (error) {
    setMessage(error, "error");
  }
};

export const getEnvironment = (access_token) => async (dispatch) => {
  try {
    const options = {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ` + access_token,
      },
    };

    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + CORE_API_ENVIRONMENT_ROUTE,
      options,
    );
    const data = await response.json();

    if (data.access_token) {
      dispatch(userLogin(data));
      localStorage.clear();
      localStorage.setItem("access_token", data.access_token);
      useNavigate("/");
      window.location.href = "/";
    } else {
      console.log("here");
      console.log(data);
      dispatch(setMessage(data.message, "error"));
    }
  } catch (error) {
    setMessage(error, "error");
  }
};

export const UpdateBinom = (item) => async (dispatch) => {
  console.log(item);
  try {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ` + localStorage.getItem("access_token"),
      },
      credentials: "include",
      body: JSON.stringify(item),
    };

    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + API_UPDATE_BINOM,
      options,
    );
  } catch (error) {
    setMessage(error, "error");
  }
};
