import React from "react";
import ReactDOM from "react-dom/client";
import {Provider} from "react-redux";
import {Persistor, store} from "./redux/store";
import {BrowserRouter} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
import {createTheme, ThemeProvider} from "@mui/material";
import Router from "./router/Router";
import "./assets/styles/App.scss";
import {SnackbarProvider} from "notistack";
import "./translate";

const root = ReactDOM.createRoot(document.getElementById("root"));

const darkTheme = createTheme({
    palette: {
        mode: "dark",
        // primary: {
        //   main: "#77f73b",
        // },
        // secondary: {
        //   main: "#ed2779",
        // },
        // third: {
        //   main: "#FFD800",
        //   contrastText: "#000",
        // },
        // white: {
        //   main: "#FFF",
        //   light: "#FFF",
        //   dark: "#a1a1a1",
        //   contrastText: "#000",
        // },
        // grey: {
        //   main: "#292929",
        //   light: "#292929",
        //   dark: "#292929",
        //   contrastText: "#fff",
        // },
        // black: {
        //   main: "#000",
        //   light: "#000",
        //   dark: "#000",
        //   contrastText: "#FFF",
        // },
    },
    typography: {
        fontFamily: `"Montseratt", "Helvetica", "Arial", sans-serif`,
    },
});

const lightTheme = createTheme({
    palette: {
        mode: "light",
        // primary: {
        //   main: "#77f73b",
        // },
        // secondary: {
        //   main: "#ed2779",
        // },
        // third: {
        //   main: "#FFD800",
        //   contrastText: "#000",
        // },
        // white: {
        //   main: "#FFF",
        //   light: "#FFF",
        //   dark: "#a1a1a1",
        //   contrastText: "#000",
        // },
        // grey: {
        //   main: "#292929",
        //   light: "#292929",
        //   dark: "#292929",
        //   contrastText: "#fff",
        // },
        // black: {
        //   main: "#000",
        //   light: "#000",
        //   dark: "#000",
        //   contrastText: "#FFF",
        // },
    },
    typography: {
        fontFamily: `"Montseratt", "Helvetica", "Arial", sans-serif`,
    },
});

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={Persistor}>
            <BrowserRouter>
                <ThemeProvider theme={darkTheme}>
                    <SnackbarProvider maxSnack={7}>
                        <Router/>
                    </SnackbarProvider>
                </ThemeProvider>
            </BrowserRouter>
        </PersistGate>
    </Provider>
);
