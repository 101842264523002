import {
  Button,
  ButtonGroup,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { connect, useDispatch, useSelector } from "react-redux";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { DataGrid } from "@mui/x-data-grid";
import {
  AdminPayouts,
  AdminPayoutsApprove,
  AdminPayoutsComment,
  getAdminPayout,
  GetGlodalStats,
  GetUserStats,
} from "../../redux/actions/AdminMembersActions";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from 'react-router-dom';
const mapStateToProps = (state) => {
  return {
    purchase_type: state.purchaseType,
    session: state.session,
    global_statistics: state.global_statistics,
    admin_payouts: state.admin_payouts,
  };
};
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};
function Payouts({ admin_payouts, global_statistics }) {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const dispatch = useDispatch();

  const [tab, settab] = useState("pending");

  const handleChangeTab = (event, newValue) => {
    settab(newValue);
  };
  React.useEffect(() => {
    dispatch(AdminPayouts());
  }, []);
  console.log(admin_payouts);

  const yearsMonths = [
    { id: 0, name: "January" },
    { id: 1, name: "February" },
    { id: 2, name: "March" },
    { id: 3, name: "April" },
    { id: 4, name: "May" },
    { id: 5, name: "June" },
    { id: 6, name: "July" },
    { id: 7, name: "August" },
    { id: 8, name: "September" },
    { id: 9, name: "October" },
    { id: 10, name: "November" },
    { id: 11, name: "December" },
  ];
  function getTime(time) {
    var date = new Date(time * 1000);
    const month_number = date.getMonth();
    const month = yearsMonths[month_number].name;
    // 👉️ 0
    // 👇️ returns day of the month (1-31)
    const day_month = date.getDate();
    const hours = date.getHours(); // get hours with getHours method
    const minutes = date.getMinutes(); // get minutes with getMinutes method // get seconds with getSeconds method
    const timeString = `${day_month} ${month} ${pad(hours)}:${pad(minutes)}`;
    return timeString; // finally, join to a time string
  }

  function pad(num) {
    return ("0" + num).slice(-2);
  }
  const handleAccept = (id, method) => {
    dispatch(AdminPayoutsApprove(id, method));
  };
  const handleAddComment = (id) => {
    setMessage("");
    dispatch(AdminPayoutsComment(id, message));
    
  };
  const [message, setMessage] = useState("");

  const handleMessageChange = (event) => {
    // 👇️ update textarea value
    setMessage(event.target.value);
  };
  const columns = [
    {
      field: "createdAt",
      headerName: "Created",
      width: "200",
      valueFormatter: (params) => getTime(params?.value),
    },
    { field: "amount", headerName: "Amount", width: "200" },
    { field: "whx_earnings", headerName: "Whx Earnings", width: "200" },
    {
      field: "username",
      headerName: "Affiliate",
      wrapText: true,
      width: "200",
      valueGetter: (params) => {
        return params.row.user.username;
      },
    },
    {
      field: "preferred_messenger",
      headerName: "Messenger",
      width: "200",
      valueGetter: (params) => {
        return params.row.user.preferred_messenger;
      },
    },
    {
      headerName: "Approve",
      width: "100",
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <ButtonGroup style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{
                color: "white",
                width: "10px",
                padding: "0px",
                border: "unset",
                borderRadius: "5px",
              }}
              type="button"
              onClick={() => handleAccept(params.row.id, 1)}
            >
              <CheckBoxIcon style={{ color: "#77f73b" }} />
            </Button>
            <Button
              style={{
                color: "white",
                width: "10px",
                padding: "0px",
                border: "unset",
                borderRadius: "5px",
              }}
              type="button"
              onClick={() => handleAccept(params.row.id, 0)}
            >
              <CancelIcon style={{ color: "#d11313" }} />
            </Button>
          </ButtonGroup>
        );
      },
    },
    {
      field: "Comments",
      headerName: "Comments",
      width: "300",
      disableClickEventBubbling: true,
      wrapText: true,
      renderCell: (params) => {
        return (
          <div style={{display:'flex'}}>
            <Grid style={{overflow:'auto',height:params?.row?.notes ? '52px':'unset'}}>
              {params?.row?.notes?.map((item)=>(
              <Typography color={'white'} >{item.note}</Typography>
              ))}
              <TextField
                fullWidth
                placeholder="Subject"
                name="subject"
                variant="standard"
                style={{
                  backgroundColor: "#191919",
                  borderRadius: "5px",
                  overflow: "auto",
                }}
                type="email"
                onChange={handleMessageChange}
                /*    {...getFieldProps("email")}  */
              />
            </Grid>
            <Button
              style={{
                color: "white",
                width: "10px",
                padding: "0px",
                border: "unset",
                borderRadius: "5px",
                minWidth: "40px",
                marginLeft: "10px",
              }}
              type="button"
              onClick={() => handleAddComment(params.row.id)}
            >
              <CheckBoxIcon style={{ color: "#77f73b" }} />
            </Button>
            </div>
        );
      },
    },
    { field: "status", headerName: "Status", width: "200" },
  ];
  const Completed_columns = [
    {
      field: "createdAt",
      headerName: "Created",
      width: "250",
      valueFormatter: (params) => getTime(params?.value),
    },
    { field: "amount", headerName: "Amount", width: "250" },
    { field: "whx_earnings", headerName: "Whx Earnings", width: "250" },
    {
      field: "username",
      headerName: "Affiliate",
      width: "250",
      valueGetter: (params) => {
        return params.row.user.username;
      },
    },
    {
      field: "preferred_messenger",
      headerName: "Messenger",
      width: "250",
      valueGetter: (params) => {
        return params.row.user.preferred_messenger;
      },
    },
    { field: "status", headerName: "Status", width: "200" },
  ];
  const [data_rows, setdata_rows] = useState([]);
  React.useEffect(() => {
    setdata_rows(
      admin_payouts &&
        admin_payouts.payouts &&
        admin_payouts.payouts.requested &&
        admin_payouts.payouts.requested
    );
  }, [admin_payouts, admin_payouts?.payouts?.requested]);

  const [completed_rows, setcompleted_rows] = useState([]);
  React.useEffect(() => {
    setcompleted_rows(
      admin_payouts &&
        admin_payouts.payouts &&
        admin_payouts.payouts.completed &&
        admin_payouts.payouts.completed
    );
  }, [admin_payouts, admin_payouts?.payouts?.completed]);

  const [pageSize, setPageSize] = React.useState(10);
  const loading = useSelector((state) => state.loading);
  const [finalClickInfo, setFinalClickInfo] = useState(null);

  const navigate = useNavigate()
  const handleOnCellClick = (params) => {
    console.log(params)
    if(params.field ==='createdAt'){
      navigate('/admin/payout/'+params.row.id)
    }
    setFinalClickInfo(params);
  };

  return (
    <>
      <Container maxWidth="xl">
        <TabContext value={tab}>
          <Box
            maxWidth="xl"
            sx={{
              margin: "15px auto",
            }}
          >
            <TabList onChange={handleChangeTab} aria-label="Pages">
              <Tab
                label={"Pending Payouts"}
                className="ProfileTab"
                value="pending"
              />
              <Tab
                label={"Completed Payouts"}
                className="ProfileTab"
                value="completed"
              />
            </TabList>
          </Box>
          <TabPanel style={{ padding: 0 }} value="pending">
            <div
              style={{ height: "600px", width: "100%" }}
              className="react-grid-multiline-content"
            >
              {data_rows && (
                <DataGrid
                  rows={data_rows && data_rows}
                  columns={columns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[10, 25, 50]}
                  //   checkboxSelection
                  loading={loading}
                  disableSelectionOnClick
                  onCellClick={handleOnCellClick}
                />
                
              )}
                {finalClickInfo &&
        `Final clicked id = ${finalClickInfo.id}, 
        Final clicked field = ${finalClickInfo.field}, 
        Final clicked value = ${finalClickInfo.value}`}
            </div>
          </TabPanel>
          <TabPanel style={{ padding: 0 }} value="completed">
            <div style={{ height: "600px", width: "100%" }}>
              {completed_rows && (
                <DataGrid
                  rows={completed_rows && completed_rows}
                  columns={Completed_columns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[10, 25, 50]}
                  //   checkboxSelection
                  loading={loading}
                  disableSelectionOnClick
                  onCellClick={handleOnCellClick}
                />
              )}
            </div>
          </TabPanel>
        </TabContext>
      </Container>
    </>
  );
}

export default connect(mapStateToProps)(Payouts);
