import { CAMPAIGNS, FETCH_MEMBER, MEMBER, MEMBERS } from '../types'

export const AdminMemberReducer = (state = [], action) =>{
  const {type, payload} = action

  switch (type) {
    case MEMBER: {
        const { data } = payload
        state = []
        return data
    }
    case FETCH_MEMBER: {
      const { data } = payload
      console.log(data)
    let newState2 = Object.assign({}, state);
      newState2.user = data.user;
      return newState2;
  }
    default: {
      return state
    }
  }
}
