import {API_FETCH_CAMPAIGN_ROUTE, API_FETCH_CAMPAIGNS_ROUTE,} from "../../utils/consts";
import {CAMPAIGN, CAMPAIGN_DETAILS, CAMPAIGN_UPDATE, CAMPAIGNS,} from "../types";
import {setMessage} from "../rootReducer";
import axios from "../../utils/axios";

const allCampaigns = (data) => {
    return {
        type: CAMPAIGNS,
        payload: {data},
    };
};
const Campaign = (data) => {
    return {
        type: CAMPAIGN,
        payload: {data},
    };
};
const CampaignDetails = (data) => {
    return {
        type: CAMPAIGN_DETAILS,
        payload: {data},
    };
};

const patchCampaign = (data) => {
    return {
        type: CAMPAIGN_UPDATE,
        payload: {data},
    };
};

export const GetCampaigns = () => async (dispatch) => {
    try {

        const response = await axios.get(process.env.REACT_APP_BACKEND_URL + API_FETCH_CAMPAIGNS_ROUTE, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ` + localStorage.getItem("access_token")
            }
        })
        //credentials: 'include',

        if (response.status >= 200 && response.status <= 299) {
            console.log("here")
            console.log(response.data.data)
            dispatch(allCampaigns(response.data.data));
        }
    } catch (error) {
        console.log(error);
    }
};

export const CreateCampaign = (item) => async (dispatch) => {
    console.log(item);
    try {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ` + localStorage.getItem("access_token"),
            },
            body: JSON.stringify(item),
            //credentials: 'include',
        };
        const response = await fetch(
            process.env.REACT_APP_BACKEND_URL + API_FETCH_CAMPAIGN_ROUTE,
            options
        );
        //response.data
        const data = await response.json();

        if (data.type == "success") {
            dispatch(Campaign(data.data));
            dispatch(GetCampaigns());
            dispatch(setMessage(data.message, data.type));
        } else {
            dispatch(setMessage(data.message, data.type));
        }
    } catch (error) {
        console.log(error);
    }
};
export const DeleteCampaign = (id) => async (dispatch) => {
    try {
        const options = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ` + localStorage.getItem("access_token"),
            },
            //credentials: 'include',
        };
        const response = await fetch(
            process.env.REACT_APP_BACKEND_URL + API_FETCH_CAMPAIGN_ROUTE + "/" + id,
            options
        );
        //response.data
        const data = await response.json();

        if (data.type == "success") {
            dispatch(Campaign({}));
            dispatch(GetCampaigns());
            dispatch(setMessage(data.message, data.type));
        }
    } catch (error) {
        console.log(error);
    }
};
export const GetCampaign =
    (id, start = null, end = null, period = null) =>

        async (dispatch) => {
            console.log(id, start, end, period)
            try {
                let response;

                if (id == null) {
                    response = await axios.get(
                        process.env.REACT_APP_BACKEND_URL + API_FETCH_CAMPAIGN_ROUTE,
                        {
                            withCredentials: "true",
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                Authorization: `Bearer ` + localStorage.getItem("access_token"),
                            },
                        }
                    );
                }
                //   if (id) {
                //     response = await axios.get(
                //       process.env.REACT_APP_BACKEND_URL + API_FETCH_CAMPAIGN_ROUTE + "/" + id,
                //       {
                //         withCredentials: "true",
                //         headers: {
                //           "Content-Type": "application/json",
                //           Accept: "application/json",
                //           Authorization: `Bearer ` + localStorage.getItem("access_token"),
                //         },
                //       }
                //     );
                //   }
                //   if (period && id) {
                //     response = await axios.get(
                //       process.env.REACT_APP_BACKEND_URL +
                //         API_FETCH_CAMPAIGN_ROUTE +
                //         "/" +
                //         id +
                //         `?period_type=${period.toLowerCase()}`,
                //       {
                //         withCredentials: "true",
                //         headers: {
                //           "Content-Type": "application/json",
                //           Accept: "application/json",
                //           Authorization: `Bearer ` + localStorage.getItem("access_token"),
                //         },
                //       }
                //     );
                //   }
                if (start != null && end != null && period != null && id != null) {
                    response = await axios.get(
                        process.env.REACT_APP_BACKEND_URL +
                        API_FETCH_CAMPAIGN_ROUTE +
                        "/" +
                        id +
                        `?start_date=${start}&end_date=${end}&period_type=${period.toLowerCase()}`,
                        {
                            withCredentials: "true",
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                Authorization: `Bearer ` + localStorage.getItem("access_token"),
                            },
                        }
                    );
                }


                //response.data
                console.log(response)
                if (response?.data) {
                    console.log("here");
                    dispatch(CampaignDetails(response.data.data));
                    /*         dispatch(setMessage(data.message,data.type)); */
                }
            } catch (error) {
                console.log(error);
            }
        };
// export const ExtendPool = (item,id) => async (dispatch) => {
//     console.log(item)
//     try {
//         const options = {
//             method: "POST",
//             headers: {
//                 'Content-Type': 'application/json',
//                 "Accept": "application/json",
//                 'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
//             },
//             body: JSON.stringify(item)
//             //credentials: 'include',
//         }
//         const response = await fetch(process.env.REACT_APP_BACKEND_URL + API_FETCH_POOLS_ROUTE+'/'+id+'/edit', options)
//         //response.data
//         const data = await response.json()

//         if (data.type == 'success') {
//             dispatch(GetPools())
//             dispatch(setMessage(data.message,data.type));
//         }
//     } catch (error) {
//         console.log(error)
//     }
// }
// export const AccomplishPool = (id) => async (dispatch) => {
//     try {
//         const options = {
//             method: "POST",
//             headers: {
//                 'Content-Type': 'application/json',
//                 "Accept": "application/json",
//                 'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
//             },
//             //credentials: 'include',
//         }
//         const response = await fetch(process.env.REACT_APP_BACKEND_URL + API_FETCH_POOLS_ROUTE+'/'+id+'/edit/accomplish', options)
//         //response.data
//         const data = await response.json()

//         if (data.type == 'success') {
//             dispatch(GetPools())
//             dispatch(setMessage(data.message,data.type));
//         }
//     } catch (error) {
//         console.log(error)
//     }
// }
// export const TerminatePool = (id) => async (dispatch) => {
//     try {
//         const options = {
//             method: "DELETE",
//             headers: {
//                 'Content-Type': 'application/json',
//                 "Accept": "application/json",
//                 'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
//             },
//             //credentials: 'include',
//         }
//         const response = await fetch(process.env.REACT_APP_BACKEND_URL + API_FETCH_POOLS_ROUTE+'/'+id+'/terminate', options)
//         //response.data
//         const data = await response.json()

//         if (data.type == 'success') {
//             dispatch(GetPools())
//             dispatch(setMessage(data.message,data.type));
//         }
//     } catch (error) {
//         console.log(error)
//     }
// }


export const EditCampaign = (item, id) => async (dispatch) => {
    console.log(item);
    try {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ` + localStorage.getItem("access_token"),
            },
            body: JSON.stringify(item),
            //credentials: 'include',
        };
        const response = await fetch(
            process.env.REACT_APP_BACKEND_URL + API_FETCH_CAMPAIGN_ROUTE + '/' + id,
            options
        );
        //response.data
        const data = await response.json();

        if (data.type == "success") {
            dispatch(Campaign(data.data));
            dispatch(GetCampaigns());
            dispatch(setMessage(data.message, data.type));
        } else {
            dispatch(setMessage(data.message, data.type));
        }
    } catch (error) {
        console.log(error);
    }
};