import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {GetMemberProfile, UpdateMemberProfile} from "../../redux/actions/AdminMembersActions";
import {useParams} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {Field, Form, FormikProvider, useFormik} from "formik";
import {Grid, Typography} from "@mui/material";
import * as Yup from "yup";

const mapStateToProps = (state) => {
    return {
        admin_member: state.admin_member,
    };
};

function MemberSettings({admin_member}) {
    const dispatch = useDispatch()
    let param = useParams()
    React.useEffect(() => {
        dispatch(GetMemberProfile(param.id))
    }, [])

    console.log(admin_member)
    const LoginSchemaRegister = Yup.object().shape({
        customPercent: Yup.number()
            .typeError("Invalid input")
            .min(0.01, "Custom Percent should be at least 0.01")
            .max(100, "Custom Percent should not exceed 100")
        ,
        customPercentReferral: Yup.number()
            .typeError("Invalid input")
            .min(0.01, "Custom Percent Referral should be at least 0.01")
            .max(100, "Custom Percent Referral should not exceed 100")
        ,
        customRewardCamAccess: Yup.number()
            .typeError("Invalid input")
            .min(0.01, "Custom Reward Cam Access should be at least 0.01")
            .max(100, "Custom Reward Cam Access should not exceed 100")
        ,
        customRewardCredits: Yup.number()
            .typeError("Invalid input")
            .min(0.01, "Custom Reward Credits should be at least 0.01")
            .max(100, "Custom Reward Credits should not exceed 100")
        ,
        customPpsCountryTierReward1: Yup.number()
            .typeError("Invalid input")
            .min(0.01, "Custom Pps Country Tier Reward 1 should be at least 0.01")
            .max(100, "Custom Pps Country Tier Reward 1 should not exceed 100")
        ,
        customPpsCountryTierReward2: Yup.number()
            .typeError("Invalid input")
            .min(0.01, "Custom Pps Country Tier Reward 2 should be at least 0.01")
            .max(100, "Custom Pps Country Tier Reward 2 should not exceed 100")
        ,
        customPpsCountryTierReward3: Yup.number()
            .typeError("Invalid input")
            .min(0.01, "Custom Pps Country Tier Reward 3 should be at least 0.01")
            .max(100, "Custom Pps Country Tier Reward 3 should not exceed 100")
        ,
        customPpsCountryTierReward4: Yup.number()
            .typeError("Invalid input")
            .min(0.01, "Custom Pps Country Tier Reward 4 should be at least 0.01")
            .max(100, "Custom Pps Country Tier Reward 4 should not exceed 100")

    });
    const formik = useFormik({
        initialValues: {
            customPercent: admin_member?.user?.affiliatePartner?.customPercent || '',
            customPercentReferral: admin_member?.user?.affiliatePartner?.customPercentReferral || '',
            customRewardCamAccess: admin_member?.user?.affiliatePartner?.customRewardCamAccess || '',
            customRewardCredits: admin_member?.user?.affiliatePartner?.customRewardCredits || '',
            customPpsCountryTierReward1: admin_member?.user?.affiliatePartner?.customPpsCountryTierReward1 || '',
            customPpsCountryTierReward2: admin_member?.user?.affiliatePartner?.customPpsCountryTierReward2 || '',
            customPpsCountryTierReward3: admin_member?.user?.affiliatePartner?.customPpsCountryTierReward3 || '',
            customPpsCountryTierReward4: admin_member?.user?.affiliatePartner?.customPpsCountryTierReward4 || '',
        },

        validationSchema: LoginSchemaRegister,

        onSubmit: () => {
            dispatch(UpdateMemberProfile(param.id, formik.values));

            console.log("submited!!");
        },
    });
    React.useEffect(() => {
        formik.setValues({
            customPercent: admin_member?.user?.affiliatePartner?.customPercent || "",
            customPercentReferral:
                admin_member?.user?.affiliatePartner?.customPercentReferral || "",
            customRewardCamAccess:
                admin_member?.user?.affiliatePartner?.customRewardCamAccess || "",
            customRewardCredits:
                admin_member?.user?.affiliatePartner?.customRewardCredits || "",
            customPpsCountryTierReward1:
                admin_member?.user?.affiliatePartner?.customPpsCountryTierReward1 || "",
            customPpsCountryTierReward2:
                admin_member?.user?.affiliatePartner?.customPpsCountryTierReward2 || "",
            customPpsCountryTierReward3:
                admin_member?.user?.affiliatePartner?.customPpsCountryTierReward3 || "",
            customPpsCountryTierReward4:
                admin_member?.user?.affiliatePartner?.customPpsCountryTierReward4 || "",
        });
    }, [admin_member]);
    const {errors, touched, values, isSubmitting, getFieldProps} = formik;

    const onSubmit = (values) => {
        console.log(values); // Handle form submission here
    };


    return (
        <FormikProvider value={formik}>
            <Form className="LoginBox">
                <Grid item xs={4} className="data"
                      style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Typography
                        style={{
                            color: "white",
                            marginTop: "15px",
                            font: "normal normal bold 1.5rem Effra",
                        }}
                    >
                        {admin_member?.user?.username}{" "}
                        {"Profile"}
                    </Typography>
                    <Grid className="DataBox"
                          style={{width: window.innerWidth < 900 ? "100%" : '30%', gap: '15px'}}>
                        {/*<Field*/}
                        {/*    as={TextField}*/}
                        {/*    type="text"*/}
                        {/*    name="customPercent"*/}
                        {/*    variant="outlined"*/}
                        {/*    */}
                        {/*/>*/}
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Field
                                style={{width: "90%", borderRadius: '5px'}}
                                sx={{
                                    "& .MuiInputLabel-root": {
                                        // Your styles for the specific class MuiInputLabel-root
                                        color: 'black'
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: '#77F73B',
                                        top: "-5px"
                                    }
                                }}
                                as={TextField}
                                type="text"
                                name="customPercent"
                                variant="outlined"
                                placeholder="customPercent"
                                label="customPercent"
                                {...getFieldProps("customPercent")}

                                error={touched.customPercent && Boolean(touched.customPercent && errors.customPercent)}
                                helperText={touched.customPercent && touched.customPercent && errors.customPercent}
                            />

                        </div>
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Field
                                style={{width: "90%", borderRadius: '5px'}}
                                sx={{
                                    "& .MuiInputLabel-root": {
                                        // Your styles for the specific class MuiInputLabel-root
                                        color: 'black'
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: '#77F73B',
                                        top: "-5px"
                                    }
                                }}
                                as={TextField}
                                type="text"
                                name="customPercentReferral"
                                variant="outlined"
                                placeholder="customPercentReferral"
                                label="customPercentReferral"
                                {...getFieldProps("customPercentReferral")}
                                error={touched.customPercentReferral && Boolean(touched.customPercentReferral && errors.customPercentReferral)}
                                helperText={touched.customPercentReferral && touched.customPercentReferral && errors.customPercentReferral}
                            />
                        </div>
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Field
                                style={{width: "90%", borderRadius: '5px'}}
                                sx={{
                                    "& .MuiInputLabel-root": {
                                        // Your styles for the specific class MuiInputLabel-root
                                        color: 'black'
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: '#77F73B',
                                        top: "-5px"
                                    }
                                }}
                                as={TextField}
                                type="text"
                                name="customRewardCamAccess"
                                variant="outlined"
                                placeholder="customRewardCamAccess"
                                label="customRewardCamAccess"
                                {...getFieldProps("customRewardCamAccess")}
                                error={touched.customRewardCamAccess && Boolean(touched.customRewardCamAccess && errors.customRewardCamAccess)}
                                helperText={touched.customRewardCamAccess && touched.customRewardCamAccess && errors.customRewardCamAccess}
                            />

                        </div>
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Field
                                style={{width: "90%", borderRadius: '5px'}}
                                sx={{
                                    "& .MuiInputLabel-root": {
                                        // Your styles for the specific class MuiInputLabel-root
                                        color: 'black'
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: '#77F73B',
                                        top: "-5px"
                                    }
                                }}
                                as={TextField}
                                type="text"
                                name="customRewardCredits"
                                variant="outlined"
                                placeholder="customRewardCredits"
                                label="Custom Reward Credits"
                                {...getFieldProps("customRewardCredits")}
                                error={touched.customRewardCredits && Boolean(touched.customRewardCredits && errors.customRewardCredits)}
                                helperText={touched.customRewardCredits && touched.customRewardCredits && errors.customRewardCredits}
                            />

                        </div>
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Field
                                style={{width: "90%", borderRadius: '5px'}}
                                sx={{
                                    "& .MuiInputLabel-root": {
                                        // Your styles for the specific class MuiInputLabel-root
                                        color: 'black'
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: '#77F73B',
                                        top: "-5px"
                                    }
                                }}
                                as={TextField}
                                type="text"
                                name="customPpsCountryTierReward1"
                                variant="outlined"
                                placeholder="customPpsCountryTierReward1"
                                label="customPpsCountryTierReward1"
                                {...getFieldProps("customPpsCountryTierReward1")}
                                error={touched.customPpsCountryTierReward1 && Boolean(touched.customPpsCountryTierReward1 && errors.customPpsCountryTierReward1)}
                                helperText={touched.customPpsCountryTierReward1 && touched.customPpsCountryTierReward1 && errors.customPpsCountryTierReward1}
                            />

                        </div>
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Field
                                style={{width: "90%", borderRadius: '5px'}}
                                sx={{
                                    "& .MuiInputLabel-root": {
                                        // Your styles for the specific class MuiInputLabel-root
                                        color: 'black'
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: '#77F73B',
                                        top: "-5px"
                                    }
                                }}
                                as={TextField}
                                type="text"
                                name="customPpsCountryTierReward2"
                                variant="outlined"
                                placeholder="customPpsCountryTierReward2"
                                label="customPpsCountryTierReward2"
                                {...getFieldProps("customPpsCountryTierReward2")}
                                error={touched.customPpsCountryTierReward2 && Boolean(touched.customPpsCountryTierReward2 && errors.customPpsCountryTierReward2)}
                                helperText={touched.customPpsCountryTierReward2 && touched.customPpsCountryTierReward2 && errors.customPpsCountryTierReward2}
                            />

                        </div>
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Field
                                style={{width: "90%", borderRadius: '5px'}}
                                sx={{
                                    "& .MuiInputLabel-root": {
                                        // Your styles for the specific class MuiInputLabel-root
                                        color: 'black'
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: '#77F73B',
                                        top: "-5px"
                                    }
                                }}
                                as={TextField}
                                type="text"
                                name="customPpsCountryTierReward3"
                                variant="outlined"
                                placeholder="customPpsCountryTierReward3"
                                label="customPpsCountryTierReward3"
                                {...getFieldProps("customPpsCountryTierReward3")}
                                error={touched.customPpsCountryTierReward3 && Boolean(touched.customPpsCountryTierReward3 && errors.customPpsCountryTierReward3)}
                                helperText={touched.customPpsCountryTierReward3 && touched.customPpsCountryTierReward3 && errors.customPpsCountryTierReward3}
                            />

                        </div>
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Field
                                style={{
                                    width: "90%", borderRadius: '5px'
                                }}
                                sx={{
                                    "& .MuiInputLabel-root": {
                                        // Your styles for the specific class MuiInputLabel-root
                                        color: 'black'
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: '#77F73B',
                                        top: "-5px"
                                    }
                                }}
                                as={TextField}
                                type="text"
                                name="customPpsCountryTierReward4"
                                variant="outlined"
                                placeholder="customPpsCountryTierReward4"
                                label="customPpsCountryTierReward4"
                                {...getFieldProps("customPpsCountryTierReward4")}
                                error={touched.customPpsCountryTierReward4 && Boolean(touched.customPpsCountryTierReward4 && errors.customPpsCountryTierReward4)}
                                helperText={touched.customPpsCountryTierReward4 && touched.customPpsCountryTierReward4 && errors.customPpsCountryTierReward4}
                            />

                        </div>

                        <Button style={{marginTop: '30px', borderRadius: "10px", backgroundColor: "#77F73B"}}
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}>Submit</Button>
                    </Grid>

                </Grid>
                {/* Repeat similar code for other fields */}

            </Form>
        </FormikProvider>
    );
}

export default connect(mapStateToProps)(MemberSettings);