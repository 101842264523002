import {Button, Container, Grid, IconButton, MenuItem, Select, SvgIcon, TextField, Typography,} from "@mui/material";
import React, {useState} from "react";
import Box from "@mui/material/Box";
import {connect, useDispatch, useSelector} from "react-redux";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import {changeAdminAffiliateStatus, GetMember, loginAsAffiliate,} from "../../redux/actions/AdminMembersActions";
import {useNavigate, useParams} from "react-router-dom";
import {DataGrid} from "@mui/x-data-grid";
import PropTypes from "prop-types";
import ClearIcon from "@mui/icons-material/Clear";
import DateRangePickerCompAffMembers from '../../components/DateRangePickerComp/DateRangePickerCompAffMembers';


const mapStateToProps = (state) => {
    return {
        admin_member: state.admin_member,
    };
};
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
    opacity: 1,
    y: 0,
    transition: {
        duration: 0.6,
        ease: easing,
        delay: 0.16,
    },
};

function QuickSearchToolbar(props) {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
            }}
        >
            <TextField
                fullWidth
                value={props.value}
                onChange={props.onChange}
                placeholder="Search User"
                InputProps={{
                    //   startAdornment: <SearchIcon fontSize="small" />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{visibility: props.value ? "visible" : "hidden"}}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small"/>
                        </IconButton>
                    ),
                }}
            />
        </Box>
    );
}

QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

function Members({admin_member}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedType, setSelectedType] = React.useState("pending");
    const param = useParams();
    React.useEffect(() => {
        dispatch(GetMember(param.id));
    }, []);

    const yearsMonths = [
        {id: 0, name: "January"},
        {id: 1, name: "February"},
        {id: 2, name: "March"},
        {id: 3, name: "April"},
        {id: 4, name: "May"},
        {id: 5, name: "June"},
        {id: 6, name: "July"},
        {id: 7, name: "August"},
        {id: 8, name: "September"},
        {id: 9, name: "October"},
        {id: 10, name: "November"},
        {id: 11, name: "December"},
    ];

    const time = useSelector((state) => state.time_picker);

    function pad(num) {
        return ("0" + num).slice(-2);
    }

    let first = time && time[0] && new Date(time[0].startDate);
    let last = time && time[0] && new Date(time[0].endDate);
    first =
        time[0] &&
        first.getFullYear() +
        "-" +
        pad(first.getMonth() + 1) +
        "-" +
        pad(first.getDate());
    last =
        time[0] &&
        last.getFullYear() +
        "-" +
        pad(last.getMonth() + 1) +
        "-" +
        pad(last.getDate());

    const columns = [
        {field: "id", headerName: "User id", width: "200"},
        {field: "username", headerName: "Username", width: "200"},
        {field: "email", headerName: "Email", width: "200"},
        {
            field: "registration_fee",
            headerName: "Cost",
            type: "number",
            width: "100",
            renderCell: (params) => {
                return <Typography>$ {params?.row?.registration_fee}</Typography>;
            },
        },
        {
            field: "purchases",
            headerName: "Purchases",
            type: "number",
            width: "200",
            renderCell: (params) => {
                return <Typography>$ {params?.row?.purchases}</Typography>;
            },
        },
        {field: "createdAt", headerName: "Date Created", width: "200"},
        {field: "country_name", headerName: "Country", width: "200"},
        {field: "registration_IP", headerName: "Registration IP", width: "200"},
    ];
    const [pageSize, setPageSize] = React.useState(
        admin_member?.pagination?.limit
            ? Number(admin_member?.pagination?.limit)
            : 20
    );
    const [page, setPage] = React.useState(
        admin_member?.pagination?.page ? admin_member?.pagination?.page : 0
    );

    console.log(pageSize, page);
    React.useEffect(() => {
        setPage(admin_member?.pagination?.page);
        setPageSize(Number(admin_member?.pagination?.limit));

        // dispatch(GetMembers(page, Number(pageSize)));
    }, [admin_member?.pagination?.page, admin_member?.pagination?.limit]);
    const [data_rows, setdata_rows] = useState([]);
    React.useEffect(() => {
        setdata_rows(admin_member && admin_member?.stats);
    }, [admin_member, admin_member?.stats]);

    const [rowCountState, setRowCountState] = React.useState(
        admin_member?.pagination?.total_count || 0
    );

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            admin_member?.pagination?.total_count !== undefined
                ? admin_member?.pagination?.total_count
                : prevRowCountState
        );
    }, [admin_member?.pagination?.total_count, setRowCountState]);
    const loading = useSelector((state) => state.loading);
    const [grouptype, setgroup_type] = useState("Affiliate");

    React.useEffect(() => {
        var group = "";
        console.log();
        if (
            admin_member?.user?.affiliate_partner?.affiliate_group_type == "default"
        ) {
            group = "Affiliate";
        }
        if (
            admin_member?.user?.affiliate_partner?.affiliate_group_type ==
            "ad_network"
        ) {
            group = "AdNetwork";
        }
        if (
            admin_member?.user?.affiliate_partner?.affiliate_group_type ==
            "model_promocode"
        ) {
            group = "Model Referral";
        }
        if (
            admin_member?.user?.affiliate_partner?.affiliate_group_type == "organic"
        ) {
            group = "Organic";
        }
        setgroup_type(group);
    }, [admin_member?.user?.affiliate_partner?.affiliate_group_type]);

    const group_selector = [
        {
            value: "Affiliate",
            name: "2",
        },
        {
            value: "AdNetwork",
            name: "€",
        },
        {
            value: "Model Referral",
            name: "1",
        },
        {
            value: "Organic",
            name: "3",
        },
    ];
    const handleChangeGroup = (event) => {
        console.log(event.target);
        setgroup_type(event.target.value);
        var group = "";

        if (event.target.value == "Affiliate") {
            group = "default";
        }
        if (event.target.value == "AdNetwork") {
            group = "ad_network";
        }
        if (event.target.value == "Model Referral") {
            group = "model_promocode";
        }
        if (event.target.value == "Organic") {
            group = "organic";
        }
        dispatch(
            changeAdminAffiliateStatus(param.id, {new_account_group: group})
        );
    };

    const [searchText, setSearchText] = useState("");

    React.useEffect(() => {
        const timeoutID = setTimeout(() => {
            dispatch(GetMember(param?.id, page, Number(pageSize), searchText, first, last));
        }, 500);
        return () => clearTimeout(timeoutID);
    }, [searchText]);

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        console.log(searchValue);
    };
    return (
        <>
            <Container maxWidth="xl">
                <Box sx={{flexGrow: 1}}>
                    <Grid
                        className="container"
                        container
                        spacing={4}
                        style={{marginTop: "40px"}}
                    >
                        <Grid
                            item
                            xs={12}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-end",
                                justifyContent: "space-between",
                                paddingTop: "46px",
                            }}
                        >
                            <Grid
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "35%",
                                    gap: "25px",
                                }}
                            >
                                <Typography
                                    style={{
                                        color: "white",
                                        font: "normal normal bold 1.5rem Effra",
                                        minWidth: "fit-content",
                                    }}
                                >
                                    {admin_member?.user?.username}`s Users
                                </Typography>
                                <Grid
                                    className="stats_type"
                                    style={{width: "-webkit-fill-available"}}
                                    item
                                >
                                    {/* <Typography className="Support_selector">Group Filter</Typography> */}
                                    <Select
                                        className="LandingSelect"
                                        value={grouptype}
                                        onChange={handleChangeGroup}
                                        displayEmpty
                                        renderValue={(value) => {
                                            return (
                                                <Box sx={{display: "flex", gap: 1}}>
                                                    <SvgIcon color="black">
                                                        <FilterListOutlinedIcon/>
                                                    </SvgIcon>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            width: "-webkit-fill-available",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        {value && value}
                                                    </div>
                                                </Box>
                                            );
                                        }}
                                    >
                                        {group_selector.map((option) => (
                                            <MenuItem
                                                style={{textAlign: "center"}}
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid className="stats_time_grid" style={{justifyContent: 'center'}}>
                                <Grid className="stats_time" item>
                                    <Typography className="Support_selector">
                                        Select custom period
                                    </Typography>
                                    <DateRangePickerCompAffMembers
                                        id={param.id}
                                        page={page}
                                        limit={Number(pageSize)}
                                        search={searchText}
                                        start={admin_member && admin_member.start_date}
                                        end={admin_member && admin_member.end_date}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                style={{display: "flex", flexDirection: "row", gap: "25px"}}
                            >
                                <Button
                                    onClick={() => {
                                        dispatch(
                                            changeAdminAffiliateStatus(param.id, {
                                                new_account_status:
                                                    admin_member?.user?.accountStatus == "active"
                                                        ? "disable"
                                                        : "active",
                                            })
                                        );
                                    }}
                                    className="new_campaign"
                                    style={{
                                        background:
                                            admin_member?.user?.accountStatus == "active"
                                                ? "#f62020 0% 0% no-repeat padding-box"
                                                : "#77F73B 0% 0% no-repeat padding-box",
                                        color: "black",
                                        boxShadow: "0px 3px 6px #00000029",
                                        font: "normal normal bold 1rem Montserrat",
                                        borderRadius: "15px",
                                        padding: "7px 15px",
                                    }}
                                >
                                    {admin_member?.user?.accountStatus == "active"
                                        ? "Disable Account"
                                        : "Activate Account"}{" "}
                                </Button>
                                <Button
                                    onClick={() => {
                                        dispatch(loginAsAffiliate(param.id));
                                    }}
                                    className="new_campaign"
                                    style={{
                                        background: "#77F73B 0% 0% no-repeat padding-box",
                                        color: "black",
                                        boxShadow: "0px 3px 6px #00000029",
                                        font: "normal normal bold 1rem Montserrat",
                                        borderRadius: "15px",
                                        padding: "7px 15px",
                                    }}
                                >
                                    Log into account
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{paddingTop: "0px"}}>
                            <div
                                style={{height: "600px", width: "100%", marginTop: "10px"}}
                            >
                                {data_rows && (
                                    <DataGrid
                                        rows={data_rows && data_rows}
                                        columns={columns}
                                        pageSize={pageSize}
                                        onPageChange={(newPage) => {
                                            setPage(newPage);
                                            dispatch(GetMember(param?.id, newPage, Number(pageSize), searchText, first, last));
                                        }}
                                        rowCount={rowCountState}
                                        onPageSizeChange={(newPageSize) => {
                                            setPageSize(newPageSize);
                                            dispatch(GetMember(param?.id, page, Number(newPageSize), searchText, first, last));
                                        }}
                                        rowsPerPageOptions={[20, 50, 100]}
                                        //   checkboxSelection
                                        loading={loading}
                                        pagination
                                        paginationMode="server"
                                        page={page}
                                        // onRowClick={handleRowClick}
                                        components={{Toolbar: QuickSearchToolbar}}
                                        componentsProps={{
                                            toolbar: {
                                                value: searchText,
                                                showQuickFilter: true,
                                                quickFilterProps: {debounceMs: 500},
                                                onChange: (event) => requestSearch(event.target.value),
                                                clearSearch: () => requestSearch(""),
                                            },
                                        }}
                                        // onSelectionModelChange={(newSelectionModel) => {
                                        //   setSelectionModel(newSelectionModel);
                                        // }}#
                                    />
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    );
}

export default connect(mapStateToProps)(Members);
