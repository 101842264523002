import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Grow,
  MenuItem,
  Select,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { CheckBox, Handshake } from "@mui/icons-material";
import { removeErrors, setPurchaseType } from "../../redux/rootReducer";
import { connect, useDispatch, useSelector } from "react-redux";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Divider } from "@mui/material";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { RequiredPayout } from "../../redux/actions/userAction";

const mapStateToProps = (state) => {
  return {
    purchase_type: state.purchaseType,
    session: state.session,
  };
};
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};
function PayoutSettings({ purchase_type, session }) {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = React.useState(
    purchase_type && purchase_type
  );
  React.useEffect(() => {
    if (purchase_type == "company") {
      setSelectedType("company");
    } else {
      setSelectedType("personal");
    }
  }, [purchase_type]);
  React.useEffect(() => {
    dispatch(setPurchaseType(selectedType));
  }, [selectedType]);

  const currencies = [
    {
      value: "Wire transfer",
      label: "$",
    },
    {
      value: "Cryptocurrency",
      label: "€",
    },
  ];

  const [inputs, setInputs] = React.useState({
    payment_method: session?.user?.latestPaymentMethod?.payable_type
      ? session?.user?.latestPaymentMethod?.payable_type
      : "Wire transfer",
    recipient: session?.user?.latestPaymentMethod?.payable?.recipient
      ? session?.user?.latestPaymentMethod?.payable?.recipient
      : "",
    iban: session?.user?.latestPaymentMethod?.payable?.iban
      ? session?.user?.latestPaymentMethod?.payable?.iban
      : "",
    bic: session?.user?.latestPaymentMethod?.payable?.bic
      ? session?.user?.latestPaymentMethod?.payable?.bic
      : "",
    bank_name: session?.user?.latestPaymentMethod?.payable?.bank_name
      ? session?.user?.latestPaymentMethod?.payable?.bank_name
      : "",
    bank_address: session?.user?.latestPaymentMethod?.payable?.bank_address
      ? session?.user?.latestPaymentMethod?.payable?.bank_address
      : "",
    crypto_wallet: session?.user?.latestPaymentMethod?.payable?.crypto_wallet
      ? session?.user?.latestPaymentMethod?.payable?.crypto_wallet
      : "",
    crypto_currency: session?.user?.latestPaymentMethod?.payable
      ?.crypto_currency
      ? session?.user?.latestPaymentMethod?.payable?.crypto_currency
      : "",
  });
  function handleChange(evt) {
    const value = evt.target.value;
    setInputs({
      ...inputs,
      [evt.target.name]: value,
    });
  }

  const selectedPayment =
    (inputs && inputs.payment_method === "wire") ||
    (inputs && inputs.payment_method === "Wire transfer")
      ? "Wire transfer"
      : "Cryptocurrency";
  const [selected, setSelected] = useState(selectedPayment && selectedPayment);
  const handleChangeCampaign = (event) => {
    console.log(event.target.value);
    inputs.payment_method = event.target.value;
    setSelected(event.target.value);
  };

  function HandlePayout() {
    if (
      (inputs && inputs.payment_method === "Wire transfer") ||
      (inputs && inputs.payment_method === "wire")
    ) {
      inputs.payment_method = "wire";
    } else {
      inputs.payment_method = "crypto";
      inputs.crypto_currency = crypto;
    }
    dispatch(RequiredPayout(inputs));
    console.log(inputs);
  }

  {
    /* <Grid className='stats_choise'>
                              <Button onClick={() => { setSelectedType("clients"); }} style={{ color: selectedType == 'clients' ? 'black' : 'white', marginRight: '20px', border: '2px solid #ffffff', height: "28px", borderRadius: '5px', alignItems: "stretch", padding: "0px 8px", backgroundColor: selectedType == 'clients' && "white" }} > my clients</Button>
                              <Button onClick={() => { setSelectedType("affiliates"); }} style={{ color: selectedType == 'affiliates' ? 'black' : 'white', border: '2px solid #ffffff', height: "28px", borderRadius: '5px', alignItems: "stretch", padding: "0px 8px", backgroundColor: selectedType == 'affiliates' && "white" }} > my  Affiliates</Button>
                          </Grid> */
  }
  const [crypto, setCrypto] = React.useState("BTC");
  const handleChangeCrypto = (event) => {
    setCrypto(event.target.value);
  };
  return (
    <Container maxWidth="xl">
      <Box sx={{ flexGrow: 1 }}>
        <Grid className="container" container spacing={4}>
          {/* <Grid item xs={12}>
            <Grid className="data_headers">
              <Typography
                style={{
                  color: "white",
                  marginTop: "15px",
                  font: "normal normal bold 1.5rem Effra",
                  marginRight: "10px",
                  width: "fit-content",
                }}
              >
                Payout Settings
              </Typography>
              <Typography
                className="green_text"
                style={{ color: "#77F73B", fontSize: "0.8rem" }}
              >
                Minimum payout is $50.00
              </Typography>
            </Grid>
            <Divider
              style={{
                opacity: 1,
                width: "100%",
                border: "1px solid #5C5C5C",
                borderRadius: "10px",
                marginTop: "10px",
              }}
              component="div"
            />
          </Grid> */}
           <Grow in timeout={200} delay={400}>
          <Grid item xs={12} className="SupportGrid">
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Grid className="DataBoxPayout">
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width:'100%'
                  }}
                >
                  <Typography style={{ color: "white" }}>
                    YOUR ACCOUNT BALANCE
                  </Typography>
                  <Typography style={{ color: "#77F73B", fontSize: "1.5rem" }}>
                    ${session?.user?.affiliatePartner?.balance}
                  </Typography>
                  <Typography
                    className="green_text"
                    style={{ color: "#77F73B", fontSize: "0.8rem" }}
                  >
                    Minimum payout is $50.00
                  </Typography>
                </Grid>
                <Grid className="Payout_type" item style={{width:'100%'}}>
                  {/* <Typography
                    className="Support_selector"
                    style={{ width: "30%" }}
                  >
                    Transfer
                  </Typography> */}
                  <Select
                    className="PayoutSelect"
                    defaultValue=""
                    displayEmpty
                    style={{fontSize: "14px",width:'100%'}}
                    value={selected && selected}
                    onChange={handleChangeCampaign}
                    renderValue={(value) => {
                      console.log(value);
                      return (
                        <div
                          style={{
                            display: "flex",
                            width: "-webkit-fill-available",
                            justifyContent: "center",
                          }}
                        >
                          {value}
                        </div>
                      );
                    }}
                  >
                    {currencies.map((option) => (
                      <MenuItem
                        style={{ textAlign: "center" }}
                        key={option.value}
                        value={option.value}
                      >
                        {option.value}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                {selected && selected === "Wire transfer" && (
                  <>
                    <Box
                      className="payoutField_user"
                      initial={{ opacity: 0, y: 40 }}
                      animate={animate}
                    >
                      <TextField
                        fullWidth
                        multiline
                        name="recipient"
                        value={inputs.recipient}
                        onChange={handleChange}
                        placeholder="Recipient name"
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                        }}
                      />
                      <TextField
                        fullWidth
                        multiline
                        name="iban"
                        value={inputs.iban}
                        onChange={handleChange}
                        placeholder="IBAN"
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                        }}
                      />
                      <TextField
                        fullWidth
                        multiline
                        name="bic"
                        value={inputs.bic}
                        onChange={handleChange}
                        placeholder="BIC / SWIFT"
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                        }}
                      />
                      <TextField
                        fullWidth
                        multiline
                        name="bank_name"
                        value={inputs.bank_name}
                        onChange={handleChange}
                        placeholder="Bank name"
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                        }}
                      />
                      <TextField
                        fullWidth
                        multiline
                        name="bank_address"
                        value={inputs.bank_address}
                        onChange={handleChange}
                        placeholder="Bank address"
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                        }}
                      />
                    </Box>
                  </>
                )}
                {selected && selected === "Cryptocurrency" && (
                  <>
                    <Box
                      className="payoutField_user"
                      initial={{ opacity: 0, y: 40 }}
                      animate={animate}
                    >
                      <TextField
                        fullWidth
                        multiline
                        name="crypto_wallet"
                        value={inputs.crypto_wallet}
                        onChange={handleChange}
                        placeholder="Crypto wallet"
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                        }}
                      />


                      <Select
                        value={crypto}
                        className="PayoutSelectCrypto"
                        onChange={handleChangeCrypto}
                        style={{  fontSize: "14px" }}
                        renderValue={(value) => {
                          console.log(value);
                          return (

                              <div
                                style={{
                                  display: "flex",
                                  width: "-webkit-fill-available",
                                  justifyContent: "center",
                                  color:'#77F73B'
                                }}
                              >
                                {value}
                              </div>
                          );
                        }}
                      >
                        <MenuItem value={"BTC"}>BTC</MenuItem>
                        {/* <MenuItem value={"ETH"}>ETH</MenuItem>
                      <MenuItem value={"LTC"}>LTC</MenuItem> */}
                        {/*<MenuItem value={"LTCT"}>LTCT</MenuItem>*/}
                      </Select>
                      {/* <TextField
                      fullWidth
                      multiline
                      name="crypto_currency"
                      value={inputs.crypto_currency}
                      onChange={handleChange}
                      placeholder="Currency(BTC,ETH...)"
                      variant="standard"
                      style={{ backgroundColor: "#191919", paddingTop: "25px" }}
                      type="email"
                    /> */}
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          </Grow>
          {/* <Grid
            item
            xs={12}
            style={{ display: "flex", flexDirection: "row", marginTop: "15px" }}
          >
            <Divider
              style={{
                opacity: 1,
                width: "100%",
                border: "2px solid white",
                borderRadius: "10px",
              }}
              component="div"
            />
          </Grid> */}
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "15px",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Button
              size="large"
              type="submit"
              variant="contained"
              className="PayoutButton"
              onClick={HandlePayout}
              /*                             style={{ backgroundColor: "#77F73B", font: "normal normal 800 1.2rem Effra", width: '20% ' }} */
            >
              {"SAVE"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default connect(mapStateToProps)(PayoutSettings);
