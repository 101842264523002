import {
  Autocomplete,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  GetGlodalStats,
  GetMembers,
  GetUserStats,
} from "../../redux/actions/AdminMembersActions";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import DateRangePickerCompGlobal from "../../components/DateRangePickerComp/DateRangePickerCompGlobal";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CloseIcon from "@mui/icons-material/Close";
const mapStateToProps = (state) => {
  return {
    global_statistics: state.global_statistics,
    user_statistics: state.user_statistics,
  };
};
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};
function GlobalStats({ global_statistics, user_statistics }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  React.useEffect(() => {
    dispatch(GetGlodalStats());
  }, []);

  const yearsMonths = [
    { id: 0, name: "January" },
    { id: 1, name: "February" },
    { id: 2, name: "March" },
    { id: 3, name: "April" },
    { id: 4, name: "May" },
    { id: 5, name: "June" },
    { id: 6, name: "July" },
    { id: 7, name: "August" },
    { id: 8, name: "September" },
    { id: 9, name: "October" },
    { id: 10, name: "November" },
    { id: 11, name: "December" },
  ];
  function getTime(time) {
    var date = new Date(time * 1000);
    const month_number = date.getMonth();
    const month = yearsMonths[month_number].name;
    // 👉️ 0
    // 👇️ returns day of the month (1-31)
    const day_month = date.getDate();
    const hours = date.getHours(); // get hours with getHours method
    const minutes = date.getMinutes(); // get minutes with getMinutes method // get seconds with getSeconds method
    const timeString = `${day_month} ${month} ${pad(hours)}:${pad(minutes)}`;
    return timeString; // finally, join to a time string
  }

  function pad(num) {
    return ("0" + num).slice(-2);
  }
  const [view_type, setview_type] = useState("day");
  const [grouptype, setgroup_type] = useState("All");
  const time = useSelector((state) => state.time_picker);

  function pad(num) {
    return ("0" + num).slice(-2);
  }
  let first = time && time[0] && new Date(time[0].startDate);
  let last = time && time[0] && new Date(time[0].endDate);
  first =
    time[0] &&
    first.getFullYear() +
      "-" +
      pad(first.getMonth() + 1) +
      "-" +
      pad(first.getDate());
  last =
    time[0] &&
    last.getFullYear() +
      "-" +
      pad(last.getMonth() + 1) +
      "-" +
      pad(last.getDate());

  React.useEffect(() => {
    setview_type(global_statistics?.filter_data?.view_type);
  }, [global_statistics?.filter_data?.view_type]);
  React.useEffect(() => {
    var group = "";
    console.log();
    if (global_statistics?.filter_data?.filter_by_group == "all") {
      group = "All";
    }
    if (global_statistics?.filter_data?.filter_by_group == "default") {
      group = "Affiliate";
    }
    if (global_statistics?.filter_data?.filter_by_group == "ad_network") {
      group = "AdNetwork";
    }
    if (global_statistics?.filter_data?.filter_by_group == "model_promocode") {
      group = "Model Referral";
    }
    if (global_statistics?.filter_data?.filter_by_group == "organic") {
      group = "Organic";
    }
    setgroup_type(group);
  }, [global_statistics?.filter_data?.filter_by_group]);
  const columns = [
    { field: "selected_object", headerName: view_type, width: "100" },
    {
      field: "clicks",
      headerName: `clicks_total: ${global_statistics?.summary_data?.clicks_summary}`,
      type: "number",
      width: "220",
    },
    {
      field: "unique_clicks",
      headerName: `unique_clicks_total: ${global_statistics?.summary_data?.unique_clicks_summary}`,
      type: "number",
      width: "220",
    },
    {
      field: "signups",
      headerName: `signups_total: ${global_statistics?.summary_data?.signups_summary}`,
      type: "number",
      width: "220",
    },
    {
      field: "earnings",
      headerName: `earnings_total: ${global_statistics?.summary_data?.earnings_summary}`,
      type: "number",
      width: "220",
      renderCell: (params) => {
        return <Typography>$ {params.row.earnings}</Typography>;
      },
    },
    {
      field: "deposits",
      headerName: `deposits_total: ${global_statistics?.summary_data?.deposits_summary}`,
      type: "number",
      width: "220",
      renderCell: (params) => {
        return <Typography>$ {params.row.deposits}</Typography>;
      },
    },
    {
      field: "chargebacks",
      headerName: `chargebacks_total: ${global_statistics?.summary_data?.chargebacks_summary}`,
      type: "number",
      width: "220",
      renderCell: (params) => {
        return <Typography>$ {params.row.chargebacks}</Typography>;
      },
    },
  ];
  const userColumns = [
    { field: "campaign_id", headerName: "Campaign Id", width: "300" },
    { field: "campaign_name", headerName: "Campaign Name", width: "300" },
    { field: "createdAt", headerName: "Created", width: "300" },
  ];

  const [pageSize, setPageSize] = React.useState(
    global_statistics?.pagination?.limit
      ? Number(global_statistics?.pagination?.limit)
      : 20
  );
  const [page, setPage] = React.useState(
    global_statistics?.pagination?.page
      ? global_statistics?.pagination?.page
      : 0
  );

  React.useEffect(() => {
    setPage(global_statistics?.pagination?.page);
    setPageSize(Number(global_statistics?.pagination?.limit));

    // dispatch(GetMembers(page, Number(pageSize)));
  }, [
    global_statistics?.pagination?.page,
    global_statistics?.pagination?.limit,
  ]);

  const [rowCountState, setRowCountState] = React.useState(
    global_statistics?.pagination?.total_count || 0
  );
  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      global_statistics?.pagination?.total_count !== undefined
        ? global_statistics?.pagination?.total_count
        : prevRowCountState
    );
  }, [global_statistics?.pagination?.total_count, setRowCountState]);

  const [data_rows, setdata_rows] = useState([]);
  React.useEffect(() => {
    setdata_rows(
      global_statistics &&
        global_statistics.period_data &&
        global_statistics.period_data
    );
  }, [global_statistics, global_statistics.period_data]);

  const loading = useSelector((state) => state.loading);
  const [selectionModel, setSelectionModel] = React.useState();

  const handleRowClick = (params) => {
    console.log(params.row.period);
  };
  const selector = [
    {
      value: "day",
      label: "$",
    },
    {
      value: "user",
      label: "€",
    },
    {
      value: "campaign",
      label: "1",
    },
  ];

  const group_selector = [
    {
      value: "All",
      name: "all",
    },
    {
      value: "Affiliate",
      name: "2",
    },
    {
      value: "AdNetwork",
      name: "€",
    },
    {
      value: "Model Referral",
      name: "1",
    },
    {
      value: "Organic",
      name: "3",
    },
  ];
  const handleChangeCampaign = (event) => {
    console.log(event.target.value);
    setview_type(event.target.value);
    dispatch(
      GetGlodalStats(
        first,
        last,
        event.target.value,
        grouptype,
        selectionCompanies,
        page,
        Number(pageSize)
      )
    );
  };
  const handleChangeGroup = (event) => {
    console.log(event.target);
    setgroup_type(event.target.value);
    var group = "";
    if (event.target.value == "All") {
      group = "all";
    }
    if (event.target.value == "Affiliate") {
      group = "default";
    }
    if (event.target.value == "AdNetwork") {
      group = "ad_network";
    }
    if (event.target.value == "Model Referral") {
      group = "model_promocode";
    }
    if (event.target.value == "Organic") {
      group = "organic";
    }
    dispatch(
      GetGlodalStats(
        first,
        last,
        view_type,
        group,
        selectionCompanies,
        page,
        Number(pageSize)
      )
    );
  };

  const [open, setOpen] = React.useState(false);
  function navigateTo(item) {
    setOpen(true);
    if (user_view !== null) {
      dispatch(GetUserStats(user_view.id));
    } else {
      dispatch(GetUserStats());
    }
  }
  const handleClose = () => {
    setOpen(false);
  };
  const [user_view, setuser_view] = useState([]);
  const handleChangeUser = (event) => {
    console.log(event.target.value);
    setuser_view(event.target.value);
    dispatch(GetUserStats(event.target.value.id));
  };
  console.log(user_statistics);

  const [selectionCompanies, setSelectionCompanies] = React.useState([]);
  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map(
      (id) =>
        user_statistics &&
        user_statistics.user_campaigns &&
        user_statistics.user_campaigns.find((row) => row.id === id).campaign_id
    );
    console.log(selectedRowsData);
    setSelectionCompanies(selectedRowsData);
  };
  function ApplyFilters() {
    setOpen(false);
    dispatch(
      GetGlodalStats(
        first,
        last,
        view_type,
        grouptype,
        selectionCompanies,
        page,
        Number(pageSize)
      )
    );
  }

  const [user_rows, setuser_rows] = useState([]);
  React.useEffect(() => {
    setuser_rows(
      user_statistics &&
        user_statistics.user_campaigns &&
        user_statistics.user_campaigns
    );
  }, [user_statistics, user_statistics.user_campaigns]);
  function ClearFilters() {
    setSelectionCompanies([]);
    dispatch(
      GetGlodalStats(
        first,
        last,
        view_type,
        grouptype,
        [],
        page,
        Number(pageSize)
      )
    );
  }

  const [finalClickInfo, setFinalClickInfo] = useState(null);

  const handleOnCellClick = (params) => {
    console.log(params);
    // if(params.field ==='createdAt'){
    //   navigate('/admin/payout/'+params.row.id)
    // }
    setFinalClickInfo(params);
  };
  const [queryOptions, setQueryOptions] = React.useState({});

  const handleSortModelChange = (sortModel) => {
    // Here you save the data you need from the sort model

    if (sortModel[0] == undefined) {
      dispatch(
        GetGlodalStats(
          first,
          last,
          view_type,
          grouptype,
          selectionCompanies,
          page,
          Number(pageSize)
        )
      );
    }
    if (sortModel[0].field == "earnings" || sortModel[0].field == "deposits") {
      dispatch(
        GetGlodalStats(
          first,
          last,
          view_type,
          grouptype,
          selectionCompanies,
          page,
          Number(pageSize),
          sortModel[0].field,
          sortModel[0].sort
        )
      );
    }
  };
  return (
    <>
      <Container maxWidth="xl">
        <Box sx={{ flexGrow: 1 }}>
          <Grid className="container" container spacing={4}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <Grid>
                <Button
                  className="new_campaign"
                  style={{
                    background: "#77F73B 0% 0% no-repeat padding-box",
                    color: "black",
                    boxShadow: "0px 3px 6px #00000029",
                    font: "normal normal bold 1rem Montserrat",
                    borderRadius: "15px",
                    padding: "7px 15px",
                  }}
                  onClick={() => {
                    navigateTo();
                  }}
                >
                  Custom FIlter
                </Button>
                {selectionCompanies.length !== 0 && (
                  <Button
                    className="new_campaign"
                    style={{
                      background: "#808080 0% 0% no-repeat padding-box",
                      color: "white",
                      boxShadow: "0px 3px 6px #00000029",
                      font: "normal normal bold 1rem Montserrat",
                      borderRadius: "15px",
                      padding: "7px 15px",
                      marginLeft: "25px",
                    }}
                    onClick={() => {
                      ClearFilters();
                    }}
                  >
                    Clear FIlter
                  </Button>
                )}
              </Grid>

              <Grid
                className="stats_type"
                style={{ alignItems: "flex-end" }}
                item
              >
                <Typography className="Support_selector">
                  Group Filter
                </Typography>
                <Select
                  className="LandingSelect"
                  value={grouptype}
                  onChange={handleChangeGroup}
                  displayEmpty
                  renderValue={(value) => {
                    return (
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <SvgIcon color="black">
                          <FilterListOutlinedIcon />
                        </SvgIcon>
                        <div
                          style={{
                            display: "flex",
                            width: "-webkit-fill-available",
                            justifyContent: "center",
                          }}
                        >
                          {value && value}
                        </div>
                      </Box>
                    );
                  }}
                >
                  {group_selector.map((option) => (
                    <MenuItem
                      style={{ textAlign: "center" }}
                      key={option.value}
                      value={option.value}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className="stats_body"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "46px",
              }}
            >
              <Grid className="stats_type" item>
                <Typography className="Support_selector">Filter</Typography>
                <Select
                  className="LandingSelect"
                  value={view_type}
                  onChange={handleChangeCampaign}
                  displayEmpty
                  renderValue={(value) => {
                    return (
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <SvgIcon color="black">
                          <FilterListOutlinedIcon />
                        </SvgIcon>
                        <div
                          style={{
                            display: "flex",
                            width: "-webkit-fill-available",
                            justifyContent: "center",
                          }}
                        >
                          {value && value}
                        </div>
                      </Box>
                    );
                  }}
                >
                  {selector.map((option) => (
                    <MenuItem
                      style={{ textAlign: "center" }}
                      key={option.value}
                      value={option.value}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid className="stats_time_grid">
                <Grid className="stats_time" item>
                  <Typography className="Support_selector">
                    Select custom period
                  </Typography>
                  <DateRangePickerCompGlobal
                    start={global_statistics && global_statistics.start_date}
                    end={global_statistics && global_statistics.end_date}
                    filter={view_type && view_type}
                    group_filter={grouptype && grouptype}
                    filter_by_campaign={selectionCompanies}
                    page={page}
                    limit={Number(pageSize)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: "46px",
              }}
            >
              <Typography
                style={{
                  color: "white",
                  font: "normal normal bold 1.5rem Effra",
                }}
              >
                Affiliate Members
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "0px" }}>
              <div style={{ height: "600px", width: "100%" }}>
                {data_rows && (
                  <DataGrid
                    rows={data_rows && data_rows}
                    columns={columns}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={(newPage) => {
                      setPage(newPage);
                      dispatch(
                        GetGlodalStats(
                          first,
                          last,
                          view_type,
                          grouptype,
                          selectionCompanies,
                          newPage,
                          Number(pageSize)
                        )
                      );
                    }}
                    rowCount={rowCountState}
                    onPageSizeChange={(newPageSize) => {
                      setPageSize(newPageSize);
                      dispatch(
                        GetGlodalStats(
                          first,
                          last,
                          view_type,
                          grouptype,
                          selectionCompanies,
                          page,
                          Number(newPageSize)
                        )
                      );
                    }}
                    rowsPerPageOptions={[20, 50, 100]}
                    //   checkboxSelection
                    loading={loading}
                    pagination
                    paginationMode="server"
                    onRowClick={handleRowClick}
                    onSortModelChange={handleSortModelChange}

                    // onCellClick={handleOnCellClick}
                  />
                )}
                {finalClickInfo &&
                  `Final clicked id = ${finalClickInfo.id}, 
        Final clicked field = ${finalClickInfo.field}, 
        Final clicked value = ${finalClickInfo.value}`}
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Dialog
        className="pop-up_verify Dialog_highlight"
        open={open}
        onClose={handleClose}
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          style={{
            width: "fit-content",
            marginLeft: "15px",
            marginTop: "5px",
            position: "absolute",
            right: "5px",
            top: "2px",
            zIndex: 5,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogActions
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            padding: "20px 24px 5px",
          }}
        >
          <Autocomplete
            className="UserSelect"
            underlineStyle={{ display: "none" }}
            id="combo-box-demo"
            options={
              user_statistics?.all_users ? user_statistics?.all_users : []
            }
            value={user_view && user_view}
            getOptionLabel={(option) => option?.username || ""}
            onChange={(event, value) => {
              handleChangeUser({
                target: {
                  value: value,
                },
              });
            }}
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  disableUnderline="true"
                  variant="standard"
                />
              </>
            )}
          />
        </DialogActions>
        <DialogContent
          style={{ overflow: "unset", width: "1500px", paddingTop: "30px" }}
        >
          <div style={{ height: "600px", width: "100%" }}>
            {user_rows && (
              <DataGrid
                rows={user_rows && user_rows}
                columns={userColumns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 25, 50]}
                checkboxSelection
                loading={loading}
                onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
              />
            )}
          </div>
          {selectionCompanies.length !== 0 && (
            <Button
              className="new_campaign"
              style={{
                background: "#77F73B 0% 0% no-repeat padding-box",
                color: "black",
                boxShadow: "0px 3px 6px #00000029",
                font: "normal normal bold 1rem Montserrat",
                borderRadius: "15px",
                padding: "7px 15px",
                marginTop: "15px",
              }}
              onClick={() => {
                ApplyFilters();
              }}
            >
              Apply
            </Button>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default connect(mapStateToProps)(GlobalStats);
