import * as React from 'react';
import { Link } from 'react-router-dom'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import LogoutIcon from '@mui/icons-material/Logout';
import Logo from '../../assets/images/logo/full.png'
import { Button } from '@mui/material';

const settings = [
  {
    // icon: 'DeleteIcon',
    name: 'Logout',
    link: '/logout'
  }
];

const Header = ({ session }) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const [loginUser, setLoginUser] = React.useState(false)

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleScroll = (to) => {
    const elem = document.getElementById(to);
    if (elem) {
      elem.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
    }
  };

  return (
    <AppBar sx={{ boxShadow: 'unset', py: 2.5 }} position="static" enableColorOnDark style={{ background: 'var(--navbar-color-bg)' }}>
      <Container className="container" maxWidth="lg">
        <Toolbar disableGutters>
          <Link to='/'>
            {/* LOGO */}
            <Avatar sx={{ display: 'flex', mr: 0.5, width: '178px', height: 'fit-content' }} className="logo" variant="square" src={Logo} />
          </Link>

          <Box sx={{ flexGrow: 1 }} />


          <Box sx={{ flexGrow: 0 }}>
            <Tooltip disableHoverListener>
              <Stack direction="row" spacing={1}>
                <Link to="/register">
                  <Button className="btn btn-primary" variant="outlined">
                    Log in
                  </Button>
                </Link>
              </Stack>
            </Tooltip>
            {/* <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (

                <Link to={setting.link} key={setting.name}>
                  <MenuItem
                    onClick={handleCloseUserMenu}
                    sx={{ fontSize: '13px', color: 'white', display: 'block', fontWeight: 'bold', maxWidth: '220px', display: 'flex', textTransform: 'uppercase' }}>
                    {setting.name === 'logout' ? <LogoutIcon /> : ''}
                    &emsp;{setting.name}
                  </MenuItem>
                </Link>
              ))}
            </Menu> */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
