import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Grow,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { removeErrors, setPurchaseType } from "../../redux/rootReducer";
import { connect, useDispatch, useSelector } from "react-redux";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import SubjectOutlinedIcon from "@mui/icons-material/SubjectOutlined";
import PublicIcon from "@mui/icons-material/Public";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import WebOutlinedIcon from "@mui/icons-material/WebOutlined";
import { UpdateUser } from "../../redux/actions/userAction";
import { useSnackbar } from "notistack";

const mapStateToProps = (state) => {
  return {
    purchase_type: state.purchaseType,
    session: state.session,
  };
};
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

function Main({ purchase_type, session }) {
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = React.useState(
    purchase_type && purchase_type,
  );
  React.useEffect(() => {
    if (purchase_type == "company") {
      setSelectedType("company");
    } else {
      setSelectedType("personal");
    }
  }, [purchase_type]);
  React.useEffect(() => {
    dispatch(setPurchaseType(selectedType));
  }, [selectedType]);

  const [inputs, setInputs] = React.useState({
    company_name: session?.user?.affiliatePartner?.company_name
      ? session?.user?.affiliatePartner?.company_name
      : "",
    company_register_code: session?.user?.affiliatePartner
      ?.company_register_code
      ? session?.user?.affiliatePartner?.company_register_code
      : "",
    company_vat_id: session?.user?.affiliatePartner?.company_vat_id
      ? session?.user?.affiliatePartner?.company_vat_id
      : "",
    first_name: session?.user?.firstName ? session?.user?.firstName : "",
    last_name: session?.user?.affiliatePartner?.last_name
      ? session?.user?.affiliatePartner?.last_name
      : "",
    individual_country: session?.user?.affiliatePartner?.individual_country
      ? session?.user?.affiliatePartner?.individual_country
      : "",
    individual_address: session?.user?.affiliatePartner?.individual_address
      ? session?.user?.affiliatePartner?.individual_address
      : "",
    company_country: session?.user?.affiliatePartner?.company_country
      ? session?.user?.affiliatePartner?.company_country
      : "",
    company_address: session?.user?.affiliatePartner?.company_address
      ? session?.user?.affiliatePartner?.company_address
      : "",
    telegram: session?.user?.affiliatePartner?.messenger_info?.["telegram"]
      ? session?.user?.affiliatePartner?.messenger_info?.["telegram"]
      : "",
    skype: session?.user?.affiliatePartner?.messenger_info?.["skype"]
      ? session?.user?.affiliatePartner?.messenger_info?.["skype"]
      : "",
    email: session?.user?.email ? session?.user?.email : "",
    mobile_phone: session?.user?.affiliatePartner?.mobile_phone
      ? session?.user?.affiliatePartner?.mobile_phone
      : "",
    website: session?.user?.affiliatePartner?.traffic_info
      ? session?.user?.affiliatePartner?.traffic_info[0]
      : "",
    website2: session?.user?.affiliatePartner?.traffic_info
      ? session?.user?.affiliatePartner?.traffic_info[1]
      : "",
    website3: session?.user?.affiliatePartner?.traffic_info
      ? session?.user?.affiliatePartner?.traffic_info[2]
      : "",
    is_company: session?.user?.affiliatePartner?.is_company,
    traffic_info: [],
    preferred_messenger: session?.user?.affiliatePartner?.preferred_messenger
      ? session?.user?.affiliatePartner?.preferred_messenger
      : "telegram",
  });

  function handleChange(evt) {
    const value = evt.target.value;
    setInputs({
      ...inputs,
      [evt.target.name]: value,
    });
  }

  function chech() {
    inputs.traffic_info = [inputs.website, inputs.website2, inputs.website3];
    // session?.user?.affiliatePartner
    /*inputs.preferred_messenger = ;*/
    inputs["messenger_username"] =
      inputs.preferred_messenger == "telegram"
        ? inputs?.telegram
        : inputs.preferred_messenger == "skype"
          ? inputs?.skype
          : inputs.email;
    if (purchase_type == "company") {
      inputs.is_company = 1;
    } else {
      inputs.is_company = 0;
    }

    dispatch(UpdateUser(inputs));
    console.log(inputs);
  }

  const period = [
    {
      value: "telegram",
      label: "T",
    },
    {
      value: "skype",
      label: "S",
    },
    {
      value: "email",
      label: "E",
    },
  ];
  const [selected, setSelected] = useState(
    session?.user?.affiliatePartner?.preferred_messenger ?? "telegram",
  );
  const handleChangeCampaign = (event) => {
    console.log(event.target.value);
    setSelected(event.target.value);
    inputs.preferred_messenger = event.target.value;
  };

  const { enqueueSnackbar } = useSnackbar();
  const errorsSubmit = useSelector((state) => state.errors);
  React.useEffect(() => {
    if (errorsSubmit && errorsSubmit.company_address) {
      enqueueSnackbar(errorsSubmit.company_address[0], { variant: "error" });
    }
    if (errorsSubmit && errorsSubmit.company_name) {
      enqueueSnackbar(errorsSubmit.company_name[0], { variant: "error" });
    }
    if (errorsSubmit && errorsSubmit.company_register_code) {
      enqueueSnackbar(errorsSubmit.company_register_code[0], {
        variant: "error",
      });
    }
    if (errorsSubmit && errorsSubmit.company_vat_id) {
      enqueueSnackbar(errorsSubmit.company_vat_id[0], {
        variant: "error",
      });
    }
    if (errorsSubmit && errorsSubmit.company_country) {
      enqueueSnackbar(errorsSubmit.company_country[0], {
        variant: "error",
      });
    }
    if (errorsSubmit && errorsSubmit.mobile_phone) {
      enqueueSnackbar(errorsSubmit.mobile_phone[0], {
        variant: "error",
      });
    }
    if (errorsSubmit && errorsSubmit.first_name) {
      enqueueSnackbar(errorsSubmit.first_name[0], {
        variant: "error",
      });
    }
    if (errorsSubmit && errorsSubmit.individual_address) {
      enqueueSnackbar(errorsSubmit.individual_address[0], {
        variant: "error",
      });
    }
    if (errorsSubmit && errorsSubmit.individual_country) {
      enqueueSnackbar(errorsSubmit.individual_country[0], {
        variant: "error",
      });
    }

    setTimeout(() => {
      dispatch(removeErrors());
    }, 5000);
  }, [errorsSubmit]);
  return (
    <Container maxWidth="xl">
      <Box sx={{ flexGrow: 1 }}>
        <Grid className="container" container spacing={4}>
          <Grid item xs={12}>
            <Grid>
              <FormGroup style={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel
                  value={"first"}
                  className="Form"
                  style={{ color: "white", marginRight: "40px" }}
                  control={
                    <Checkbox
                      checked={selectedType == "personal" ? true : false}
                      style={{
                        color: "white",
                      }}
                      onClick={() => {
                        setSelectedType("personal");
                      }}
                    />
                  }
                  label="Individual"
                />
                <FormControlLabel
                  value={"second"}
                  className="Form"
                  style={{ color: "white" }}
                  control={
                    <Checkbox
                      checked={selectedType == "company" ? true : false}
                      style={{
                        color: "white",
                      }}
                      onClick={() => {
                        setSelectedType("company");
                      }}
                    />
                  }
                  label="Company"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className="DataGrid"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Grow in timeout={200} delay={400}>
              <Grid item xs={4} className="data">
                <Typography
                  style={{
                    color: "white",
                    marginTop: "15px",
                    font: "normal normal bold 1.5rem Effra",
                  }}
                >
                  {selectedType == "company"
                    ? "Company Profile"
                    : "Your profile"}
                </Typography>
                <Grid className="DataBox">
                  {selectedType == "company" && (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          gap: "16px",
                          width: "90%",
                        }}
                        className="LoginBox"
                        initial={{ opacity: 0, y: 40 }}
                        animate={animate}
                      >
                        {/* <MailOutlineIcon
                        sx={{ color: "action.active", mr: 1, my: 0.5 }}
                      />
                      <TextField
                        fullWidth
                        size="medium"
                        name="company_name"
                        value={inputs.company_name}
                        onChange={handleChange}
                        placeholder="C"
                        variant="standard"
                        style={{
                          backgroundColor: "#191919",
                          paddingTop: "25px",
                        }}
                        type="text"
                        required
                        error={inputs.company_name.length === 0 ? true : false}
                      /> */}
                        <TextField
                          fullWidth
                          name="company_name"
                          value={inputs.company_name}
                          onChange={handleChange}
                          placeholder="Company name"
                          style={{
                            backgroundColor: "#FFFFFF",
                            borderRadius: "5px",
                          }}
                          required
                          error={
                            inputs.company_name.length === 0 ? true : false
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <BusinessOutlinedIcon
                                  style={{ color: "black" }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          fullWidth
                          name="company_register_code"
                          value={inputs.company_register_code}
                          onChange={handleChange}
                          placeholder="Local registry no."
                          style={{
                            backgroundColor: "#FFFFFF",
                            borderRadius: "5px",
                          }}
                          required
                          error={
                            inputs.company_register_code.length === 0
                              ? true
                              : false
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SubjectOutlinedIcon
                                  style={{ color: "black" }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />

                        <TextField
                          fullWidth
                          name="company_vat_id"
                          value={inputs.company_vat_id}
                          onChange={handleChange}
                          placeholder="VAT (if eligible)"
                          style={{
                            backgroundColor: "#FFFFFF",
                            borderRadius: "5px",
                          }}
                          required
                          error={
                            inputs.company_vat_id.length === 0 ? true : false
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SubjectOutlinedIcon
                                  style={{ color: "black" }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </>
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      margin: "16px 0px",
                      width: "90%",
                    }}
                    className="LoginBox"
                    initial={{ opacity: 0, y: 40 }}
                    animate={animate}
                  >
                    <TextField
                      fullWidth
                      name="first_name"
                      value={inputs.first_name}
                      onChange={handleChange}
                      placeholder={"Your Full Name"}
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      required
                      error={
                        inputs.first_name.length === 0 &&
                        selectedType == "personal"
                          ? true
                          : false
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleOutlinedIcon
                              style={{ color: "black" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>

                  {selectedType == "company" && (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          gap: "16px",
                          width: "90%",
                        }}
                        className="LoginBox"
                        initial={{ opacity: 0, y: 40 }}
                        animate={animate}
                      >
                        <TextField
                          fullWidth
                          name="company_country"
                          value={inputs.company_country}
                          onChange={handleChange}
                          placeholder="Country"
                          style={{
                            backgroundColor: "#FFFFFF",
                            borderRadius: "5px",
                          }}
                          required
                          error={
                            inputs.company_country.length === 0 ? true : false
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PublicIcon style={{ color: "black" }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          fullWidth
                          name="company_address"
                          value={inputs.company_address}
                          onChange={handleChange}
                          placeholder="Address"
                          style={{
                            backgroundColor: "#FFFFFF",
                            borderRadius: "5px",
                          }}
                          required
                          error={
                            inputs.company_address.length === 0 ? true : false
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <RoomOutlinedIcon style={{ color: "black" }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </>
                  )}
                  {selectedType == "personal" && (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          gap: "16px",
                          width: "90%",
                        }}
                        className="LoginBox"
                        initial={{ opacity: 0, y: 40 }}
                        animate={animate}
                      >
                        <TextField
                          fullWidth
                          name="individual_country"
                          value={inputs.individual_country}
                          onChange={handleChange}
                          placeholder="Country"
                          style={{
                            backgroundColor: "#FFFFFF",
                            borderRadius: "5px",
                          }}
                          required
                          error={
                            inputs.individual_country.length === 0
                              ? true
                              : false
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PublicIcon style={{ color: "black" }} />
                              </InputAdornment>
                            ),
                          }}
                        />

                        <TextField
                          fullWidth
                          name="individual_address"
                          value={inputs.individual_address}
                          onChange={handleChange}
                          placeholder="Address"
                          style={{
                            backgroundColor: "#FFFFFF",
                            borderRadius: "5px",
                          }}
                          required
                          error={
                            inputs.individual_address.length === 0
                              ? true
                              : false
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <RoomOutlinedIcon style={{ color: "black" }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grow>
            <Grow in timeout={200} delay={400}>
              <Grid item xs={5} className="data">
                <Grid className="data_headers">
                  <Typography
                    style={{
                      color: "white",
                      marginTop: "15px",
                      font: "normal normal bold 1.5rem Effra",
                      marginRight: "10px",
                      width: "fit-content",
                    }}
                  >
                    Communication
                  </Typography>
                  <Typography
                    className="green_text"
                    style={{ color: "#77F73B", fontSize: "0.8rem" }}
                  >
                    How we should be in contact
                  </Typography>
                </Grid>
                <Grid className="DataBoxComunication">
                  <Grid
                    className="period_select"
                    item
                    xs={10}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "26px",
                    }}
                  >
                    <Select
                      className="сommunicationTypeSelector"
                      defaultValue=""
                      onChange={handleChangeCampaign}
                      value={selected && selected}
                      displayEmpty
                      renderValue={(value) => {
                        return (
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <div
                              style={{
                                display: "flex",
                                width: "-webkit-fill-available",
                                justifyContent: "center",
                                textTransform: "capitalize",
                              }}
                            >
                              {value}
                            </div>
                          </Box>
                        );
                      }}
                    >
                      {period.map((option) => (
                        <MenuItem
                          style={{
                            textAlign: "center",
                            textTransform: "capitalize",
                          }}
                          key={option.value}
                          value={option.value}
                        >
                          {option.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "16px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        gap: "16px",
                        width: "90%",
                      }}
                      className="LoginBox"
                      initial={{ opacity: 0, y: 40 }}
                      animate={animate}
                    >
                      {selected && selected === "telegram" && (
                        <>
                          <TextField
                            fullWidth
                            name="telegram"
                            value={inputs.telegram}
                            onChange={handleChange}
                            placeholder="Telegram username"
                            style={{
                              backgroundColor: "#FFFFFF",
                              borderRadius: "5px",
                            }}
                          />
                        </>
                      )}
                      {selected && selected === "skype" && (
                        <>
                          <TextField
                            fullWidth
                            name="skype"
                            value={inputs.skype}
                            onChange={handleChange}
                            placeholder="Skype username"
                            style={{
                              backgroundColor: "#FFFFFF",
                              borderRadius: "5px",
                            }}
                          />
                        </>
                      )}
                      <TextField
                        fullWidth
                        name="email"
                        value={inputs.email}
                        onChange={handleChange}
                        placeholder="Email"
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MailOutlineIcon style={{ color: "black" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        fullWidth
                        name="mobile_phone"
                        value={inputs.mobile_phone}
                        onChange={handleChange}
                        placeholder="Phone number"
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                        }}
                        required
                        error={inputs.mobile_phone.length === 0 ? true : false}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LocalPhoneOutlinedIcon
                                style={{ color: "black" }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grow>
          </Grid>
          {/* <Grid
            item
            xs={12}
            style={{ display: "flex", flexDirection: "row", marginTop: "15px" }}
          >
            <Divider
              style={{
                opacity: 1,
                width: "100%",
                border: "1px solid #5C5C5C",
                borderRadius: "10px",
              }}
              component="div"
            />
          </Grid> */}
          <Grow in timeout={200} delay={400}>
            <Grid item xs={12} className="Trafic_info_grid">
              <Grid
                xs={12}
                md={12}
                sm={12}
                lg={9}
                xl={9}
                className="data_headers"
                style={{ width: "100%" }}
              >
                <Typography
                  style={{
                    color: "white",
                    marginTop: "15px",
                    font: "normal normal bold 1.5rem Effra",
                    marginRight: "10px",
                    width: "fit-content",
                  }}
                >
                  Traffic info
                </Typography>
                <Typography
                  className="green_text"
                  style={{ color: "#77F73B", fontSize: "0.8rem" }}
                >
                  Where do you plan to direct traffic from
                </Typography>
              </Grid>
              <Grid
                xs={9}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  width: "-webkit-fill-available",
                }}
                className="DataGrid"
              >
                <Grid className="DataBoxTrafic">
                  <Box
                    className="TrafficData"
                    initial={{ opacity: 0, y: 40 }}
                    animate={animate}
                  >
                    <TextField
                      fullWidth
                      name="website"
                      value={inputs.website}
                      onChange={handleChange}
                      placeholder="Website (optional)"
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <WebOutlinedIcon style={{ color: "black" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      fullWidth
                      name="website2"
                      value={inputs.website2}
                      onChange={handleChange}
                      placeholder="Website 2 (optional)"
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <WebOutlinedIcon style={{ color: "black" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      fullWidth
                      name="website2"
                      value={inputs.website3}
                      onChange={handleChange}
                      placeholder="Website 3 (optional)"
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <WebOutlinedIcon style={{ color: "black" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grow>

          {/* <Grid
            item
            xs={12}
            style={{ display: "flex", flexDirection: "row", marginTop: "15px" }}
          >
            <Divider
              style={{
                opacity: 1,
                width: "100%",
                border: "2px solid white",
                borderRadius: "10px",
              }}
              component="div"
            />
          </Grid> */}
          <Grid item xs={12} className="landing_save">
            <Button
              size="large"
              onClick={chech}
              type="submit"
              variant="contained"
              className="MainSaveButton"
              /*                             style={{ backgroundColor: "#77F73B", font: "normal normal 800 1.2rem Effra", width: '20% ' }} */
            >
              {"SAVE"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default connect(mapStateToProps)(Main);
