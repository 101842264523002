import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Avatar from "@mui/material/Avatar";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import Alert from "@mui/material/Alert";
import { List, ListItem, ListItemIcon, useTheme } from "@mui/material";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Logo from "../../assets/images/logo/full.png";
import ClearIcon from "@mui/icons-material/Clear";
import {
  removeErrors,
  setErrors,
  removeError,
  setMessage,
} from "../../redux/rootReducer";
import { useTranslation } from "react-i18next";
import { API_RESTORE_LINK_ROUTE, API_RESTORE_ROUTE } from "../../utils/consts";
import { showModal, hideModal } from "../../redux/rootReducer";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  minWidth: 320,
  width: "100%",
  bgcolor: "rgb(18, 18, 18)",
  border: "2px solid #000",
  textAlign: "center",
  margin: "0 auto",
  boxShadow: 24,
  p: 4,
};

const RequestingResetBox = ({
  t,
  setData,
  error,
  errors,
  handleSend,
  handleReturn,
}) => {
  return (
    <>
      <Typography
        component="h1"
        variant="h2"
        sx={{ mb: 2, mt: 2 }}
        fontSize={window.innerWidth < 1050 ? "1.1rem" : "1.4rem"}
      >
        {t("Forgot your password?")}
      </Typography>
      <Typography
        fontSize="0.85rem"
        className="font EffraRegular"
        sx={{ whiteSpace: "nowrap" }}
      >
        {t("Type in ")}{" "}
        <span>{t("the email you used to register at X-Money.")}</span>
      </Typography>
      <Typography
        fontSize="0.9rem"
        className="font EffraRegular"
        sx={{ whiteSpace: "nowrap" }}
      >
        {t("We'll send you a link to create a new password.")}
      </Typography>
      <Box
        component="form"
        noValidate
        sx={{ mt: 3 }}
        style={{ boxShadow: "none" }}
      >
        <Grid container spacing={2} onChange={setData}>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              id="email"
              label={t("Email")}
              name="email"
              autoComplete="email"
            />
          </Grid>
          {/* {error && typeof error === "string" && (
            <Grid item xs={12}>
              <Alert variant="outlined" severity="error">
                {error}
              </Alert>
            </Grid>
          )} */}
          {/* {errors?.email && (
            <Grid item xs={12}>
              <Alert
                variant="outlined"
                severity="error"
                style={{
                  textAlign: "initial",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {errors.email}
              </Alert>
            </Grid>
          )} */}
        </Grid>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            mt: 2,
            mb: 2,
            textTransform: "unset",
            backgroundColor: "rgb(119, 247, 59) !important",
          }}
          onClick={handleSend}
        >
          {t("Send email")}
        </Button>

        <Grid container justifyContent="center">
          <Grid item>
            <Link
              component="button"
              variant="body2"
              onClick={handleReturn}
              fontSize={window.innerWidth < 1050 ? "0.775rem" : "0.875rem"}
              style={{
                textDecoration: "underline",
                fontWeight: "bold",
                color: "#FFFFFF",
              }}
            >
              {t("Return")}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const EmailSentBox = ({ sent, t, theme, handleReturn, data }) => {
  return (
    <Grow in={sent} timeout={500} delay={200}>
      <Box className="flex column items-center">
        <Typography
          component="h1"
          variant="h2"
          sx={{ mb: 2, mt: 2 }}
          fontSize={window.innerWidth < 1050 ? "1.1rem" : "1.4rem"}
        >
          {t("We've sent an email to ")}
          <br />
          <span
            style={{
              color: "rgb(119, 247, 59)",
              marginTop: "5px",
              fontSize: "1.2rem",
            }}
          >
            {data.email}
          </span>
        </Typography>
        <Typography
          fontSize="0.85rem"
          className="font EffraRegular"
          sx={{ whiteSpace: "nowrap" }}
        >
          {t("Please make sure:")}
        </Typography>
        <List sx={{ mt: 1 }}>
          <Fade in={sent} timeout={1000} delay={600}>
            <ListItem disablePadding>
              <ListItemIcon sx={{ minWidth: "30px" }}>
                <CheckIcon color="white" fontSize="small" />
              </ListItemIcon>
              <Typography sx={{ fontSize: "0.8rem" }}>
                {t("You've entered it correctly")}
              </Typography>
            </ListItem>
          </Fade>
          <Fade in={sent} timeout={1200} delay={600}>
            <ListItem disablePadding>
              <ListItemIcon sx={{ minWidth: "30px" }}>
                <CheckIcon color="white" fontSize="small" />
              </ListItemIcon>
              <Typography sx={{ fontSize: "0.8rem" }}>
                {t("This email is linked to your profile")}
              </Typography>
            </ListItem>
          </Fade>
          <Fade in={sent} timeout={1400} delay={600}>
            <ListItem disablePadding>
              <ListItemIcon sx={{ minWidth: "30px" }}>
                <CheckIcon color="white" fontSize="small" />
              </ListItemIcon>
              <Typography sx={{ fontSize: "0.8rem" }}>
                {t("Check your spam and notifications folders")}
              </Typography>
            </ListItem>
          </Fade>
        </List>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            mt: 2,
            textTransform: "unset",
            backgroundColor: "rgb(119, 247, 59) !important",
          }}
          onClick={handleReturn}
        >
          {t("Return")}
        </Button>
      </Box>
    </Grow>
  );
};

const CongradulationsBox = ({ isReset, handleReturn, t }) => {
  return (
    <Grow in={isReset} timeout={500} delay={200}>
      <Box className="flex column items-center">
        <Typography
          component="h1"
          variant="h2"
          sx={{ mb: 2, mt: 2 }}
          fontSize={window.innerWidth < 1050 ? "1.1rem" : "1.4rem"}
        >
          {t("Congratulations!")}
        </Typography>
        <Typography
          fontSize="1rem"
          className="font EffraRegular"
          sx={{ mt: 2, textAlign: "left" }}
        >
          {t("Your password was successfully reset") +
            ". " +
            t("Please login to your account with new password!")}
        </Typography>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 4, textTransform: "unset" }}
          color="primary"
          onClick={handleReturn}
        >
          {t("Return")}
        </Button>
      </Box>
    </Grow>
  );
};

const ResetBox = ({ reset, handleReset, t, error, errors, setResetFields }) => {
  return (
    <Grow in={reset} timeout={500} delay={200}>
      <Box className="flex column items-center">
        <Typography
          component="h1"
          variant="h2"
          sx={{ mb: 2, mt: 2 }}
          fontSize={window.innerWidth < 1050 ? "1.1rem" : "1.4rem"}
        >
          {t("Reset Your Password")}
        </Typography>
        <Typography
          fontSize="0.85rem"
          className="font EffraRegular"
          sx={{ mb: 2, textAlign: "left" }}
        >
          {t(
            "Strong passwords include numbers, letters and punctuation marks."
          )}
        </Typography>
        <Grid container spacing={2} onChange={setResetFields}>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              id="password"
              label={t("Enter new password")}
              name="password"
              type="password"
              autoComplete="password"
            />
          </Grid>
          {/* {errors?.password && (
            <Grid item xs={12}>
              <Alert
                variant="outlined"
                severity="error"
                style={{
                  textAlign: "initial",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {errors.password}
              </Alert>
            </Grid>
          )} */}
          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              id="password_confirmation"
              label={t("Confirm new password")}
              name="password_confirmation"
              type="password"
              autoComplete="password_confirmation"
            />
          </Grid>
          {/* {errors?.password_confirmation && (
            <Grid item xs={12}>
              <Alert
                variant="outlined"
                severity="error"
                style={{
                  textAlign: "initial",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {errors.password_confirmation}
              </Alert>
            </Grid>
          )}
          {error && typeof error === "string" && (
            <Grid item xs={12}>
              <Alert variant="outlined" severity="error">
                {error}
              </Alert>
            </Grid>
          )} */}
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 4, textTransform: "unset" }}
          color="primary"
          onClick={handleReset}
        >
          {t("Reset Password")}
        </Button>
      </Box>
    </Grow>
  );
};

export default function RestoreModal({ reset, content }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const error = useSelector((state) => state.error);
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();
  const { user, token } = content || {};

  // let [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const handleClose = () => {
    // setSearchParams({login: false});
    setOpen(false);
    clear();
    dispatch(hideModal("restore"));
    dispatch(hideModal("reset"));
    dispatch(hideModal("login"));
  };

  const [data, setRestoreData] = React.useState({
    email: null,
  });

  const [resetData, setResetData] = React.useState({
    password: null,
    password_confirmation: null,
  });

  const [sent, setSent] = React.useState(false);

  const [isReset, setIsReset] = React.useState(false);

  function setData(event) {
    if (event.target.name === "email") {
      setRestoreData({ ...data, email: event.target.value });
    }
  }

  function setResetFields(event) {
    if (event.target.name === "password") {
      setResetData({ ...resetData, password: event.target.value });
    }
    if (event.target.name === "password_confirmation") {
      setResetData({ ...resetData, password_confirmation: event.target.value });
    }
  }

  async function handleReset(event) {
    event.preventDefault();
    dispatch(removeError());
    dispatch(removeErrors());
    let item = {
      password: resetData.password,
      password_confirmation: resetData.password_confirmation,
      user: user,
      token: token,
    };

    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      };
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + API_RESTORE_ROUTE,
        options
      );
      const data = await response.json();

      if (data.success) {
        setIsReset(true);
        navigate("/login");
      } else {
        if (data.errors) dispatch(setErrors(data.errors));
        else if (data.message) dispatch(setMessage(data.message, "error"));
      }
    } catch (error) {
      setMessage(error, "error");
    }
  }

  async function handleSend(event) {
    event.preventDefault();

    dispatch(removeError());
    dispatch(removeErrors());
    let item = {
      email: data.email,
      isMoneyX: true,
    };

    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      };
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + API_RESTORE_LINK_ROUTE,
        options
      );
      const data = await response.json();

      if (data.success) {
        setSent(true);
      } else {
        if (data.errors) dispatch(setErrors(data.errors));
        else if (data.message) dispatch(setMessage(data.message, "error"));
      }
    } catch (error) {
      setMessage(error, "error");
    }
  }

  function clear() {
    setRestoreData({
      email: null,
    });
    setResetData({
      password: null,
      password_confirmation: null,
    });
    var el1 = document.getElementsByName("email");
    if (el1) el1.value = "";
    var el2 = document.getElementsByName("password");
    if (el2) el2.value = "";
    var el3 = document.getElementsByName("password_confirmation");
    if (el3) el3.value = "";
    dispatch(removeErrors());
  }

  const handleReturn = () => {
    clear();
    dispatch(hideModal("restore"));
    dispatch(hideModal("login"));
    dispatch(showModal("login"));
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className="pop-up"
    >
      <Fade in={open}>
        <Box
          sx={style}
          style={{
            width: window.innerWidth < 1050 ? "unset" : "100%",
            padding: window.innerWidth < 1050 ? "0px" : "32px",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: 15, top: 15 }}
            onClick={handleClose}
          >
            <ClearIcon />
          </IconButton>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              style={{ boxShadow: "none" }}
            >
              <Avatar
                style={{
                  margin: "0 auto",
                  width: "180px",
                  height: "100%",
                }}
                src={Logo}
              />
              {reset ? (
                !isReset ? (
                  <ResetBox
                    reset={reset}
                    handleReset={handleReset}
                    t={t}
                    error={error}
                    errors={errors}
                    setResetFields={setResetFields}
                  />
                ) : (
                  <CongradulationsBox
                    isReset={isReset}
                    handleReturn={handleReturn}
                    t={t}
                  />
                )
              ) : sent ? (
                <EmailSentBox
                  sent={sent}
                  t={t}
                  theme={theme}
                  handleReturn={handleReturn}
                  data={data}
                />
              ) : (
                <RequestingResetBox
                  t={t}
                  setData={setData}
                  error={error}
                  errors={errors}
                  handleSend={handleSend}
                  handleReturn={handleReturn}
                />
              )}
            </Box>
          </Container>
        </Box>
      </Fade>
    </Modal>
  );
}
