import {
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  Input,
  MenuItem,
  Select,
  Snackbar,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { connect, useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ext from "../../assets/images/ext.png";
import CloseIcon from "@mui/icons-material/Close";
import { CreateCampaign, DeleteCampaign, EditCampaign } from "../../redux/actions/CampaignsActions";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ContentPasteGoOutlinedIcon from "@mui/icons-material/ContentPasteGoOutlined";
const mapStateToProps = (state) => {
  return {
    stats_type: state.statsType,
    payout: state.payout,
    campaign: state.campaign,
  };
};
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const WhxTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} placement="top" arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function Interstitial({ campaign }) {
  const dispatch = useDispatch();

  //   var result = images.sort((a, b) => a.images - b.images);

  const videos = [
    "https://media.warehouse-x.io/videos/fetch_preroll.php?context=whx_aff_preroll_1.mp4",
    "https://media.warehouse-x.io/videos/fetch_preroll.php?context=whx_aff_preroll_2.mp4",
    "https://media.warehouse-x.io/videos/fetch_preroll.php?context=whx_aff_preroll_3.mp4",
    "https://media.warehouse-x.io/videos/fetch_preroll.php?context=whx_aff_preroll_4.mp4",
    "https://media.warehouse-x.io/videos/fetch_preroll.php?context=whx_aff_preroll_5.mp4",
  ];
  const [shown, setShown] = React.useState(false);

  const handleClose = () => {
    setShown(false);
    setOpen(false)
  };
  const [shownExt2, setShownExt2] = React.useState(false);

  const handleCloseExt2 = () => {
    setShownExt2(false);
  };

  const [Edit, setCampaignEdit] = React.useState();
  const [campaign_details, setCampaignDetails] = React.useState(
    campaign && campaign
  );
  React.useEffect(() => {
    setCampaignDetails(campaign && campaign);
  }, [campaign]);
  const [open, setOpen] = React.useState(false);

  const [inputs, setСampaignName] = React.useState({
    campaign_name:
      campaign && campaign.campaign_name ? campaign.campaign_name : "",
      interstitial_type:campaign.interstitial_type? campaign.interstitial_type :''
    
  });

  function handleChange(evt) {
    const value = evt.target.value;
    setСampaignName({
      ...inputs,
      [evt.target.name]: value,
    });
  }
  function handleCreate(e) {
    console.log(inputs)
    dispatch(CreateCampaign(inputs));
    setCampaignEdit(true);
  }
  function handleEdit(inputs, id) {
    dispatch(EditCampaign(inputs, id));
    setCampaignEdit(true);
  }
  function handleDelete(id) {
    dispatch(DeleteCampaign(id));
    handleClose();
  }
  const currencies = [
    {
      value: "Landing page",
      label: "€",
    },
  ];
  const [openSnack, setOpenSnack] = React.useState(false);
  const handleClick = (item) => {
    setOpenSnack(true);
    navigator.clipboard.writeText(item);
  };
  function navigateTo(e) {
    setOpen(true);
  }
  console.log(campaign_details)
  return (
    <>
      <Container maxWidth="xl">
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            className="container"
            container
            spacing={4}
            style={{ marginTop: "40px" }}
          >
            <Grid
              className="stats_header"
              item
              xs={11}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: "35px",
                paddingBottom: "40px",
              }}
            >
              <Grow in timeout={200} delay={400}>
                <Box>
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <Typography style={{ color: "white" }}>
                      {" "}
                      Interstitial Adult
                    </Typography>
                    <Button
                      className="new_campaign"
                      onClick={() => {
                        navigateTo();
                        setCampaignEdit(false);
                        inputs.campaign_name = "";
                        inputs.interstitial_type ='adult'
                        setCampaignDetails("");
                      }}
                      style={{
                        background: "#77F73B 0% 0% no-repeat padding-box",
                        color: "black",
                        boxShadow: "0px 3px 6px #00000029",
                        font: "normal normal bold 1rem Montserrat",
                        borderRadius: "15px",
                        padding: "7px 15px",
                      }}
                    >
                      Create new interstitial
                    </Button>
                  </Grid>

                  <WhxTooltip title="Click to see">
                    <Card>
                      <CardActionArea
                        download={true}
                        onClick={() => setShown(!shown)}
                        style={{ display: "flex" }}
                      >
                        {
                          // <ReactHlsPlayer
                          //   id="main_video"
                          //   src="rtsp://admin:WhxXXx971!@84.50.88.9:2001/cam/realmonitor?channel=1&subtype=1"
                          //   controls={false}
                          //   style={{ maxWidth: "140px", minWidth: "140px", width: "100%", height: '80px' }}
                          //   muted={true}
                          //   autoPlay
                          // />
                          // :
                          <img
                            src={ext}
                            alt="preload"
                            className="Mansory_img"
                            style={{ borderRadius: "5px" }}
                          />
                        }
                      </CardActionArea>
                    </Card>
                  </WhxTooltip>
                </Box>
              </Grow>
              <Grow in timeout={200} delay={400}>
                <Box>
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <Typography style={{ color: "white" }}>
                      {" "}
                      Interstitial Non Adult
                    </Typography>
                    <Button
                      className="new_campaign"
                      onClick={() => {
                        navigateTo();
                        setCampaignEdit(false);
                        inputs.campaign_name = "";
                        inputs.interstitial_type ='non_adult'
                        setCampaignDetails("");
                      }}
                      style={{
                        background: "#77F73B 0% 0% no-repeat padding-box",
                        color: "black",
                        boxShadow: "0px 3px 6px #00000029",
                        font: "normal normal bold 1rem Montserrat",
                        borderRadius: "15px",
                        padding: "7px 15px",
                      }}
                    >
                      Create new interstitial
                    </Button>
                  </Grid>

                  <WhxTooltip title="Click to see">
                    <Card>
                      <CardActionArea
                        download={true}
                        onClick={() => setShownExt2(!shownExt2)}
                        style={{ display: "flex" }}
                      >
                        {
                          // <ReactHlsPlayer
                          //   id="main_video"
                          //   src="rtsp://admin:WhxXXx971!@84.50.88.9:2001/cam/realmonitor?channel=1&subtype=1"
                          //   controls={false}
                          //   style={{ maxWidth: "140px", minWidth: "140px", width: "100%", height: '80px' }}
                          //   muted={true}
                          //   autoPlay
                          // />
                          // :
                          <img
                            src={ext}
                            alt="preload"
                            className="Mansory_img"
                            style={{ borderRadius: "5px" }}
                          />
                        }
                      </CardActionArea>
                    </Card>
                  </WhxTooltip>
                </Box>
              </Grow>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Dialog
        className="pop-up_verify i_frame"
        open={shown}
        onClose={handleClose}
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          style={{
            width: "fit-content",
            marginLeft: "15px",
            marginTop: "5px",
            position: "absolute",
            right: "10px",
            top: "2px",
            zIndex: 5,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* <DialogContent style={{ overflow: 'unset' }} */}
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            zIndex: "4",
          }}
        ></div>
        <iframe
          title={"https://ext.warehouse-x.io/"}
          allowFullScreen
          frameBorder="0"
          height="90%"
          src={"https://ext.warehouse-x.io/"}
          width="80%"
          className="i_frame"
        />
      </Dialog>
      <Dialog
        className="pop-up_verify i_frame"
        open={shownExt2}
        onClose={handleCloseExt2}
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleCloseExt2}
          aria-label="close"
          style={{
            width: "fit-content",
            marginLeft: "15px",
            marginTop: "5px",
            position: "absolute",
            right: "10px",
            top: "2px",
            zIndex: 5,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* <DialogContent style={{ overflow: 'unset' }} */}
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            zIndex: "4",
          }}
        ></div>
        <iframe
          title={"https://ext2.warehouse-x.io/ "}
          allowFullScreen
          frameBorder="0"
          height="90%"
          src={"https://ext2.warehouse-x.io/ "}
          width="80%"
          className="i_frame"
        />
      </Dialog>
      <Dialog
        className="pop-up_verify Dialog_verify"
        open={open}
        onClose={handleClose}
        style={{ backdropFilter: "blur(1rem)" }}
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          style={{
            width: "fit-content",
            marginLeft: "15px",
            marginTop: "5px",
            position: "absolute",
            right: "10px",
            top: "2px",
            zIndex: 5,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <Typography className="title">Create / edit Interstitial</Typography>
        </DialogTitle>
        <DialogContent className="DialogContent">
          <DialogContentText
            textAlign={"center"}
            marginBottom={"10px !important"}
            width={"100%"}
            height={"60%"}
          >
            <Box
              className="Input"
              initial={{ opacity: 0, y: 40 }}
              animate={animate}
            >
              <MailOutlineIcon
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
              />
              <TextField
                fullWidth
                name="campaign_name"
                value={inputs.campaign_name}
                onChange={handleChange}
                placeholder="Campaign name"
                variant="standard"
                style={{ backgroundColor: "#191919", paddingTop: "25px" }}
                type="text"
                /*    {...getFieldProps("email")}  */
              />
            </Box>
            <Grid
              item
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0px 0px 0px 42px",
              }}
            >
              <Grid
                item
                style={{
                  width: "100%",
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography className="landingPageText">
                  Landing page
                </Typography>
                <Typography
                  style={{ paddingBottom: "5px" }}
                  className="landingPageOutText"
                >
                  Where your visitors should land
                </Typography>
                <Select
                  className="LandingSelect"
                  defaultValue=""
                  displayEmpty
                  value={currencies[0].value}
                  renderValue={(value) => {
                    return (
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <SvgIcon color="black">
                          <FilterListOutlinedIcon />
                        </SvgIcon>
                        <div
                          style={{
                            display: "flex",
                            width: "-webkit-fill-available",
                            justifyContent: "center",
                          }}
                        >
                          {value}
                        </div>
                      </Box>
                    );
                  }}
                >
                  {currencies.map((option) => (
                    <MenuItem
                      style={{ textAlign: "center" }}
                      key={option.value}
                      value={option.value}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              {campaign_details && campaign_details.campaign_url && (
                <Grid
                  item
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography className="landingPageText">
                    Campaign link
                  </Typography>
                  <div style={{ display: "flex", width: "100%" }}>
                    <Input
                      style={{ width: "80%" }}
                      disabled
                      disableUnderline
                      className="DisabledLink"
                      type="text"
                      value={campaign_details.campaign_url}
                    />
                    <IconButton
                      onClick={() => {
                        handleClick(campaign_details.campaign_url);
                      }}
                    >
                      <ContentPasteGoOutlinedIcon fontSize="small" />
                    </IconButton>
                    <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      className="snackBarCopy"
                      open={openSnack}
                      style={{ color: "#77F73B" }}
                      onClose={() => setOpenSnack(false)}
                      autoHideDuration={2000}
                      message="Copied to clipboard"
                    />
                  </div>
                </Grid>
              )}
            </Grid>
          </DialogContentText>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "0px",
            }}
          >
            {!Edit && (
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 4, mb: 2 }}
                color="success"
                style={{
                  backgroundColor: "#77F73B",
                  width: "313px",
                  height: "52px",
                  font: "normal normal bold 16px/19px Effra",
                  color: "black",
                }}
                onClick={handleCreate}
              >
                Create
              </Button>
            )}
            {Edit && (
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 4, mb: 2 }}
                color="success"
                style={{
                  backgroundColor: "#77F73B",
                  width: "313px",
                  height: "52px",
                  font: "normal normal bold 16px/19px Effra",
                  color: "black",
                }}
                onClick={() => handleEdit(inputs, campaign_details.id)}
              >
                Save
              </Button>
            )}
          </DialogActions>
        </DialogContent>
        {Edit && (
          <Grid item className="Campaign_delete">
            <Button
              fullWidth
              startIcon={<DeleteOutlinedIcon />}
              variant="contained"
              color="success"
              style={{
                background: "#414141 0% 0% no-repeat padding-box",
                width: "209px",
                height: "35px",
                font: "normal normal bold 16px/19px Effra",
                color: "white",
              }}
              onClick={() => {
                handleDelete(campaign_details.id);
              }}
            >
              delete campaign
            </Button>
          </Grid>
        )}
      </Dialog>
    </>
  );
}

export default connect(mapStateToProps)(Interstitial);
