import {
    Button,
    ButtonGroup,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    Grow,
    IconButton,
    Input,
    MenuItem,
    Pagination,
    Select,
    Snackbar,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import React, {useState} from "react";
import Box from "@mui/material/Box";
import {connect, useDispatch} from "react-redux";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import {useNavigate} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {CreateCampaign, DeleteCampaign, EditCampaign, GetCampaigns,} from "../../redux/actions/CampaignsActions";
import ContentPasteGoOutlinedIcon from "@mui/icons-material/ContentPasteGoOutlined";

const mapStateToProps = (state) => {
    return {
        campaigns: state.campaigns,
        campaign: state.campaign,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCampaigns: () => dispatch(GetCampaigns()),
    };
};
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
    opacity: 1,
    y: 0,
    transition: {
        duration: 0.6,
        ease: easing,
        delay: 0.16,
    },
};

function Campaigns({campaigns, getCampaigns, campaign}) {
    const dispatch = useDispatch();

    React.useEffect(() => {
        getCampaigns();
    }, []);
    console.log(campaigns);

    const rows = campaigns && campaigns.campaigns;
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setOffSet(page * 10);
    };

    const [count, setCount] = React.useState(0);
    const [offSet, setOffSet] = React.useState(0);
    /*  setCount(2) */
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(1);
    };
    React.useEffect(() => {
        rows && setCount(Math.ceil(rows.length / rowsPerPage));
    }, [rows]);

    const StyledTableRow = styled(TableRow)(({theme}) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: "#292929",
            height: "40px",
            padding: "0px 16px",
        },
        "&:nth-of-type(even)": {
            backgroundColor: "#1F1F1F",
            height: "40px",
            padding: "0px 16px",
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
        root: {
            height: 30,
        },
        /*         "&.MuiTableRow-root:hover": {
                        backgroundColor: "#555555",
                    }, */
    }));

    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#292929",
            color: theme.palette.common.white,
            padding: "0px 16px",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    const navigate = useNavigate();

    const [open, setOpen] = React.useState(false);

    const [inputs, setСampaignName] = React.useState({
        campaign_name:
            campaign && campaign.campaign_name ? campaign.campaign_name : "",
        campaign_page: "",
    });

    function handleChange(evt) {
        const value = evt.target.value;
        setСampaignName({
            ...inputs,
            [evt.target.name]: value,
        });
    }

    function navigateTo(e) {
        setOpen(true);
    }

    function handleCreate(e) {
        dispatch(CreateCampaign(inputs));
        setCampaignEdit(true);
    }

    function handleEdit(inputs, id) {
        dispatch(EditCampaign(inputs, id));
        setCampaignEdit(true);
    }

    function handleDelete(id) {
        dispatch(DeleteCampaign(id));
        handleClose();
    }

    function handleGetStats(id) {
        // dispatch(GetCampaign(id))
        navigate("/stats/" + id);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const currencies = [
        {
            value: "Revenue Share Landing Page",
            label: "(earn 25% of your users purchase)",
            id: 0,
        },
        {
            value: "Affiliate Referral Landing Page",
            label: "(earn 5% of referred affiliate earnings)",
            id: 1,
        },
        {
            value: "CPA Landing Page",
            label: "(earn 10$ for a first purchase and 20$ for a first subscription of your user)",
            id: 2,
        },
        {
            value: "Pay Per Signup Landing Page",
            label: "(get up to 2$ per signup depending on users GEO)",
            id: 3,
        },
    ];
    const [grouptype, setgroup_type] = useState(currencies[0].value);

    const handleChangeGroup = (event) => {
        console.log(event.target);
        setgroup_type(event.target.value);

        if (event.target.value == "Revenue Share Landing Page") {
            inputs.campaign_page = 0;
        }
        if (event.target.value == "Affiliate Referral Landing Page") {
            inputs.campaign_page = 1;
        }
        if (event.target.value == "CPA Landing Page") {
            inputs.campaign_page = 2;
        }
        if (event.target.value == "Pay Per Signup Landing Page") {
            inputs.campaign_page = 3;
        }
    };

    const [Edit, setCampaignEdit] = React.useState();
    const [campaign_details, setCampaignDetails] = React.useState(
        campaign && campaign
    );
    React.useEffect(() => {
        setCampaignDetails(campaign && campaign);
    }, [campaign]);

    React.useEffect(() => {
        var group = "";
        console.log();
        if (campaign_details?.campaign_page == 0) {
            group = "Revenue Share Landing Page";
        }
        if (campaign_details?.campaign_page == 1) {
            group = "Affiliate Referral Landing Page";
        }
        if (campaign_details?.campaign_page == 2) {
            group = "CPA Landing Page";
        }
        if (campaign_details?.campaign_page == 3) {
            group = "Pay Per Signup Landing Page";
        }
        if (!campaign_details?.campaign_page) {
            group = "Revenue Share Landing Page";
        }
        setgroup_type(group);
    }, [campaign_details, campaign_details?.campaign_name]);

    const [openSnack, setOpenSnack] = React.useState(false);
    const handleClick = (item) => {
        setOpenSnack(true);
        navigator.clipboard.writeText(item);
    };
    return (
        <>
            <Container maxWidth="xl">
                <Box sx={{flexGrow: 1}}>
                    <Grid
                        className="container"
                        container
                        spacing={4}
                        style={{marginTop: "40px"}}
                    >
                        <Grid
                            item
                            xs={12}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Typography
                                className="Run_compaign"
                                style={{
                                    color: "white",
                                    font: "normal normal bold 1.5rem Effra",
                                }}
                            >
                                Running campaigns
                            </Typography>
                            <Button
                                className="new_campaign"
                                onClick={() => {
                                    navigateTo();
                                    setCampaignEdit(false);
                                    inputs.campaign_name = "";
                                    setCampaignDetails("");
                                }}
                                style={{
                                    background: "#77F73B 0% 0% no-repeat padding-box",
                                    color: "black",
                                    boxShadow: "0px 3px 6px #00000029",
                                    font: "normal normal bold 1rem Montserrat",
                                    borderRadius: "15px",
                                    padding: "7px 15px",
                                }}
                            >
                                Create new campaign
                            </Button>
                        </Grid>
                        <Grow in timeout={200} delay={400}>
                            <Grid item xs={12}>
                                <TableContainer
                                    className="SchrollBar_Geo"
                                    component={Paper}
                                    style={{
                                        overflow: "auto",
                                        marginRight: "40px",
                                        marginTop: "20px",
                                        borderRadius: "5px",
                                        boxShadow: "unset",
                                    }}
                                    sx={{maxHeight: "500px"}}
                                >
                                    <Table aria-label="simple table" stickyHeader>
                                        <TableHead style={{height: "40px"}}>
                                            <StyledTableRow>
                                                <StyledTableCell
                                                    style={{
                                                        borderBottom: "2px solid #5C5C5C",
                                                        color: "#77F73B",
                                                        width: "45%",
                                                    }}
                                                    align="left"
                                                >
                                                    CAMPAIGN
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    style={{
                                                        borderBottom: "2px solid #5C5C5C",
                                                        color: "#77F73B",
                                                    }}
                                                    align="center"
                                                >
                                                    CREATED
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    style={{
                                                        borderBottom: "2px solid #5C5C5C",
                                                        color: "#77F73B",
                                                    }}
                                                    align="center"
                                                >
                                                    SIGNUPS
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    style={{
                                                        borderBottom: "2px solid #5C5C5C",
                                                        color: "#77F73B",
                                                    }}
                                                    align="center"
                                                >
                                                    DEPOSITS
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    style={{
                                                        borderBottom: "2px solid #5C5C5C",
                                                        color: "#77F73B",
                                                    }}
                                                    align="center"
                                                >
                                                    EARNINGS
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    style={{
                                                        borderBottom: "2px solid #5C5C5C",
                                                        color: "#77F73B",
                                                    }}
                                                    align="right"
                                                ></StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows &&
                                                rows
                                                    .slice(
                                                        (page - 1) * rowsPerPage,
                                                        (page - 1) * rowsPerPage + rowsPerPage
                                                    )
                                                    .map((row) => (
                                                        <StyledTableRow
                                                            key={row.id}
                                                            sx={{
                                                                "&:last-child td, &:last-child th": {
                                                                    border: 0,
                                                                },
                                                            }}
                                                            style={{height: "40px"}}
                                                        >
                                                            <TableCell
                                                                align="left"
                                                                style={{
                                                                    borderBottom: "none",
                                                                    color: "white",
                                                                    padding: "0px 16px",
                                                                    width: "45%",
                                                                    fontSize: "1.1rem",
                                                                }}
                                                            >
                                                                {row.campaign_name}
                                                            </TableCell>
                                                            <TableCell
                                                                align="center"
                                                                style={{
                                                                    borderBottom: "none",
                                                                    color: "white",
                                                                    padding: "0px 16px",
                                                                    fontSize: "1.1rem",
                                                                }}
                                                            >
                                                                {row.createdAt}
                                                            </TableCell>
                                                            <TableCell
                                                                align="center"
                                                                style={{
                                                                    borderBottom: "none",
                                                                    color: "white",
                                                                    padding: "0px 16px",
                                                                    fontSize: "1.1rem",
                                                                }}
                                                            >
                                                                {row.signups_count}
                                                            </TableCell>
                                                            <TableCell
                                                                align="center"
                                                                style={{
                                                                    borderBottom: "none",
                                                                    color: "white",
                                                                    padding: "0px 16px",
                                                                    fontSize: "1.1rem",
                                                                }}
                                                            >
                                                                ${row.deposits}
                                                            </TableCell>
                                                            <TableCell
                                                                align="center"
                                                                style={{
                                                                    borderBottom: "none",
                                                                    color: "#FFD800",
                                                                    padding: "0px 16px",
                                                                    fontSize: "1.1rem",
                                                                }}
                                                            >
                                                                ${row.earnings}
                                                            </TableCell>
                                                            <TableCell
                                                                align="right"
                                                                style={{
                                                                    borderBottom: "none",
                                                                    color: "white",
                                                                    padding: "0px 16px",
                                                                    fontSize: "1.1rem",
                                                                }}
                                                            >
                                                                <ButtonGroup
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-end",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        style={{
                                                                            color: "white",
                                                                            width: "10px",
                                                                            padding: "0px",
                                                                            border: "unset",
                                                                            borderRadius: "5px",
                                                                        }}
                                                                        type="button"
                                                                        onClick={() => {
                                                                            handleGetStats(row.id);
                                                                        }}
                                                                    >
                                                                        <InsertChartOutlinedIcon/>
                                                                    </Button>
                                                                    <Button
                                                                        style={{
                                                                            color: "white",
                                                                            width: "10px",
                                                                            padding: "0px",
                                                                            border: "unset",
                                                                            borderRadius: "5px",
                                                                        }}
                                                                        type="button"
                                                                        onClick={() => {
                                                                            navigateTo();
                                                                            setCampaignEdit(true);
                                                                            setCampaignDetails(row);
                                                                            inputs.campaign_name = row.campaign_name;
                                                                        }}
                                                                    >
                                                                        <SettingsOutlinedIcon/>
                                                                    </Button>
                                                                </ButtonGroup>
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginTop: "15px",
                                    }}
                                >
                                    <Divider
                                        style={{
                                            opacity: 1,
                                            width: "100%",
                                            border: "2px solid white",
                                            borderRadius: "10px",
                                        }}
                                        component="div"
                                    />
                                </Grid>
                                <Pagination
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: "20px",
                                    }}
                                    component="div"
                                    count={count}
                                    page={page}
                                    siblingCount={1}
                                    boundaryCount={0}
                                    /*    variant="outlined" */
                                    shape="rounded"
                                    /*  style={{color:'white'}} */
                                    /* color='white' */
                                    onChange={handleChangePage}
                                />
                                {/*       <TablePagination
                            style={{ background: '#292929', color: 'white' }}
                            rowsPerPageOptions={[6, 12, 18]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage} /> */}
                            </Grid>
                        </Grow>
                    </Grid>
                </Box>
            </Container>
            <Dialog
                className="pop-up_verify Dialog_verify"
                open={open}
                onClose={handleClose}
                style={{backdropFilter: "blur(1rem)"}}
            >
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    style={{
                        width: "fit-content",
                        marginLeft: "15px",
                        marginTop: "5px",
                        position: "absolute",
                        right: "10px",
                        top: "2px",
                        zIndex: 5,
                        color: "white",
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogTitle
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                    }}
                >
                    <Typography className="title">Create / edit campaign</Typography>
                </DialogTitle>
                <DialogContent className="DialogContent">
                    <DialogContentText
                        textAlign={"center"}
                        marginBottom={"10px !important"}
                        width={"100%"}
                        height={"60%"}
                    >
                        <Box
                            className="Input"
                            initial={{opacity: 0, y: 40}}
                            animate={animate}
                        >
                            <MailOutlineIcon
                                sx={{color: "action.active", mr: 1, my: 0.5}}
                            />
                            <TextField
                                fullWidth
                                name="campaign_name"
                                value={inputs.campaign_name}
                                onChange={handleChange}
                                placeholder="Campaign name"
                                variant="standard"
                                style={{backgroundColor: "#191919", paddingTop: "25px"}}
                                type="text"
                                /*    {...getFieldProps("email")}  */
                            />
                        </Box>
                        <Grid
                            item
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                padding: "0px 0px 0px 42px",
                            }}
                        >
                            {campaign_details.is_interstitial != 1 && (
                                <Grid
                                    item
                                    style={{
                                        width: "100%",
                                        marginTop: "30px",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <Typography className="landingPageText">
                                        Landing page
                                    </Typography>
                                    <Typography
                                        style={{paddingBottom: "5px"}}
                                        className="landingPageOutText"
                                    >
                                        Where your visitors should land
                                    </Typography>
                                    {Edit ? (
                                        <Select
                                            className="LandingSelect"
                                            defaultValue=""
                                            displayEmpty
                                            disabled
                                            style={{opacity: "0.5"}}
                                            value={grouptype}
                                            onChange={handleChangeGroup}
                                            renderValue={(value) => {
                                                return (
                                                    <Box sx={{display: "flex", gap: 1}}>
                                                        <SvgIcon color="black">
                                                            <FilterListOutlinedIcon/>
                                                        </SvgIcon>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                width: "-webkit-fill-available",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            {value}
                                                        </div>
                                                    </Box>
                                                );
                                            }}
                                        >
                                            {currencies.map((option) => (
                                                <MenuItem
                                                    style={{textAlign: "center"}}
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    <div style={{color: "white"}}>{option.value}</div>
                                                    <div style={{color: "#77f73b"}}>{option.label}</div>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    ) : (
                                        <Select
                                            className="LandingSelect"
                                            defaultValue=""
                                            displayEmpty
                                            value={grouptype}
                                            onChange={handleChangeGroup}
                                            renderValue={(value) => {
                                                return (
                                                    <Box sx={{display: "flex", gap: 1}}>
                                                        <SvgIcon color="black">
                                                            <FilterListOutlinedIcon/>
                                                        </SvgIcon>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                width: "-webkit-fill-available",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            {value}
                                                        </div>
                                                    </Box>
                                                );
                                            }}
                                        >
                                            {currencies.map((option) => (
                                                <MenuItem
                                                    style={{
                                                        textAlign: "center",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center"
                                                    }}
                                                    key={option.value}
                                                    value={option.value}
                                                >

                                                    <div style={{color: "white"}}>{option.value}</div>
                                                    <div style={{color: "#77f73b"}}>{option.label}</div>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                </Grid>
                            )}

                            {campaign_details && campaign_details.campaign_url && (
                                <Grid
                                    item
                                    style={{
                                        width: "100%",
                                        marginTop: "10px",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <Typography className="landingPageText">
                                        Campaign link
                                    </Typography>
                                    <div style={{display: "flex", width: "100%"}}>
                                        <Input
                                            style={{width: "80%"}}
                                            disabled
                                            disableUnderline
                                            className="DisabledLink"
                                            type="text"
                                            value={campaign_details.campaign_url}
                                        />
                                        <IconButton
                                            onClick={() => {
                                                handleClick(campaign_details.campaign_url);
                                            }}
                                        >
                                            <ContentPasteGoOutlinedIcon fontSize="small"/>
                                        </IconButton>
                                        <Snackbar
                                            anchorOrigin={{vertical: "top", horizontal: "right"}}
                                            className="snackBarCopy"
                                            open={openSnack}
                                            style={{color: "#77F73B"}}
                                            onClose={() => setOpenSnack(false)}
                                            autoHideDuration={2000}
                                            message="Copied to clipboard"
                                        />
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    </DialogContentText>
                    <DialogActions
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            padding: "0px",
                        }}
                    >
                        {!Edit && (
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{mt: 4, mb: 2}}
                                color="success"
                                style={{
                                    backgroundColor: "#77F73B",
                                    width: "313px",
                                    height: "52px",
                                    font: "normal normal bold 16px/19px Effra",
                                    color: "black",
                                }}
                                onClick={handleCreate}
                            >
                                Create
                            </Button>
                        )}
                        {Edit && (
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{mt: 4, mb: 2}}
                                color="success"
                                style={{
                                    backgroundColor: "#77F73B",
                                    width: "313px",
                                    height: "52px",
                                    font: "normal normal bold 16px/19px Effra",
                                    color: "black",
                                }}
                                onClick={() => handleEdit(inputs, campaign_details.id)}
                            >
                                Save
                            </Button>
                        )}
                    </DialogActions>
                </DialogContent>
                {Edit && (
                    <Grid item className="Campaign_delete">
                        <Button
                            fullWidth
                            startIcon={<DeleteOutlinedIcon/>}
                            variant="contained"
                            color="success"
                            style={{
                                background: "#414141 0% 0% no-repeat padding-box",
                                width: "209px",
                                height: "35px",
                                font: "normal normal bold 16px/19px Effra",
                                color: "white",
                            }}
                            onClick={() => {
                                handleDelete(campaign_details.id);
                            }}
                        >
                            delete campaign
                        </Button>
                    </Grid>
                )}
            </Dialog>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);
