import {
  ADMIN_PAYOUT,
  ADMIN_PAYOUTS,
  ADMIN_PAYOUT_PATCH,
  ADMIN_PAYOUT_TRANSACTION_PATCH,
  CAMPAIGNS,
  GLOBAL_STATS,
  MEMBERS,
} from "../types";

export const AdminPayoutReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADMIN_PAYOUT: {
      const { data } = payload;
      state = [];
      return data;
    }
    case ADMIN_PAYOUT_PATCH: {
      const { data } = payload;
      let newState2 = Object.assign({}, state);
      newState2.payout.status = data.payout.status;
      return newState2;
    }
    case ADMIN_PAYOUT_TRANSACTION_PATCH: {
      const { data } = payload;
      let newState;
      let newState2 = Object.assign({}, state);

      newState = state.transactions;
      let arrayForSort = [...newState]
      let obj = newState.find((item) => item.id === data.transaction.id);
      const idx = newState.indexOf(obj);
      arrayForSort[idx] = data.transaction;
      newState2.transactions = arrayForSort;

      return newState2;
    }
    default: {
      return state;
    }
  }
};
